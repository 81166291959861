//Template Imports
import AuthBox from 'com/templates/AuthBox';
//UI Imports
import BackButton from 'com/ui/BackButton';
//ICON imports
import ICON_BACK_LOGIN from 'assets/images/icons/login_back_light_purple_blue.svg';
//Style Imports
import './style.css';

const ForgotPasswordMessageIPeer = () => {
	return (
		<div className="thankyou-container">
			<AuthBox
				title="A password reset link is on it's way"
				titleColor="dark-grey"
				icon="password"
				footer={
					<BackButton icon={ICON_BACK_LOGIN} to="/">
						Back to login
					</BackButton>
				}
			>
				An email with a password reset link has just been sent to you. Please check your email and follow the instructions in order to reset your password.
			</AuthBox>
		</div>
	);
};

export default ForgotPasswordMessageIPeer;
