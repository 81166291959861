import React from 'react';

import { AppRoute } from 'com/util/AppRoute';
import Routes from 'data/routes';

import './style.css';

import SystemSettingsEmailTemplatesMain from './SystemSettingsEmailTemplatesMain';
import SystemSettingsEmailTemplatesDetails from './SystemSettingsEmailTemplatesDetails';

const SystemSettingsEmailTemplates = () => {
	return (
		<>
			<AppRoute route={Routes.SYSTEM_SETTINGS_EMAIL_TEMPLATES_MAIN} exact />
			<AppRoute route={Routes.SYSTEM_SETTINGS_EMAIL_TEMPLATES_ADD} exact />
			<AppRoute route={Routes.SYSTEM_SETTINGS_EMAIL_TEMPLATES_EDIT} exact />
			<AppRoute route={Routes.SYSTEM_SETTINGS_EMAIL_TEMPLATES_DUPLICATE} exact />
		</>
	);
};

export { SystemSettingsEmailTemplatesMain, SystemSettingsEmailTemplatesDetails };

export default SystemSettingsEmailTemplates;
