import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Settings from 'services/rest/settings';
import EventLoggerEvents from 'services/rest/event_logger';

import DataTable from 'com/widgets/DataTable';
import Modal from 'com/widgets/Modal';

import Button from 'com/ui/Button';
import MultiSelect from 'com/ui/MultiSelect';

import AppLink from 'com/util/AppLink';

import ICON_STATUS_CHECKED from 'assets/images/icons/ico_status_checked.svg';
import ICON_STATUS_UNCHECKED from 'assets/images/icons/ico_status_unchecked.svg';

const SystemSettingsPoasMain = (props) => {
	const filterInit = {
		regions: [],
		orderBy: [],
	};

	const [filter, setFilter] = useState(filterInit);
	const [tableHeaders, setTableHeaders] = useState([]);
	const [poas, setPoas] = useState([]);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showDeletePoaModal, setShowDeletePoaModal] = useState(false);
	const [deletingPoa, setDeletingPoa] = useState(null);
	const [deletedPoas, setDeletedPoas] = useState([]);
	const user = useSelector((state) => state.auth.user);

	useEffect(async () => {
		setTableHeaders([
			{ title: 'Region', field: 'region_code', type: 'string', sort: 'none', sortable: true },
			{ title: 'Low cost vendor', field: 'low_cost_vendor', type: 'string', sort: 'none', sortable: true },
			{ title: 'Required', field: 'is_required', type: 'string', sort: 'none', sortable: true },
			{ title: 'GPOA Allowed', field: 'is_general_allowed', type: 'string', sort: 'none', sortable: true },
			{ title: 'Actions', field: 'action', type: 'string', sort: 'none', sortable: false },
		]);
	}, []);

	useEffect(async () => {
		try {
			await fetchData();
		} catch (err) {}
	}, [props.regions, filter]);

	const fetchData = async () => {
		try {
			if (!!filter.orderBy) {
				filter.order_by = filter.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(',');
			}

			let ps = await Settings.GetPoas(filter);
			setPoas(
				ps
					.map((p) => {
						let region = props.regions.find((r) => r.value == p.region_code);
						p.region_name = region ? region.label : p.region_code;
						return p;
					})
					.sort((a, b) => a.region_name.localeCompare(b.region_name)),
			);
		} catch (err) {
			console.log('err', err);
		}
	};

	const filterChange = (e) => {
		setFilter({ ...filter, [e.target.name]: e.target.value });
	};

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filter.orderBy.filter((r) => r.field === col).length === 0) {
						setFilter({
							...filter,
							orderBy: [{ field: col, direction: h.sort }, ...filter.orderBy],
						});
					} else {
						setFilter({
							...filter,
							orderBy: [{ field: col, direction: h.sort }, ...filter.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
	};

	const getStatus = (status) => {
		const statuses = {
			0: {
				ico: ICON_STATUS_UNCHECKED,
				title: 'No',
			},
			1: {
				ico: ICON_STATUS_CHECKED,
				title: 'Yes',
			},
		};

		return statuses[status];
	};

	const openDeletePoaModal = (e) => {
		let { pid } = e.target.dataset;
		let poa = poas.find((p) => p.id == pid);
		setDeletingPoa(poa);
		setShowDeletePoaModal(true);
	};

	const closeDeletePoaModal = () => {
		setShowDeletePoaModal(false);
	};

	const handleDeletePoa = () => {
		setDeletedPoas((prevState) => [...prevState, deletingPoa]);
		let data = poas.filter((p) => p.id != deletingPoa.id);
		onChangePoas(data);
		closeDeletePoaModal();
	};

	const deletePoaModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeletePoaModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Delete', action: handleDeletePoa, theme: 'azami-blue' },
	];

	const onChangePoas = (data) => {
		setPoas(data);
	};

	const handleSavePoaRegionalSettings = async () => {
		setShowSpinner(true);
		try {
			for (let p of deletedPoas) {
				await Settings.DeletePoa(p.id);
			}
			await fetchData();
			setShowSpinner(false);
			EventLoggerEvents.EventLogger({ event: `System Settings POA Tab Updated`, data: { user: user } });
		} catch (err) {
			setShowSpinner(false);
		}
	};

	return (
		<div className="main-screen-section white full-height no-padding system-details-content">
			<div className="system-settings-poas-main white">
				<div className="system-settings-poas__header">
					<span className="system-settings-poas__header__title">POA Regional Settings</span>
					<div className="system-settings-poas__header_actions">
						<MultiSelect label="" name="regions" options={props.regions} onChange={filterChange} values={filter.regions} selectAllEnabled={true} />
						<AppLink type="nav" to={`/system-settings/poa-management/add`} exact={true} className="form-button light">
							Add POA
						</AppLink>
					</div>
				</div>
				<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort}>
					{poas.map((p, i) => {
						return (
							<tr key={p.id}>
								<DataTable.CountryCell code={p.region_code || ''}>{p.region_name}</DataTable.CountryCell>
								<DataTable.NullableCell>{p.low_cost_vendor}</DataTable.NullableCell>
								<DataTable.StatusCell status={getStatus(p.is_required)}></DataTable.StatusCell>
								<DataTable.StatusCell status={getStatus(p.is_general_allowed)}></DataTable.StatusCell>
								<DataTable.Cell>
									<AppLink type="nav" to={`/system-settings/poa-management/${p.id}/edit`} exact={true} className="system-settings-poas-main-edit-btn" title="Edit"></AppLink>
									<button onClick={openDeletePoaModal} data-pid={p.id} data-index={i} className="system-settings-poas-main-delete-btn" />
								</DataTable.Cell>
							</tr>
						);
					})}
				</DataTable>
				{showDeletePoaModal && (
					<Modal title="Delete Poa" closeHandler={closeDeletePoaModal} footerActions={deletePoaModalFooterActions}>
						Are you sure that you want to delete <strong>{deletingPoa.region_name}</strong>?
					</Modal>
				)}
			</div>
			<footer className="system-details-content__footer">
				<Button onClick={handleSavePoaRegionalSettings} showSpinner={showSpinner}>
					Save
				</Button>
			</footer>
		</div>
	);
};
export default SystemSettingsPoasMain;
