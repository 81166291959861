import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Select from 'com/ui/Select';
import MultiSelect from 'com/ui/MultiSelect';
import Input from 'com/ui/Input';
import Editor from 'com/ui/Editor';
import Button from 'com/ui/Button';
import DocumentsTable from 'com/widgets/DocumentsTable';

import { DOCUMENT_OBJECT_TYPE } from 'data/constants';
import { getFlag } from 'data/flags';

import EmailTemplates from 'services/rest/email-templates';
import Emails from 'services/rest/emails';
import useNotification from 'services/hooks/use_notification';

import './style.css';

import ICON_ARROW_UP from 'assets/images/icons/ico_sort_up.svg';
import ICON_ARROW_DOWN from 'assets/images/icons/ico_sort_down.svg';

const CaseDetailsSendEmail = ({ caseDetails }) => {
	const user = useSelector((state) => state.auth.user);

	const formDataInit = {
		action: '',
		regions: [],
		id: '',
		email_template_id: '',
		client_id: '',
		case_id: '',
		created_by: user.uid,
		from_email: user.email,
		to_email: '',
		cc: [],
		bcc: [],
		subject: '',
		content: '',
		attachments: ['none'],
	};

	const sectionsInit = {
		regions: true,
	};

	const errorsInit = {
		from_email: '',
		to_email: '',
		cc: '',
		bcc: '',
		subject: '',
	};

	const [spinner, setSpinner] = useState(false);
	const [showEmailContent, setShowEmailContent] = useState(false);
	const [actions, setActions] = useState([{}]);
	const [caseRegions, setCaseRegions] = useState([{}]);
	const [templates, setTemplates] = useState([{}]);
	const [formData, setFormData] = useState(formDataInit);
	const [sections, setSections] = useState(sectionsInit);
	const [errors, setErrors] = useState(errorsInit);
	const sendNotification = useNotification();

	useEffect(() => {
		fetchData();
	}, [caseDetails]);

	const fetchData = async () => {
		try {
			setSpinner(true);
			setFormData({
				...formData,
				client_id: caseDetails.client_id,
				case_id: caseDetails.id,
			});

			setActions([
				{ label: 'Single Email', value: 'single' },
				{ label: 'Bulk Email', value: 'bulk' },
			]);

			setCaseRegions(
				caseDetails.regions.map((region) => {
					return {
						value: region.region_id,
						label: region.region_name,
						img: getFlag(region.region_code),
					};
				}),
			);

			let emailTemplates = await EmailTemplates.GetAll({ email_type: 'CASE', service_id: caseDetails.service_id });
			setTemplates([
				{ label: 'Select Value', value: '' },
				...emailTemplates?.data.map((c) => {
					return { ...c, label: c.name, value: c.id };
				}),
			]);
		} catch (error) {
			console.log(error);
		}
		setSpinner(false);
	};

	const fieldUpdate = async (e) => {
		let { name, value } = e.target;

		if (['cc', 'bcc'].includes(name)) {
			value = value.split(',');
		}

		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleEmail = async (action) => {
		setShowEmailContent(false);

		if (!formData.regions.length || !formData.email_template_id) {
			sendNotification({ type: 'info', title: 'Please select Region(s) and Email Template.' });
			return;
		}

		setSpinner(true);
		try {
			let email;
			if (action === 'restore') {
				email = await Emails.Restore(formData);
				if (!Object.keys(email).length) {
					sendNotification({ type: 'info', title: "Email Draft doesn't exist, Generate a New Draft." });
					return;
				}
			} else if (action === 'create') {
				delete formData.id;
				email = await Emails.Create(formData);
			} else {
				email = await Emails.Update(formData.id, formData);
				sendNotification({ type: 'success', title: 'Email Draft saved' });
			}

			setFormData((prevState) => ({
				...prevState,
				id: email.id,
				from_email: email.from_email,
				to_email: email.to_email,
				cc: email.cc,
				bcc: email.bcc,
				subject: email.subject,
				content: email.content,
				attachments: email.attachments,
			}));

			setShowEmailContent(true);
		} catch (error) {
			console.log(error);
		}
		setSpinner(false);
	};

	const sectionClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		let state = {
			...sections,
			[e.target.dataset.name]: !sections[e.target.dataset.name],
		};

		setSections(state);
	};

	const editorUpdate = (state) => {
		setFormData({
			...formData,
			content: state,
		});
	};

	const updateAttachments = useCallback((documentsID) => {
		setFormData((prevState) => ({
			...prevState,
			attachments: documentsID,
		}));
	}, []);

	const sendEmail = async () => {
		setSpinner(true);
		try {
			await Emails.Send(formData);
			sendNotification({ type: 'success', title: 'Successfully Send Email' });
		} catch (error) {
			console.log(error);
			sendNotification({ type: 'error', title: 'Unable to Send Email.' });
		}
		setSpinner(false);
	};

	return (
		<div className="case-details-send-email">
			<div className="case-details-send-email__title">Email Configuration</div>
			<div className="case-details-send-email__header">
				<div className="case-details-send-email__header__selects">
					{caseRegions.length > 0 && (
						<>
							<Select label="Action" name="action" value={formData.action} options={actions} onChange={fieldUpdate} />
							<MultiSelect label="Regions" name="regions" values={formData.regions} onChange={fieldUpdate} options={caseRegions} selectAllEnabled={true} />
							<Select label="Email Template" name="email_template_id" value={formData.email_template_id} options={templates} onChange={fieldUpdate} />
						</>
					)}
				</div>
				<div className="case-details-send-email__header__actions ">
					<Button onClick={() => handleEmail('restore')} theme="azami-light-violet">
						Restore Email Draft
					</Button>
					<Button onClick={() => handleEmail('create')}>Generate New Email</Button>
				</div>
			</div>

			{showEmailContent && (
				<>
					<details className="case-details-colapsible" open={sections.regions}>
						<summary
							className={`case-details-colapsible__header ${sections.regions ? 'open' : ''} ${errors.regions ? 'estimator-rules-summary-error' : ''}`}
							data-name="regions"
							onClick={sectionClick}
						>
							<i>
								{sections.regions ?
									<img src={ICON_ARROW_UP} />
								:	<img src={ICON_ARROW_DOWN} />}
							</i>
							{formData.regions.map((regionID, key) => {
								let region = caseRegions.find((cr) => cr.value === regionID);
								return (
									<span className="region-email" key={key}>
										<img src={region.img} alt="" />
										<span>{region.label}</span>
										<span>{`${key !== formData.regions.length - 1 ? ' & ' : ''}`}</span>
									</span>
								);
							})}
							Email
						</summary>
						<div className="case-details-colapsible__body">
							<div className="case-details-send-email__contnet">
								<div className="case-details-send-email__contnet-row">
									<Input label="From" name="from_email" value={formData.from_email} onChange={fieldUpdate} error={errors.from_email} />
									<Input label="To" name="to_email" value={formData.to_email} onChange={fieldUpdate} error={errors.to_email} />
								</div>
								<div className="case-details-send-email__contnet-row">
									<Input label="CC" name="cc" value={formData.cc} onChange={fieldUpdate} error={errors.cc} description="Include multiple email addresses" />
									<Input label="BCC" name="bcc" value={formData.bcc} onChange={fieldUpdate} error={errors.bcc} description="Include multiple email addresses" />
								</div>
								<Input label="Subject" name="subject" value={formData.subject} onChange={fieldUpdate} error={errors.subject} />

								<Editor label="Content" name="contnet" theme="snow" value={formData.content} onChange={editorUpdate} />

								<DocumentsTable
									objectTypeRegion={DOCUMENT_OBJECT_TYPE.CASE_REGION}
									objectTypeState={DOCUMENT_OBJECT_TYPE.CASE}
									title="Attachments"
									documentsID={formData.attachments}
									updateDocumentsID={updateAttachments}
									allowPickExistDocument={true}
									allowFilters={false}
									allowEdit={false}
								/>
							</div>
						</div>
					</details>
					<footer className="case-details-send-email__footer ">
						<Button onClick={() => handleEmail('update')} theme="azami-light-violet">
							Save As Draft
						</Button>
						<Button onClick={sendEmail}>Send Email</Button>
					</footer>
				</>
			)}
		</div>
	);
};
export default CaseDetailsSendEmail;
