import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { OwnerContext } from 'util/ownerContext';

import './style.css';

const Textarea = (props) => {
	let theme = props.theme ? props.theme : 'light';
	const errorBorder = props.error ? 'form-textarea__error-border' : '';
	const userData = useSelector((state) => state.auth.user);
	const ownerData = useContext(OwnerContext);

	const checkRoles = (uData, roles) => {
		if (props.disabled) return props.disabled;
		if (uData.role.includes('admin')) return false;
		if (roles.includes('$user') && ownerData.userMatch) return false;
		if (roles.includes('$firm') && ownerData.firmMatch) return false;
		if (roles.includes('$clientOfFirm') && ownerData.clientOfFirmMatch) return false;
		if (roles.includes('$agentOfFirm') && ownerData.agentOfFirmMatch) return false;
		if (roles.length === 0) return false;
		let uRoles = uData.role.filter((r) => {
			return roles.includes(r);
		});
		return uRoles.length === 0;
	};

	let disabled = checkRoles(userData, props.forRoles) ? 'disabled' : '';

	return (
		<label className={`form-textarea ${props.customClassName ? props.customClassName : ''}`}>
			<span className={`form-textarea__label ${theme}`}>{props.label}</span>
			<textarea
				disabled={disabled}
				className={`form-textarea__input ${theme} ${errorBorder} ${disabled}`}
				placeholder={props.placeholder}
				onChange={props.onChange}
				onBlur={props.onBlur}
				name={props.name}
				value={props.value ? props.value : ''}
			></textarea>
			{props.error && <span className="form-textarea__error">{props.error}</span>}
		</label>
	);
};

Textarea.propTypes = {
	theme: PropTypes.oneOf(['light', 'dark']),
	label: PropTypes.string,
	placeholder: PropTypes.string,
	customClassName: PropTypes.string,
	value: PropTypes.any,
	name: PropTypes.string,
	error: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	disabled: PropTypes.bool,
	forRoles: PropTypes.array,
};

Textarea.defaultProps = {
	theme: 'light',
	label: '',
	placeholder: '',
	customClassName: '',
	value: '',
	name: '',
	error: '',
	onChange: () => {},
	onBlur: () => {},
	disabled: false,
	forRoles: [],
};

export default Textarea;
