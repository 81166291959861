// vendor imports
import React, { useEffect, useState } from 'react';

// icons
import RadioButton from 'com/ui/RadioButton';
import Switch from 'com/ui/Switch';

import { COMMUNICATION_PREFERENCES } from 'data/constants';
import { random } from 'services/strings';

const UserDetailsCommunicationPreferences = (props) => {
	const getInitPreferencesState = () => {
		let initState = {};
		Object.keys(COMMUNICATION_PREFERENCES).forEach((item) => {
			switch (COMMUNICATION_PREFERENCES[item].value_type) {
				case 'string':
					initState[item] = '';
					break;
				case 'array':
					initState[item] = [];
					break;
				case 'boolean':
					initState[item] = 0;
					break;
				default:
					initState[item] = '';
					break;
			}
		});
		return initState;
	};
	const [preferences, setPreferences] = useState(getInitPreferencesState());

	useEffect(() => {
		if (props.data.communication_preferences) {
			let user_prefs = {};
			props.data.communication_preferences.forEach((p) => {
				user_prefs = {
					...user_prefs,
					[p.preference_key]: p.preference_value_type === 'boolean' ? Number(p.preference_value) : p.preference_value,
				};
			});

			setPreferences((prevState) => {
				return {
					...prevState,
					...user_prefs,
				};
			});
		}
	}, [props.data]);

	const handlePreferenceChange = (e) => {
		let { name, value } = e.target;
		let data = {};

		switch (name) {
			case COMMUNICATION_PREFERENCES.AUTO_APPROVE_ASSOCIATE.name:
				setPreferences({
					...preferences,
					[name]: preferences[name] ? 0 : 1,
				});
				data = {
					id: props.data.communication_preferences[0]?.id || random(8),
					object_type: 'USER',
					object_id: props.data.id,
					preference_group: COMMUNICATION_PREFERENCES[name].group,
					preference_key: name,
					preference_value: !preferences[name] ? 1 : 0,
					preference_value_type: COMMUNICATION_PREFERENCES[name].value_type,
				};
				break;
			default:
				setPreferences({
					...preferences,
					[name]: value,
				});
				data = {
					id: props.data.communication_preferences[0]?.id || random(8),
					object_type: 'USER',
					object_id: props.data.id,
					preference_group: COMMUNICATION_PREFERENCES[name].group,
					preference_key: name,
					preference_value: value,
					preference_value_type: COMMUNICATION_PREFERENCES[name].value_type,
				};
				break;
		}
		props.onChangeCommunicationPreferences(data);
	};

	return (
		<div className="user-details-comm_preferences">
			{Object.keys(COMMUNICATION_PREFERENCES).map((p, i) => {
				return (
					<React.Fragment key={i}>
						<span className="user-details-comm_preferences__section-header__title">{COMMUNICATION_PREFERENCES[p].title}</span>
						{[p].subtitle ?
							<span className="user-details-comm_preferences__section-header__subtitle">{COMMUNICATION_PREFERENCES[p].subtitle}:</span>
						:	null}
						<div className="user-details-comm_preferences__reports">
							{COMMUNICATION_PREFERENCES[p].options.map((item, key) => {
								switch (COMMUNICATION_PREFERENCES[p].field_type) {
									case 'radio':
										return (
											<RadioButton
												key={key}
												value={item}
												checked={preferences[p] === item}
												onChange={handlePreferenceChange}
												label={COMMUNICATION_PREFERENCES[p].labels[key]}
												name={p}
											/>
										);
									case 'switch':
										return (
											<Switch
												key={key}
												value={Number(preferences.AUTO_APPROVE_ASSOCIATE)}
												name={p}
												onChange={handlePreferenceChange}
												label={COMMUNICATION_PREFERENCES[p].labels[key]}
												customClassName="user-details-comm_preferences__auto-approve-associate"
												theme="blurple"
											/>
										);
									default:
										break;
								}
							})}
						</div>
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default UserDetailsCommunicationPreferences;
