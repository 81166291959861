//System Imports
import { useState } from 'react';
import PropTypes from 'prop-types';
//UI Imports
import Input from 'com/ui/Input';
import LoaderOverlay from 'com/ui/LoaderOverlay';
//Widget Imports
import Modal from '../Modal';
//Service Imports
import { validateEmailAddress } from 'services/strings';
// hooks
import useNotification from 'services/hooks/use_notification';
//Icon Imports
import ICON_PLUS_BIG from 'assets/images/icons/ico_plus_grey_big.svg';
import ICON_BIN_BIG from 'assets/images/icons/ico_bin_grey_big.svg';
//Style Imports
import './style.css';

const SendEmailModal = (props) => {
	const errInit = {
		email: '',
	};
	const sendNotification = useNotification();
	const [email, setEmail] = useState('');
	const [emailList, setEmailList] = useState([]);
	const [err, setErr] = useState(errInit);
	const [showLoader, setShowLoader] = useState(false);

	const handleChange = (e) => {
		setErr(errInit);
		setEmail(e.target.value);
	};

	const addToEmailList = () => {
		if (!validateEmailAddress(email)) return setErr({ ...err, email: 'Invalid email' });
		if (emailList.includes(email)) return setErr({ ...err, email: 'Email is already in the list' });
		setEmailList([...emailList, email]);
		setEmail('');
		setErr(errInit);
	};

	const removeEmailFromList = (e) => {
		let element = e.target.closest('li');
		if (element.dataset.email) {
			setEmailList([...emailList.filter((ei) => ei !== element.dataset.email)]);
		}
	};

	const sendEmail = async () => {
		try {
			if (emailList.length === 0 && email && !validateEmailAddress(email)) {
				return setErr({ ...err, email: 'Invalid email' });
			}
			setShowLoader(true);
			const emails = emailList.length === 0 && email ? [email] : emailList;
			for (let e of emails) {
				let ep = {
					...props.emailPayload,
					to: e,
				};
				await props.emailHandler(ep);
			}
			props.closeHandler();
			sendNotification({ type: 'success', title: 'Email sent successfully' });
		} catch (error) {
			console.log(error);
			sendNotification({ type: 'error', title: 'Failed to send email' });
		} finally {
			setShowLoader(false);
		}
	};

	const footerActions = [
		{ primary: false, label: 'Cancel', action: props.closeHandler, theme: 'azami-ghost' },
		{ primary: true, label: 'Send', action: sendEmail, theme: 'azami-blue' },
	];

	return (
		<Modal title="Send Email" footerActions={footerActions} closeHandler={props.closeHandler}>
			<LoaderOverlay showLoader={showLoader} />
			<div className="send-email-modal-container">
				<div className="send-email-modal-container__email-input">
					<Input error={err.email} type="email" label="Enter one or more email addresses" value={email} onChange={handleChange} />
					<button onClick={addToEmailList}>
						<img src={ICON_PLUS_BIG} alt="" />
					</button>
				</div>
				<ul className="send-email-modal-container__email-list">
					{emailList.map((e) => {
						return (
							<li data-email={e}>
								<Input disabled={true} label="" value={e} key={e} />
								<button onClick={removeEmailFromList}>
									<img src={ICON_BIN_BIG} alt="" />
								</button>
							</li>
						);
					})}
				</ul>
			</div>
		</Modal>
	);
};

SendEmailModal.propTypes = {
	closeHandler: PropTypes.func,
	emailPayload: PropTypes.object,
	emailHandler: PropTypes.func,
};

SendEmailModal.defaultProps = {
	closeHandler: () => {},
	emailPayload: {},
	emailHandler: () => {},
};

export default SendEmailModal;
