import React, { useContext } from 'react';
import Select from 'com/ui/Select';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { OwnerContext } from 'util/ownerContext';

import './style.css';

const TagSelect = (props) => {
	let name = props.name ? props.name : 'tags';

	const addTag = (e) => {
		let out = {
			target: {
				name: name,
				value: [...props.values.filter((v) => v != e.target.value), e.target.value],
			},
		};
		props.onChange(out);
	};

	const removeTag = (e) => {
		let out = {
			target: {
				name: name,
				value: props.values.filter((v) => v != e.target.dataset.tag),
			},
		};
		props.onChange(out);
	};

	return (
		<div className={`form-tag-select ${props.customClassName}`}>
			{!props.tagsOnTop ?
				<Select forRoles={props.forRoles} label={props.label} name={name} theme={props.theme} options={props.options} onChange={addTag} error={props.error} />
			:	null}
			<ul className="form-tag-select__tag-list">
				{props.values ?
					props.values.map((t, i) => {
						let opt = props.options.filter((o) => o.value === t);
						let label = opt.length > 0 ? opt[0].label : '';
						return (
							<li className="form-tag-select__tag" key={i}>
								<span className="form-tag-select__tag__label">{label}</span>
								<button className="form-tag-select__tag__close" name={name} onClick={removeTag} data-tag={t}>
									&times;
								</button>
							</li>
						);
					})
				:	null}
			</ul>
			{props.tagsOnTop ?
				<Select forRoles={props.forRoles} label={props.label} name={name} theme={props.theme} options={props.options} onChange={addTag} error={props.error} />
			:	null}
		</div>
	);
};

TagSelect.defaultProps = {
	disabled: false,
	forRoles: [],
	tagsOnTop: false,
	customClassName: '',
};

TagSelect.propTypes = {
	theme: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	options: PropTypes.array,
	values: PropTypes.array,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	forRoles: PropTypes.array,
	tagsOnTop: PropTypes.bool,
	customClassName: PropTypes.string,
};

export default TagSelect;
