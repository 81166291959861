import Ajax from './base';

const PartialUpdateByCaseID = (cid, payload) => {
	return Ajax.Request(`/cases/${cid}/details`, Ajax.PATCH, true, payload);
};

const GetByCaseID = (cid, queryStringData) => {
	return Ajax.Request(`/cases/${cid}/details`, Ajax.GET, true, queryStringData);
};

const Create = (cid, payload) => {
	return Ajax.Request(`/cases/${cid}/details`, Ajax.POST, true, payload);
};

const Update = (cid, did, payload) => {
	return Ajax.Request(`/cases/${cid}/details/${did}`, Ajax.PUT, true, payload);
};

export default {
	PartialUpdateByCaseID,
	GetByCaseID,
	Create,
	Update,
};
