import { WIPO_SERVICES } from 'data/constants';

const enableGetWipo = (service_id, services) => {
	let service = services.find((s) => s.id == service_id);
	return service && WIPO_SERVICES.includes(service.key);
};

const formatWipoRequestObj = (application_number, service_id) => {
	let replacer = new RegExp('/', 'g');
	let app_number = application_number.replaceAll(replacer, '');
	return { application_number: app_number, service_id: service_id };
};

export default {
	enableGetWipo,
	formatWipoRequestObj,
};
