import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import FirmRegisterAzami from './FirmRegisterAzami';
import FirmRegisterIPeer from './FirmRegisterIPeer';
import withDomainAuthTemplate from 'com/hoc/withDomainAuthTemplate';

import Auth from 'services/rest/auth';
import Settings from 'services/rest/settings';
import useTitle from 'services/hooks/useTitle';
import useQuery from 'services/hooks/useQuery';

import { PAGE_TITLE } from 'data/constants';

import { getError } from 'util/errors';

import './style.css';

const FirmRegisterTemplate = withDomainAuthTemplate(FirmRegisterAzami, FirmRegisterIPeer);

const Register = () => {
	useTitle(PAGE_TITLE.REGISTER);

	const [spinner, setSpinner] = useState(false);
	const history = useHistory();
	const [countries, setCountries] = useState([]);
	const searchParams = useQuery();
	const firmCode = searchParams.get('firm-code');

	const initialState = {
		first_name: '',
		last_name: '',
		title: 'mr',
		email: '',
		phone: '',
		country: '',
		firm_code: firmCode ? firmCode : '',
		account_executive: '',
		firm_registered: firmCode ? '1' : '0',
		firm_name: '',
		address_1: '',
		address2: '',
		city: '',
		state: '',
		postal_code: '',
		firm_country: '',
		pricing_level: '',
		pricing_level_ep: '',
		password: '',
		password_repeat: '',
		tos_agreement: false,
	};

	useEffect(() => {
		(async () => {
			const countriesData = await Settings.GetRegions({ is_country: 1 });
			setCountries([
				{ label: '', value: '' },
				...countriesData.map((c) => ({
					label: c.name,
					value: c.code,
				})),
			]);
		})();
	}, []);

	const handleSubmit = async (fields, { setStatus }) => {
		let t = await recaptchaToken();
		const data = {
			firm_registered: fields.firm_registered === '1',
			user: {
				email: fields.email,
				first_name: fields.first_name,
				last_name: fields.last_name,
				title: fields.title,
				password: fields.password,
				password_repeat: fields.password_repeat,
				firm_code: fields.firm_code,
				country: fields.country,
			},
			firm: {
				email: fields.email,
				// phone1: fields.phone,
				name: fields.firm_name,
				address: fields.address_1,
				address2: fields.address2,
				city: fields.city,
				country: fields.firm_country,
				state: fields.state,
				zipcode: fields.postal_code,
			},
			grct: t,
			tos_agreement: fields.tos_agreement,
		};

		try {
			setSpinner(true);
			await Auth.CreateAccount(data);
			setSpinner(false);
			history.push('/register/thank-you');
		} catch (err) {
			setStatus(getError(err.code));
			setSpinner(false);
		}
	};

	const recaptchaToken = async () => {
		return new Promise((success, fail) => {
			if (!window.grecaptcha) return success('');
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'submit' }).then((token) => {
					success(token);
				});
			});
		});
	};

	return <FirmRegisterTemplate initialState={initialState} handleSubmit={handleSubmit} countries={countries} spinner={spinner} />;
};

export default Register;
