import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppRoute } from 'com/util/AppRoute';
import Routes from 'data/routes';
import Tooltip from 'com/ui/Tooltip';
import AppLink from 'com/util/AppLink';
import AppDate from 'com/util/AppDate';

import DataTable from 'com/widgets/DataTable';
import { RoleBox } from 'com/util/RoleBox';

const UserDetailsPoints = (props) => {
	const [tableHeaders, setTableHeaders] = useState([]);
	const [caseNumberModalFilter, setCaseNumberModalFilter] = useState(null);
	const params = useParams();
	const directions = [
		{ label: 'All', value: 'ALL' },
		{ label: 'Sent', value: 'CLIENT' },
		{ label: 'Received', value: 'ASSOCIATE' },
	];
	const [direction, setDirection] = useState('ALL');

	useEffect(async () => {
		setTableHeaders([
			{ title: 'Date', field: 'case_created', type: 'date', sortable: false },
			{ title: 'Case No.', field: 'case_number', type: 'string', sortable: false },
			{ title: 'Recip Account', field: 'recip_account_id', type: 'string', sortable: false },
			{ title: 'Client ref.', field: 'client_ref', type: 'string', sortable: false },
			{ title: 'Service', field: 'service_name', type: 'string', sortable: false },
			{ title: 'Region', field: 'region_name', type: 'string', sortable: false },
			{ title: 'Points', field: 'points', type: 'number', sortable: false },
			{ title: 'Actions', field: 'actions', type: 'string', sortable: false },
		]);
	}, []);

	const directionChange = (e) => {
		setDirection(e.target.dataset.name);
	};

	const adjustPointsPopupHandler = (el) => {
		setCaseNumberModalFilter(el.target.dataset.caseNumber);
	};

	return (
		<div className="user-details-points">
			<div className="user-details-points__section-header">
				<span className="user-details-points__section-header__title">Points balance</span>
				<ul className="user-details-points__section-header__directions">
					{directions.map((dir, i) => {
						return (
							<li key={i} onClick={directionChange} data-name={dir.value} className={`dir-filter ${direction === dir.value && 'active'}`}>
								{dir.label}
							</li>
						);
					})}
				</ul>
				<RoleBox roles={['admin']}>
					<AppLink type="regular" to={`/user-management/${params.id}/points/adjustment`} className="form-button light">
						Adjust Points
					</AppLink>
				</RoleBox>
			</div>
			<div className="user-details-points__section-body">
				<DataTable fixedHeader={true} columns={tableHeaders}>
					{props.data.casesRegionsPoints &&
						props.data.casesRegionsPoints.map((p, i) => {
							return (direction === p.direction || direction === 'ALL') && <ExpandableRow key={i} data={p} columns={tableHeaders} adjustPointsPopupHandler={adjustPointsPopupHandler} />;
						})}
				</DataTable>
				<div className="user-details-points__section-footer">
					<a href="/reports/client" className="client-report">
						View Points History
					</a>
				</div>
			</div>
			<AppRoute route={Routes.USER_DETAILS_POINTS_ADJUSTMENT} exact onChange={props.onUpdate} />
		</div>
	);
};

const ExpandableRow = (props) => {
	const params = useParams();
	const [adjustmentsShow, setAdjustmentsShow] = useState(false);

	const tableHeaders = [
		{ title: 'Date', field: 'case_created', type: 'date', sortable: false },
		{ title: 'Case No.', field: 'case_number', type: 'string', sortable: false },
		{ title: 'Recip Account', field: 'recip_account_id', type: 'string', sortable: false },
		{ title: 'Client ref.', field: 'client_ref', type: 'string', sortable: false },
		{ title: 'Service', field: 'service_name', type: 'string', sortable: false },
		{ title: 'Region', field: 'region_name', type: 'string', sortable: false },
		{ title: 'Points', field: 'points', type: 'number', sortable: false },
		{ title: 'Adjusted By', field: 'points_adjusted_by', type: 'string', sortable: false },
		{ title: 'Comment', field: 'comment', type: 'string', sortable: false },
	];

	const toggleAdjustments = () => {
		setAdjustmentsShow(!adjustmentsShow);
	};

	const toggleShow = () => {
		return adjustmentsShow && hasAdjustments();
	};

	const hasAdjustments = () => {
		return props.data.adjustments.length > 1;
	};

	return (
		<RoleBox roles={['$user', 'admin', 'sales', 'sales_manager']}>
			<tr onClick={toggleAdjustments} className="pointer" style={{ backgroundColor: toggleShow() ? 'var(--clr-lightblue)' : '' }}>
				<DataTable.DateCell>{props.data.case_created}</DataTable.DateCell>
				<DataTable.Cell>{props.data.case_number}</DataTable.Cell>
				<DataTable.Cell>{props.data.firm_account_name}</DataTable.Cell>
				<DataTable.Cell>{props.data.client_name}</DataTable.Cell>
				<DataTable.Cell>{props.data.service_name}</DataTable.Cell>
				<DataTable.CountryCell code={props.data.region_code || ''}>{props.data.region_name}</DataTable.CountryCell>
				<DataTable.PointsCell adjusted={hasAdjustments()}>{props.data.amount}</DataTable.PointsCell>
				<RoleBox roles={['admin']}>
					<DataTable.Cell>
						<AppLink type="regular" to={`/user-management/${params.id}/points/adjustment?case_number=${props.data.case_number}`} title="Edit" className="adjust"></AppLink>
					</DataTable.Cell>
				</RoleBox>
			</tr>
			{hasAdjustments() ?
				<tr style={{ display: toggleShow() ? 'table-row' : 'none', backgroundColor: '#FFCE00' }}>
					<td colSpan={tableHeaders.length} className="drawer">
						<div>
							<table cellSpacing="0" cellPadding="0" className="nested-table">
								<thead>
									<tr>
										{tableHeaders.map((c, i) => (
											<th key={i}>{c.title}</th>
										))}
									</tr>
								</thead>
								<tbody>
									{props.data.adjustments.map((d, i) => {
										return (
											<tr key={i} className={d.parent_id ? 'adjustment-row' : ''}>
												<DataTable.DateCell>{d.case_created}</DataTable.DateCell>
												<DataTable.Cell>{d.case_number}</DataTable.Cell>
												<DataTable.Cell>{d.firm_account_name}</DataTable.Cell>
												<DataTable.Cell>{d.client_name}</DataTable.Cell>
												<DataTable.Cell>{d.service_name}</DataTable.Cell>
												<DataTable.CountryCell code={props.data.region_code || ''}>{props.data.region_name}</DataTable.CountryCell>
												<DataTable.PointsCell isAdjustmentRow={d.parent_id}>{d.amount}</DataTable.PointsCell>
												<DataTable.Cell>
													{d.parent_id && (
														<div className="adjusted-cell">
															{d._modified && (
																<span className="adjusted-date">
																	<AppDate>{d._modified}</AppDate>
																</span>
															)}
															{d.points_adjusted_by}
														</div>
													)}
												</DataTable.Cell>
												<DataTable.Cell>{d.parent_id && <Tooltip content={d.reason || d.custom_reason} direction="bottom"></Tooltip>}</DataTable.Cell>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</td>
				</tr>
			:	null}
		</RoleBox>
	);
};

export default UserDetailsPoints;
