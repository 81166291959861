//vendor imports
import React, { useState } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
//widgets
import Modal from 'com/widgets/Modal';
import EventLoggerEvents from 'services/rest/event_logger';

import { setIsUpdated, redirectInit } from 'redux/ducks/redirect';

const CustomLink = (props) => {
	const user = useSelector((state) => state.auth.user);
	const redirect = useSelector((state) => state.redirect);

	const [showModal, setShowModal] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const handleConfirm = () => {
		handleTrackClick();
		dispatch(setIsUpdated(redirectInit));
		history.push(props.to);
	};

	const handleCancel = () => {
		setShowModal(false);
	};

	const navigationActions = [
		{ primary: false, label: 'Cancel', action: handleCancel },
		{ primary: true, label: 'Confirm', action: handleConfirm },
	];

	const handleClick = (e) => {
		const parsedUrl = window.location.href;
		let splittedPath = location.pathname.split('/');
		let isNavigating = true;
		if (props.checkRedirect) {
			for (let f of props.checkRedirectFrom) {
				let match = false;
				if (redirect.checkExact && f === location.pathname) {
					match = true;
				} else {
					if (splittedPath.includes(f)) match = true;
				}

				if (match) {
					isNavigating = false;
					e.preventDefault();
					if (parsedUrl.startsWith(location.pathname)) {
						handleConfirm();
					} else {
						setShowModal(true);
					}
					break;
				}
			}
		}

		if (isNavigating) {
			handleTrackClick();
		}
	};

	const handleTrackClick = () => {
		if (!props.trackClick) return;

		let event = props.event || `${props.children} Link Clicked`;
		EventLoggerEvents.EventLogger({ event: event, data: { user: user } });
	};

	let { type, checkRedirect, activeClassName, checkRedirectFrom, trackClick, ...rest } = props;

	return (
		<>
			{type === 'nav' ?
				<NavLink {...rest} activeClassName={activeClassName} onClick={handleClick} />
			:	<Link {...rest} onClick={handleClick} />}
			{showModal ?
				<Modal closeHandler={handleCancel} title="Navigation confirmation" footerActions={navigationActions}>
					{redirect.message ? redirect.message : 'Are you sure you want to discard all unsaved edits?'}
				</Modal>
			:	null}
		</>
	);
};

export default CustomLink;

CustomLink.defaultProps = {
	type: 'regular',
	to: '',
	className: '',
	activeClassName: 'active',
	// checkRedirect: false,
	// exact: false,
	checkRedirect: false,
	checkRedirectFrom: [],
	trackClick: false,
	target: '_self',
	event: '',
};

CustomLink.propTypes = {
	type: PropTypes.oneOf(['nav', 'regular']).isRequired,
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	className: PropTypes.string,
	activeClassName: PropTypes.string,
	exact: PropTypes.bool,
	checkRedirect: PropTypes.bool,
	children: PropTypes.node,
	checkRedirectFrom: PropTypes.array,
	trackClick: PropTypes.bool,
	target: PropTypes.string,
	event: PropTypes.string,
};
