import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getFlag } from 'data/flags';

import Input from 'com/ui/Input';
import Checkbox from 'com/ui/Checkbox';
import { RoleBox } from 'com/util/RoleBox';

import { INTERNAL_ROLES, SERVICE_KEYS, EUROPEAN_VALIDATION_EXT_REGIONS, ROLE_TYPES } from 'data/constants';

import './style.css';

const RegionPicker = (props) => {
	const [search, setSearch] = useState('');
	const [regions, setRegions] = useState([]);
	const [selectedRegions, setSelectedRegions] = useState([]);
	const user = useSelector((state) => state.auth.user);

	useEffect(() => {
		setRegions(props.regions);
	}, [props.regions]);

	useEffect(() => {
		if (props.selectedRegions) {
			// Generate an array of selected region objects with name, id, code, and sort them by their name
			let selected_regions = [];
			selected_regions = props.selectedRegions.map((r) => {
				let region = props.regions.find((reg) => reg.id === r);
				if (region) {
					return { id: region.id, code: region.code, name: region.name };
				}
			});
			selected_regions.sort((a, b) => {
				let na = a.name.toLowerCase(),
					nb = b.name.toLowerCase();
				if (na < nb) return -1;
				if (na > nb) return 1;
				return 0;
			});
			setSelectedRegions(selected_regions);
		}
	}, [props.selectedRegions]);

	const inputChange = (e) => {
		setSearch(e.target.value);
		let str = e.target.value.toLowerCase().trim();
		let matches = props.regions.filter((element) => {
			let region_label = `${element.name}${element.code}`.toLowerCase().trim();
			if (props.selectedServiceKey === SERVICE_KEYS.PCT) {
				region_label = `${element.name}${element.code}${element.deadline ? element.deadline : ''}`;
			}
			region_label = region_label.toLowerCase().trim();
			if (region_label.includes(str)) {
				return true;
			}
		});
		setRegions(matches);
	};

	const selectAll = () => {
		let tmp = props.regions.map((r) => {
			return r.id;
		});
		props.onChange(tmp);
	};

	const selectAllExt = () => {
		let tmp = [];
		props.regions.map((r) => {
			if (!EUROPEAN_VALIDATION_EXT_REGIONS.includes(r.code) && !r.name.startsWith('Translation:')) {
				tmp.push(r.id);
			}
		});
		props.onChange(tmp);
	};

	const removeAll = () => {
		props.onChange([]);
	};

	const regionPickerOnChange = (e) => {
		if (e.target.checked) {
			props.onChange([...props.selectedRegions, e.target.name]);
		} else if (isRegionRemovable(e.target.name)) {
			props.onChange([...props.selectedRegions.filter((r) => r !== e.target.name)]);
		}
	};

	const isRegionRemovable = (region_id) => {
		return !props.addRegions || (user.roleType === ROLE_TYPES.INTERNAL && props.quoteState === 'QUOTE') || !props.quoteRegions.includes(region_id);
	};

	const removeRegion = (e) => {
		props.onChange([...props.selectedRegions.filter((r) => r !== e.target.dataset.id)]);
		// mark region documents with delete flag
		props.setDocuments((prevState) => {
			return prevState.map((d) => {
				if (d.region_id === e.target.dataset.id) {
					d.for_delete = true;
				}
				return d;
			});
		});
	};

	return (
		<div className="region-picker-container">
			<div className="region-picker-container__left">
				<Input name="search" placeholder="Search" value={search} onChange={inputChange} />
				<div className="region-picker-container__left-filters">
					<div className="region-picker-container__left-filters-left">
						{props.selectedServiceKey !== SERVICE_KEYS.EP ?
							<RoleBox roles={INTERNAL_ROLES}>
								<span onClick={selectAll}>Select All</span>
							</RoleBox>
						:	null}

						{!props.quoteRegionPicker || props.selectedServiceKey === SERVICE_KEYS.EP ?
							<span onClick={selectAllExt}>Select All (Except Ext. States)</span>
						:	null}
					</div>
					{!props.addRegions ?
						<div className="region-picker-container__left-filters-right">
							<span onClick={removeAll}>Remove All</span>
						</div>
					:	null}
				</div>
				<ul className="region-picker-container__left-list">
					{regions.map((o, i) => {
						return (
							<li key={i}>
								<Checkbox
									themeChecked={'blurple'}
									name={o.value}
									label={o.label}
									onChange={regionPickerOnChange}
									img={getFlag(o.code)}
									checked={props.selectedRegions.includes(o.value)}
								/>
								<span className="region-picker-container__left-list-code">{`${o.code}${
									props.selectedServiceKey === SERVICE_KEYS.PCT ?
										o.deadline ?
											` ${o.deadline}`
										:	''
									:	''
								}`}</span>
							</li>
						);
					})}
				</ul>
			</div>

			<div className="region-picker-container__right">
				{props.showCounter ?
					<div className="region-picker-container__right-counter">{selectedRegions && selectedRegions.length > 0 ? `${selectedRegions.length} Regions Selected` : `No Regions Selected`}</div>
				:	null}
				<ul className="region-picker-container__right-selected">
					{selectedRegions &&
						selectedRegions.map((r) => {
							return r ?
									<li key={r?.id}>
										{props.showFlags ?
											<img src={getFlag(r?.code)} />
										:	null}
										{r?.name}
										{isRegionRemovable(r.id) ?
											<button data-id={r?.id} onClick={removeRegion}>
												&times;
											</button>
										:	null}
									</li>
								:	null;
						})}
				</ul>
			</div>
		</div>
	);
};

RegionPicker.propTypes = {
	setDocuments: () => {},
};

RegionPicker.defaultProps = {
	setDocuments: () => {},
};

export default RegionPicker;
