import Ajax from './base';

const Create = (payload) => {
	return Ajax.Request('/settings/banners', Ajax.POST, false, payload);
};

const UpdateByID = (id, payload) => {
	return Ajax.Request(`/settings/banners/${id}`, Ajax.PUT, false, payload);
};

const deleteByID = (id) => {
	return Ajax.Request(`/settings/banners/${id}`, Ajax.DELETE, false);
};

const GetOneByID = (id) => {
	return Ajax.Request(`/settings/banners/${id}`, Ajax.GET, false);
};

const GetAll = (queryStringData) => {
	return Ajax.Request('/settings/banners', Ajax.GET, false, queryStringData);
};

export default {
	Create,
	UpdateByID,
	GetOneByID,
	GetAll,
	deleteByID,
};
