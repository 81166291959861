// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
//styles
import './style.css';
import { CASE_DIRECTIONS, CASE_DIRECTIONS_OPTIONS } from 'data/constants';
//icons
import ICON_ARROW_UP from 'assets/images/icons/ico_arrow_up.svg';
import ICON_ARROW_DOWN from 'assets/images/icons/ico_arrow_down.svg';

const CaseDirectionFilter = (props) => {
	const items = props.items.length > 0 ? props.items : CASE_DIRECTIONS_OPTIONS;
	const icons = {
		[CASE_DIRECTIONS.SENT]: ICON_ARROW_UP,
		[CASE_DIRECTIONS.RECEIVED]: ICON_ARROW_DOWN,
	};

	return (
		<ul className="direction-list">
			{items.map((dir, i) => {
				return (
					<li key={i} onClick={props.onClick} data-name={dir.value} className={`${props.selectedDirection === dir.value && 'active'}`}>
						<img src={icons[dir.value]} />
						<span>{dir.label}</span>
					</li>
				);
			})}
		</ul>
	);
};

CaseDirectionFilter.defaultProps = {
	items: [],
	onClick: () => {},
	selectedDirection: '',
};

CaseDirectionFilter.propTypes = {
	items: PropTypes.array,
	onClick: PropTypes.func,
	selectedDirection: PropTypes.string,
};

export default CaseDirectionFilter;
