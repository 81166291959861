export const isoStringToMySQLDate = (date) => {
	return new Date(date).toISOString().split('T')[0];
};

export const toLocaleDateString = (date) => {
	return new Date(date).toLocaleDateString();
};

export const toLocaleTimeString = (date) => {
	return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
};

export const toDateString = (date) => {
	return new Date(date).toDateString();
};

/**
 * This function converts ISO date to yyyy-mm-dd date format
 * that can later be used that date to set value to 'input type date' html element
 */
export const toENCADateString = (date) => {
	return new Date(date).toLocaleDateString('en-CA');
};

export const formatToInputLocaleDate = (date) => {
	let d = new Date(date);

	let year = String(d.getFullYear());
	let month = String(d.getMonth() + 1).padStart(2, 0);
	let dv = String(d.getDate()).padStart(2, 0);
	let hours = String(d.getHours()).padStart(2, 0);
	let minutes = String(d.getMinutes()).padStart(2, 0);

	return `${year}-${month}-${dv}T${hours}:${minutes}`;
};
