import AuthTemplate from 'com/templates/AuthTemplate';
import AuthTemplateIPeer from 'com/templates/AuthTemplateIPeer';
import { DOMAINS } from 'data/constants';
import { getDomain } from 'services/strings';

const withDomainAuthTemplate = (WrapperComponentAzami, WrappedComponentIPeer) => {
	return (props) => {
		let domain = getDomain();

		// the `btoa()` function encodes a string (in this case, the domain) into base64 format.
		// this encoding is used to match the domain against the base64-encoded domains stored in DOMAINS.
		if (DOMAINS.OPV2.includes(btoa(domain))) {
			return (
				<AuthTemplate>
					<WrapperComponentAzami {...props} />
				</AuthTemplate>
			);
		} else if (DOMAINS.IPEER.includes(btoa(domain))) {
			return (
				<AuthTemplateIPeer>
					<WrappedComponentIPeer {...props} />
				</AuthTemplateIPeer>
			);
		} else {
			return (
				<AuthTemplate>
					<WrapperComponentAzami {...props} />
				</AuthTemplate>
			);
		}
	};
};

export default withDomainAuthTemplate;
