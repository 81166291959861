import React from 'react';
import AppLink from 'com/util/AppLink';

import ICON_REGISTER from 'assets/images/icons/create_account_blue.svg';

const LoginFooterAzami = () => {
	return (
		<AppLink type="regular" className="login-footer-azami" to="/register">
			<img src={ICON_REGISTER} className="" alt="" />
			<div className="login-footer-azami__message">
				<span className="login-footer-azami__message__top">Don't have an account yet?</span>
				<span className="login-footer-azami__message__bottom">Click here to create one</span>
			</div>
		</AppLink>
	);
};

export default LoginFooterAzami;
