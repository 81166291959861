//System Imports
import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
//Local Component Imports
import SendEmailModal from './SendEmailModal';
//Icon Imports
import ICON_COPY from 'assets/images/icons/ico_copy_white.svg';
import ICON_SEND_EMAIL from 'assets/images/icons/ico_send_white.svg';
//Style Imports
import './style.css';

const CopyToClipboard = (props) => {
	const { copyContent, copyContentLabel, withEmailOption, emailPayload, emailHandler, forRoles } = props;

	const auth = useSelector((state) => state.auth);
	const copyMenuEnabled = auth.user.role.some((r) => forRoles.includes(r)) || forRoles.includes('*');

	const copy = () => {
		const type = 'text/html';
		const blob = new Blob([copyContent], { type });
		const data = [new window.ClipboardItem({ [type]: blob })];

		navigator.clipboard
			.write(data)
			.then(() => {
				console.log('Content Copied');
			})
			.catch((err) => {
				console.error('Could not copy text', err);
			});
	};

	return (
		<>
			{/* copy to clipboard is available only for specified roles */}
			{copyMenuEnabled ?
				<div className="copy-to-clipboard-container">
					<CopyToClipboardToolTip onCopy={copy} copyContentLabel={copyContentLabel} withEmailOption={withEmailOption} emailPayload={emailPayload} emailHandler={emailHandler} />
					{props.children}
				</div>
			:	<>{props.children}</>}
		</>
	);
};

const CopyToClipboardToolTip = (props) => {
	const [emailModalOpen, setEmailModalOpen] = useState(false);

	const openSendEmailModal = () => {
		setEmailModalOpen(true);
	};

	const closeSendEmailModal = () => {
		setEmailModalOpen(false);
	};

	return (
		<ul className="copy-to-clipboard-container__tooltip-list">
			<li onClick={props.onCopy}>
				<img src={ICON_COPY} alt="" />
				<span>{props.copyContentLabel}</span>
			</li>
			{props.withEmailOption && (
				<li onClick={openSendEmailModal}>
					<img src={ICON_SEND_EMAIL} alt="" />
					<span>send e-mail</span>
				</li>
			)}
			<>
				{props.withEmailOption && emailModalOpen ?
					<SendEmailModal emailPayload={props.emailPayload} emailHandler={props.emailHandler} closeHandler={closeSendEmailModal} />
				:	null}
			</>
		</ul>
	);
};

CopyToClipboard.propTypes = {
	copyContent: PropTypes.string,
	copyContentLabel: PropTypes.string,
	withEmailOption: PropTypes.bool,
	emailPayload: PropTypes.object,
	emailHandler: PropTypes.func,
	forRoles: PropTypes.array,
};

CopyToClipboard.defaultProps = {
	copyContent: '',
	copyContentLabel: '',
	withEmailOption: false,
	emailPayload: {},
	emailHandler: () => {},
	forRoles: [],
};

CopyToClipboardToolTip.propTypes = {
	onCopy: PropTypes.func,
	copyContentLabel: PropTypes.string,
	withEmailOption: PropTypes.bool,
	emailPayload: PropTypes.object,
	emailHandler: PropTypes.func,
};

CopyToClipboardToolTip.defaultProps = {
	onCopy: () => {},
	copyContentLabel: '',
	withEmailOption: false,
	emailPayload: {},
	emailHandler: () => {},
};

export default CopyToClipboard;
