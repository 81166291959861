import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppLink from 'com/util/AppLink';
import { RoleBox } from 'com/util/RoleBox';
import Button from 'com/ui/Button';

import StatusProgressBar from 'com/widgets/StatusProgressBar';
import DataTable from 'com/widgets/DataTable';
import StatusUpdateButton from 'com/widgets/StatusUpdateButton';

import { INTERNAL_ROLES, CASES_REGIONS_STATUSES } from 'data/constants';

import ICON_CHECKED from 'assets/images/icons/ico_status_checked.svg';
import ICON_UNCHECKED from 'assets/images/icons/ico_status_unchecked.svg';

import useQuery from 'services/hooks/useQuery';

import './style.css';
import AppDate from 'com/util/AppDate';

import Cases from 'services/rest/cases';
import Estimates from 'services/rest/estimates';
import useNotification from 'services/hooks/use_notification';

const CaseDetailsInstructed = ({ regions, filterData, setFilterData, unInstructableCase, setInstructionDeadlineModal, caseStatuses, fetchData }) => {
	const tableHeadersInit = [
		{ title: 'Region', field: 'region_name', sort: 'none', sortable: true },
		{ title: 'Client', field: 'client', sort: 'none', sortable: false },
		{ title: 'Ref.No.', field: 'ref_number', sort: 'none', sortable: false },
		{ title: 'Associate', field: 'associate_first_name', sort: 'none', sortable: true },
		{ title: 'Deadline', field: 'due_date', sort: 'none', sortable: true },
	];
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [isCaseInstructedFromIPEER, setIsCaseInstructedFromIPEER] = useState(false);
	const { id } = useParams();
	const history = useHistory();
	const sendNotification = useNotification();
	const query = useQuery();
	const queryReload = query.get('reload_case_details');

	useEffect(async () => {
		//We are passing a query parameter from the instruct modal window back to the case details page to reload the data
		//because closing the modal window, which is on a separate route, after instruct doesn’t refresh the case details page automatically
		if (queryReload === '1') {
			await fetchData();
		}
		//reset filter if set from another tab
		if (filterData.order_by.length > 0) {
			setFilterData({
				fileName: '',
				region: '',
				category: '',
				attached_by: '',
				order_by: [],
			});
		}
	}, []);

	/**
	 * Case region can be instructed from OPV2 or IPEER, we need somehow to check if the regions are instructed from OPV2 or IPEER
	 * this code will be changed in the future when the case region flag that checks if case is instructed from OPV2 or IPEER is moved on case level,
	 * and not on case region level like its atm.
	 *
	 * We need to check if there is at least one case region that is instructed from IPEER to determinate the headers
	 */
	useEffect(() => {
		if (regions.length > 0) {
			setIsCaseInstructedFromIPEER(regions.some((r) => r.oon_associate));
		}
	}, [regions]);

	useEffect(() => {
		if (isCaseInstructedFromIPEER) {
			setTableHeaders([
				...tableHeadersInit,
				{ title: 'Accepted', field: 'accepted', sort: 'none', sortable: false },
				{ title: 'Completed', field: 'completed', sort: 'none', sortable: false },
				{ title: '', field: 'action', sort: 'none', sortable: false },
			]);
		} else {
			setTableHeaders([...tableHeadersInit, { title: 'Milestones', field: 'milestone', sort: 'none', sortable: false }, { title: '', field: 'action', sort: 'none', sortable: false }]);
		}
	}, [isCaseInstructedFromIPEER]);

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filterData.order_by.filter((r) => r.field === col).length === 0) {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by],
						});
					} else {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
	};

	const unInstructableCaseAction = (e) => {
		e.stopPropagation();
		setInstructionDeadlineModal(true);
	};

	const pushToCAS = async (e) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			let caseData = await Cases.GetOne(id);
			Estimates.SyncToCAS(caseData.case_number, caseData.state);
			sendNotification({ type: 'success', title: 'Push to CAS started. You must visually verify on CAS.' });
		} catch (err) {
			sendNotification({ type: 'error', title: 'Push to CAS Failed.' });
		}
	};

	const getAcceptedStatus = (status) => {
		if (status === CASES_REGIONS_STATUSES.INSTRUCTIONS_RECEIVED_BY_ASSOCIATE.status || status === CASES_REGIONS_STATUSES.INITIAL_CONFIRMATION_RECEIVED.status) {
			return { ico: ICON_CHECKED };
		} else {
			return { ico: ICON_UNCHECKED };
		}
	};

	const getCompletedStatus = (status) => {
		if (status === CASES_REGIONS_STATUSES.INITIAL_CONFIRMATION_RECEIVED.status) {
			return { ico: ICON_CHECKED };
		} else {
			return { ico: ICON_UNCHECKED };
		}
	};

	return (
		<div className="case-details-instructed">
			<RoleBox roles={INTERNAL_ROLES}>
				<div className="">
					<Button className={'form-button azami-blue'} theme="azami-blue" onClick={pushToCAS}>
						Push To CAS
					</Button>
				</div>
			</RoleBox>
			<div className="case-details-instructed__header">
				{unInstructableCase ?
					<Button className={'form-button azami-blue'} theme="azami-blue" onClick={unInstructableCaseAction}>
						Instruct
					</Button>
				:	<AppLink to={`/case-details/${id}/uninstructed/instruct`} className="form-button azami-blue">
						Instruct
					</AppLink>
				}
			</div>
			<div className="case-details-instructed__main-screen">
				<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort} customClassName="case-details-instructed__table">
					{regions.length > 0 &&
						regions.map((r) => {
							return (
								<DataTable.Row key={r.id}>
									<DataTable.CountryCell code={r.region_code}>{r.region_name}</DataTable.CountryCell>
									<DataTable.Cell>{r.client_firm_name}</DataTable.Cell>
									<DataTable.Cell>{r.client_ref_number}</DataTable.Cell>
									<DataTable.Cell>{r.associate_firm_name}</DataTable.Cell>
									<DataTable.Cell>
										<AppDate>{r.due_date}</AppDate>
									</DataTable.Cell>
									{!isCaseInstructedFromIPEER && (
										<DataTable.Cell>
											<StatusProgressBar status={r.milestone} order={r.mil_order} />
										</DataTable.Cell>
									)}
									{isCaseInstructedFromIPEER && (
										<>
											<DataTable.StatusCell status={getAcceptedStatus(r.ext_status)} />
											<DataTable.StatusCell status={getCompletedStatus(r.ext_status)} />
										</>
									)}
									<DataTable.Cell customClassName="case-details-instructed__table-update-status-cell">
										{r.oon_associate ?
											<StatusUpdateButton caseStatuses={caseStatuses} data={r} fetchData={fetchData} filterData={filterData} />
										:	null}
									</DataTable.Cell>
								</DataTable.Row>
							);
						})}
				</DataTable>
			</div>
		</div>
	);
};

CaseDetailsInstructed.propTypes = {
	regions: PropTypes.array,
	filterData: PropTypes.object,
	setFilterData: PropTypes.func,
	unInstructableCase: PropTypes.bool,
};

CaseDetailsInstructed.defaultProps = {
	regions: [],
	filterData: {},
	setFilterData: () => {},
	unInstructableCase: false,
};

export default CaseDetailsInstructed;
