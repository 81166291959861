import { useLocation } from 'react-router-dom';

const useFilterStorage = () => {
	const location = useLocation();
	// this constant follows a naming convention for filters, e.g. "filter_user-management".
	const key = `filter_${location.pathname.replace('/', '').replaceAll('/', '-')}`;

	const getFilterValue = () => {
		const storedFilter = localStorage.getItem(key);
		if (storedFilter) {
			const { filter, timestamp } = JSON.parse(storedFilter);
			const filterTimestamp = new Date(timestamp);
			filterTimestamp.setHours(filterTimestamp.getHours() + 2);
			const now = new Date();
			if (now < filterTimestamp) {
				return filter;
			} else {
				localStorage.removeItem(key);
			}
		}
		return null;
	};

	const setFilterValue = (filter) => {
		const now = new Date();
		const f = JSON.stringify({ filter, timestamp: now });
		localStorage.setItem(key, f);
	};

	return {
		getFilterValue,
		setFilterValue,
	};
};

export default useFilterStorage;
