import Button from 'com/ui/Button';
import ICON_SAVE from 'assets/images/icons/ico_save.svg';

import './style.css';

const QuoteActionsHeader = (props) => {
	return (
		<div className="quote-actions-header">
			<Button theme="bordered" onClick={props.saveAsDraft} showSpinner={props.draftSpinner} className="new-quote__footer__btn-margin">
				<img src={ICON_SAVE} alt="" />
				Save As Draft
			</Button>
			<Button theme="bordered" onClick={props.headerCancel}>
				Close
			</Button>
		</div>
	);
};

export default QuoteActionsHeader;
