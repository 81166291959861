import Ajax from './base';

const GetFirmRegionsData = (fid) => {
	return Ajax.Request(`/bi/map/firm/${fid}/cases`, Ajax.GET, false);
};

const GetRegionAgentsData = (fid, rid) => {
	return Ajax.Request(`/bi/map/firm/${fid}/agents/region/${rid}`, Ajax.GET, false);
};

const GetRegionReceivedCasesData = (fid, rid) => {
	return Ajax.Request(`/bi/map/firm/${fid}/cases/region/${rid}/received`, Ajax.GET, false);
};

const GetRegionSentCasesData = (fid, rid) => {
	return Ajax.Request(`/bi/map/firm/${fid}/cases/region/${rid}/sent`, Ajax.GET, false);
};

const GetRegionData = (rid) => {
	return Ajax.Request(`/bi/map/region/${rid}`, Ajax.GET, false);
};

const GetGeoJsonData = async () => {
	let res = await fetch('https://op-v2-assets.s3.eu-central-1.amazonaws.com/scripts/regionGeoData.json');
	return await res.json();
};

const GetCountriesData = async () => {
	return Ajax.Request(`/bi/map/countries`, Ajax.GET, false);
};

export default {
	GetFirmRegionsData,
	GetRegionAgentsData,
	GetRegionReceivedCasesData,
	GetRegionSentCasesData,
	GetRegionData,
	GetGeoJsonData,
	GetCountriesData,
};
