import React from 'react';

import RegisterThankyouAzami from './RegisterThankyouAzami';
import RegisterThankyouIPeer from './RegisterThankyouIPeer';
import withDomainAuthTemplate from 'com/hoc/withDomainAuthTemplate';

import { PAGE_TITLE } from 'data/constants';

import useTitle from 'services/hooks/useTitle';

const RegisterThankyouTemplate = withDomainAuthTemplate(RegisterThankyouAzami, RegisterThankyouIPeer);

const RegisterThankyou = () => {
	useTitle(PAGE_TITLE.REGISTER);

	return <RegisterThankyouTemplate />;
};

export default RegisterThankyou;
