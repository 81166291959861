import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'com/ui/Checkbox';

import './style.css';

const MultiSelect = (props) => {
	const ref = useRef();
	const [showDropdown, setShowDropdown] = useState(false);
	const [listedValues, setListedValues] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const errorBorder = props.error.length > 0 ? 'form-multiselect__error-border' : '';

	const toggleDropdown = () => {
		setShowDropdown(!showDropdown);
	};

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (showDropdown && ref.current && !ref.current.contains(e.target)) {
				setShowDropdown(false);
			}
		};
		document.addEventListener('mousedown', checkIfClickedOutside);
		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [showDropdown]);

	useEffect(() => {
		if (props.options.length) {
			let vals = props.values.length <= 5 ? props.values : props.values.slice(0, 5);
			setListedValues(vals.map((v) => props.options.filter((o) => o.value === v)[0].label));
		}
	}, [props.values, props.options]);

	const onChange = (e) => {
		if (e.target.checked) {
			if (e.target.name === 'SELECT_ALL') {
				props.onChange({ target: { name: props.name, value: props.options.map((o) => o.value) } });
				setSelectAll(true);
			} else {
				props.onChange({ target: { name: props.name, value: [...props.values, e.target.name] } });
			}
		} else {
			if (e.target.name === 'SELECT_ALL') {
				props.onChange({ target: { name: props.name, value: [] } });
				setSelectAll(false);
			} else {
				props.onChange({ target: { name: props.name, value: props.values.filter((r) => r !== e.target.name) } });
			}
		}
	};

	return (
		<div className={`form-multiselect ${props.customClassName}`} ref={ref}>
			<span className={`form-multiselect__label ${props.theme}`}>{props.label}</span>
			<div className={`form-multiselect__input ${errorBorder}`}>
				<div className={`form-multiselect__input__field ${props.theme}`} onClick={toggleDropdown}>
					<span className="form-multiselect__input__field__text">{props.values.length ? props.values.length : 'None'} selected</span>
				</div>
				{showDropdown && (
					<div className={`form-multiselect__input__dropdown ${props.theme}`} onBlur={toggleDropdown}>
						<div className="form-multiselect__input__dropdown__selected">
							{listedValues.map((v, i) => {
								return (
									<span key={i} className="form-multiselect__input__dropdown__selected__item" title={v}>
										{v}
									</span>
								);
							})}
							{props.values.length > 5 ?
								<span className="form-multiselect__input__dropdown__selected__rest">+ {props.values.length - 5} more</span>
							:	null}
						</div>
						<ul className="form-multiselect__input__dropdown__list">
							{props.selectAllEnabled ?
								<li className="form-multiselect__input__dropdown__list__item" key="SELECT_ALL">
									<Checkbox name="SELECT_ALL" label="Select All" onChange={onChange} checked={selectAll} forRoles={props.forRoles} />
								</li>
							:	null}
							{props.options.map((o, i) => {
								return (
									<li className="form-multiselect__input__dropdown__list__item" key={i}>
										<Checkbox name={o.value} label={o.label} img={o.img} onChange={onChange} checked={props.values.includes(o.value)} forRoles={props.forRoles} />
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</div>
			{props.error && <span className="form-multiselect__error">{props.error}</span>}
		</div>
	);
};

MultiSelect.propTypes = {
	customClassName: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.array,
	theme: PropTypes.oneOf(['light', 'dark']),
	options: PropTypes.array,
	onChange: PropTypes.func,
	selectAllEnabled: PropTypes.bool,
	disabled: PropTypes.bool,
	forRoles: PropTypes.array,
};

MultiSelect.defaultProps = {
	customClassName: '',
	label: '',
	error: [],
	theme: 'light',
	options: [],
	onChange: () => {},
	selectAllEnabled: false,
	disabled: false,
	forRoles: [],
};

export default MultiSelect;
