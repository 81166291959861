import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AppTemplate from 'com/templates/ApplicationTemplate';
import DataTable from 'com/widgets/DataTable';

import { GetFirmReport, DownloadClientReport, DownloadReportSpreadsheetTest } from 'services/rest/reports';
import Firms from 'services/rest/firms';
import FirmAccounts from 'services/rest/firm_accounts';
import Settings from 'services/rest/settings';
import { isoStringToMySQLDate } from 'services/dates';
import { POINTS_TRANSACTION_TYPE, EXTERNAL_ROLES, PAGE_TITLE, INTERNAL_ROLES, ROLE_TYPES } from 'data/constants';

import Select from 'com/ui/Select';
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import IconButton from 'com/ui/IconButton';
import LoaderOverlay from 'com/ui/LoaderOverlay';

import { RoleBox } from 'com/util/RoleBox';

import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';

import './style.css';

import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';
import ICON_PDF from 'assets/images/icons/ico_pdf.svg';
import ICON_EXCEL from 'assets/images/icons/ico_excel.svg';

const ClientReport = () => {
	useTitle(PAGE_TITLE.MEMBER_REPORT);

	const initData = { sent: [], received: [], all: [] };
	const initFirms = [];
	const initFirmAccounts = [];
	const initServices = [];
	const filterInit = { direction: '', firm_id: '', firm_account_id: '', service: '', date_from: '', date_to: '' };
	let reports = [
		{ label: 'All', value: 'all' },
		{ label: 'Sent Cases', value: 'sent' },
		{ label: 'Recieved Cases', value: 'received' },
	];

	const [data, setData] = useState(initData);
	const [filter, setFilter] = useState(filterInit);
	const [firms, setFirms] = useState(initFirms);
	const [firmAccounts, setFirmAccounts] = useState(initFirmAccounts);
	const [services, setServices] = useState(initServices);
	const [direction, setDirection] = useState('all');
	const [showLoader, setShowLoader] = useState(false);
	const [firm, setFirm] = useState({ name: '', id: '' });
	const u = useSelector((state) => state.auth.user);
	const { getFilterValue, setFilterValue } = useFilterStorage();

	useEffect(() => {
		fetchData();
		let f = getFilterValue();
		if (f) {
			setFilter(f);
			fetchFirmData(f);
		}
	}, []);

	useEffect(() => {
		fetchFirmAccounts();
	}, [filter.firm_id]);

	useEffect(() => {
		if (u.roleType === ROLE_TYPES.EXTERNAL) {
			setSentTableHeaders(sentTableHeaders.filter((h) => h.field !== 'points'));
			setReceivedTableHeaders(receivedTableHeaders.filter((h) => h.field !== 'points'));
			setAllTableHeaders(allTableHeaders.filter((h) => h.field !== 'points'));
		}
	}, [u.uid]);

	const fetchData = async () => {
		try {
			let firms = await Firms.GetAllRaw();

			if (EXTERNAL_ROLES.includes(u.role[0])) {
				firms = firms.filter((f) => f.id === u.fid);
				setFilter({
					...filter,
					firm_id: u.fid,
				});
			}

			setFirms([
				{ label: 'Select Client', value: '' },
				...firms.map((d) => {
					return { label: d.name, value: d.id };
				}),
			]);
			let services = await Settings.GetServices();
			setServices([
				{ label: 'All', value: '' },
				...services.map((d) => {
					return { label: d.name, value: d.id };
				}),
			]);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchFirmAccounts = async () => {
		if (!filter.firm_id) return;

		let firm_accounts = await FirmAccounts.GetAllByFirm(filter.firm_id);
		setFirmAccounts(
			[
				firm_accounts.length > 1 ? { label: 'All Recip Accounts', value: '' } : false,
				...firm_accounts.map((d) => {
					return { label: d.name, value: d.id };
				}),
			].filter(Boolean),
		);
	};

	const fetchFirmData = async (params) => {
		try {
			setShowLoader(true);
			let data = await GetFirmReport(params.firm_id, params);
			data['all'] = [
				...data.sent.map((s) => {
					s['region'] = '';
					s['direction'] = 'sent';
					return s;
				}),
				...data.received.map((r) => {
					r['total_regions'] = r['transaction_type'] == POINTS_TRANSACTION_TYPE.CASE ? r['total_regions'] : '';
					r['direction'] = r['transaction_type'] == POINTS_TRANSACTION_TYPE.CASE ? 'received' : '';
					return r;
				}),
			].sort((a, b) => {
				let x = new Date(a.case_instruction_date).getTime();
				let y = new Date(b.case_instruction_date).getTime();
				if (x > y) {
					return -1;
				} else if (x < y) {
					return 1;
				} else {
					return 0;
				}
			});
			setData(data);
			setShowLoader(false);
		} catch (err) {
			console.log('err', err);
			setShowLoader(false);
		}
	};

	const filterChange = (e) => {
		let field = e.target.name;
		let val = e.target.value;

		if (['date_from', 'date_to'].includes(field) && val !== '') {
			val = isoStringToMySQLDate(e.target.value);
		}
		if (field === 'firm_id') {
			let f = firms.find((fc) => fc.value === val);
			setFirm({
				...firm,
				name: f.label,
				id: val,
			});
		}

		setFilter({
			...filter,
			[field]: val,
		});
	};

	const directionChange = (e) => {
		let field = e.target.name;
		let val = e.target.value;
		setDirection(e.target.value);
		setFilter({
			...filter,
			[field]: val,
		});
	};

	const filterSubmit = () => {
		fetchFirmData(filter);
		setFilterValue(filter);
	};

	const clearFilter = () => {
		setFilter(filterInit);
		setData(initData);
		setFilterValue(null);
	};

	const sentTableHeadersInit = [
		{ title: 'Date', field: 'case_instruction_date', type: 'date', sort: 'none' },
		{ title: 'Recip Account Name', field: 'firm_account_name', type: 'string', sort: 'none' },
		{ title: 'Case & App No.', field: 'case_number', type: 'casenum', sort: 'none' },
		{ title: 'Client Ref. No.', field: 'client_ref_number', type: 'string', sort: 'none' },
		{ title: 'Service', field: 'service', type: 'string', sort: 'none' },
		{ title: 'Total Regions', field: 'total_regions', type: 'number', sort: 'none' },
		{ title: 'Points', field: 'points', type: 'number', sort: 'none' },
		{ title: 'Direction', field: 'direction', type: 'string', sort: 'none' },
	];

	const receivedTableHeadersInit = [
		{ title: 'Date', field: 'case_instruction_date', type: 'date', sort: 'none' },
		{ title: 'Recip Account Name', field: 'firm_account_name', type: 'string', sort: 'none' },
		{ title: 'Case & App No.', field: 'case_number', type: 'casenum', sort: 'none' },
		{ title: 'Associate Ref. No.', field: 'client_ref_number', type: 'string', sort: 'none' },
		{ title: 'Service', field: 'service', type: 'string', sort: 'none' },
		{ title: 'Total Regions', field: 'total_regions', type: 'number', sort: 'none' },
		{ title: 'Points', field: 'points', type: 'number', sort: 'none' },
		{ title: 'Direction', field: 'direction', type: 'string', sort: 'none' },
	];

	const allTableHeadersInit = [
		{ title: 'Date', field: 'case_instruction_date', type: 'date', sort: 'none' },
		{ title: 'Recip Account Name', field: 'firm_account_name', type: 'string', sort: 'none' },
		{ title: 'Case & App No.', field: 'case_number', type: 'casenum', sort: 'none' },
		{ title: 'Ref. No.', field: 'ref_number', type: 'string', sort: 'none' },
		{ title: 'Service', field: 'service', type: 'string', sort: 'none' },
		{ title: 'Total Regions', field: 'total_regions', type: 'number', sort: 'none' },
		{ title: 'Points', field: 'points', type: 'number', sort: 'none' },
		{ title: 'Direction', field: 'direction', type: 'string', sort: 'none' },
	];

	const [sentTableHeaders, setSentTableHeaders] = useState(sentTableHeadersInit);
	const [receivedTableHeaders, setReceivedTableHeaders] = useState(receivedTableHeadersInit);
	const [allTableHeaders, setAllTableHeaders] = useState(allTableHeadersInit);

	const columnSort = (f, t) => {
		let dir = 'up';
		switch (direction) {
			case 'all':
				setAllTableHeaders(
					allTableHeaders.map((e) => {
						if (e.field === f) {
							e.sort = e.sort === 'up' ? 'down' : 'up';
							dir = e.sort === 'up' ? 'down' : 'up';
						}
						return e;
					}),
				);
				break;
			case 'sent':
				setSentTableHeaders(
					sentTableHeaders.map((e) => {
						if (e.field === f) {
							e.sort = e.sort === 'up' ? 'down' : 'up';
							dir = e.sort === 'up' ? 'down' : 'up';
						}
						return e;
					}),
				);
				break;
			case 'received':
				setReceivedTableHeaders(
					receivedTableHeaders.map((e) => {
						if (e.field === f) {
							e.sort = e.sort === 'up' ? 'down' : 'up';
							dir = e.sort === 'up' ? 'down' : 'up';
						}
						return e;
					}),
				);
				break;
			default:
		}

		let sorted = data[direction].sort((a, b) => {
			let x = null;
			let y = null;

			switch (t) {
				case 'date':
					x = new Date(a[f]).getTime();
					y = new Date(b[f]).getTime();
					break;
				case 'number':
					x = Number(a[f]);
					y = Number(b[f]);
					break;
				case 'string':
					x = a[f].toLowerCase();
					y = b[f].toLowerCase();
					break;
				case 'casenum':
					x = Number(a[f].replace('PN', ''));
					y = Number(b[f].replace('PN', ''));
					break;
				default:
			}

			if (dir === 'up') {
				if (x > y) {
					return -1;
				}
				if (x < y) {
					return 1;
				}
			} else if (dir === 'down') {
				if (y > x) {
					return -1;
				}
				if (y < x) {
					return 1;
				}
			}
			return 0;
		});

		setData({
			...data,
			[direction]: sorted,
		});
	};

	const printReport = async () => {
		let payload = {
			sent: [],
			received: [],
			adjustments: [],
			firmName: firm.name,
			firmID: firm.id,
		};

		switch (direction) {
			case 'all':
				payload = {
					...payload,
					sent: data.sent,
					received: data.received,
					adjustments: data.adjustments,
				};
				break;
			case 'sent':
				payload = {
					...payload,
					sent: data.sent,
					received: [],
					adjustments: data.adjustments,
				};
				break;
			case 'received':
				payload = {
					...payload,
					sent: [],
					received: data.received,
					adjustments: data.adjustments,
				};
				break;
			default:
				break;
		}

		await DownloadClientReport(payload, 'pdf');
	};

	const printSpreadsheet = async () => {
		try {
			const payload = {
				sent: data.sent,
				received: data.received,
				adjustments: data.adjustments,
				firmName: firm.name,
			};
			await DownloadClientReport(payload, 'excel');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<AppTemplate title="Member Report">
			<AppTemplate.Sidebar>
				<div className="reports-sidebar">
					<h4 className="reports-sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						filters
					</h4>
					<Select options={reports} label="Direction" theme="dark" onChange={directionChange} name="direction" value={filter.direction} />
					<Select options={firms} label="Clients" theme="dark" onChange={filterChange} name="firm_id" value={filter.firm_id} />
					<Select options={firmAccounts} label="Recip Account" theme="dark" onChange={filterChange} name="firm_account_id" value={filter.firm_account_id} />
					<Select options={services} label="Service" theme="dark" onChange={filterChange} name="service" value={filter.service} />
					<div className="reports-sidebar_hgroup">
						<Input label="Date from" theme="dark" type="date" onChange={filterChange} name="date_from" value={filter.date_from} />
						<Input label="Date to" theme="dark" type="date" onChange={filterChange} name="date_to" value={filter.date_to} />
					</div>
					<div className="reports-sidebar_actions sidebar_actions">
						<Button onClick={filterSubmit}>Generate Report</Button>
						<Button theme="ghost" onClick={clearFilter}>
							Clear
						</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<div className="main-screen-section subheader">
					<h2 className="main-screen-section__title">Report Summary</h2>
					<div className="main-screen-section__headbox">
						<h4>
							Total Displayed:&nbsp;
							{direction === 'sent' &&
								data.sent.reduce((a, b) => {
									return Number(a) + Number(b.points);
								}, 0)}
							{direction === 'received' &&
								data.received.reduce((a, b) => {
									return Number(a) + Number(b.points);
								}, 0)}
							{direction === 'all' &&
								data.all.reduce((a, b) => {
									return Number(a) + Number(b.points);
								}, 0)}
						</h4>
					</div>
					<IconButton icon={ICON_EXCEL} onClick={printSpreadsheet} />
					<IconButton icon={ICON_PDF} onClick={printReport} />
				</div>
				<div className="main-screen-section white">
					<LoaderOverlay showLoader={showLoader} />
					{direction === 'sent' && (
						<DataTable fixedHeader={true} columns={sentTableHeaders} onColumnSort={columnSort}>
							{data.sent.map((d, i) => {
								return <ExpandableRow data={d} key={i} showDirection={true} />;
							})}
						</DataTable>
					)}
					{direction === 'received' && (
						<DataTable fixedHeader={true} columns={receivedTableHeaders} onColumnSort={columnSort}>
							{data.received.map((d, i) => {
								return <ExpandableRow data={d} key={i} showDirection={true} />;
							})}
						</DataTable>
					)}
					{direction === 'all' && (
						<DataTable fixedHeader={true} columns={allTableHeaders} onColumnSort={columnSort}>
							{data.all.map((d, i) => {
								return <ExpandableRow data={d} key={i} showDirection={true} />;
							})}
						</DataTable>
					)}
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default ClientReport;

const ExpandableRow = (props) => {
	const [detailsShow, setDetailsShow] = useState(false);

	const toggleDetails = () => {
		setDetailsShow(!detailsShow);
	};

	const toggleShow = () => {
		return detailsShow && props.data.case_breakdown;
	};

	return (
		<>
			<tr onClick={toggleDetails} className="pointer" style={{ backgroundColor: toggleShow() ? 'var(--clr-lightblue)' : '' }}>
				<DataTable.DateCell>{props.data.case_instruction_date}</DataTable.DateCell>
				<DataTable.Cell>{props.data.client_firm_account_name}</DataTable.Cell>
				<DataTable.PersonCell subtext={props.data.application_number} showIco={false}>
					{props.data.case_number}
				</DataTable.PersonCell>
				<DataTable.Cell>{props.data.direction === 'sent' ? props.data.client_ref_number : props.data.associate_ref_number}</DataTable.Cell>
				<DataTable.Cell>{props.data.service}</DataTable.Cell>
				<DataTable.Cell>{props.data.total_regions}</DataTable.Cell>
				<RoleBox roles={INTERNAL_ROLES}>
					<DataTable.Cell>{props.data.points}</DataTable.Cell>
				</RoleBox>
				{props.showDirection && <DataTable.Cell>{props.data.direction}</DataTable.Cell>}
			</tr>
			{props.data.case_breakdown ?
				<tr style={{ display: toggleShow() ? 'table-row' : 'none' }}>
					<td colSpan="8" className="drawer">
						<div>
							<table cellSpacing="0" cellPadding="0" className="nested-table">
								<thead>
									<tr>
										<th>Case Number</th>
										<th>Firm</th>
										<th>Recip Account</th>
										<th>Associate</th>
										<th>Client</th>
										<RoleBox roles={INTERNAL_ROLES}>
											<th>Points</th>
										</RoleBox>
										<th>Region</th>
									</tr>
								</thead>
								<tbody>
									{props.data.case_breakdown.map((d, i) => {
										return (
											<tr key={i}>
												<td>{d.case_number}</td>
												<td>{d.associate_firm_name}</td>
												<td>{d.associate_firm_account_name}</td>
												<td>
													{d.associate_first_name} {d.associate_last_name}
												</td>
												<td>
													{d.client_first_name} {d.client_last_name}
												</td>
												<RoleBox roles={INTERNAL_ROLES}>
													<td>{d.points}</td>
												</RoleBox>
												<td>{d.region}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</td>
				</tr>
			:	null}
		</>
	);
};
