import React, { useState, useEffect, useRef } from 'react';

import AppTemplate from 'com/templates/ApplicationTemplate';
import DataTable, { Paging } from 'com/widgets/DataTable';
import Input from 'com/ui/Input';
import TagSelect from 'com/ui/TagSelect';
import Button from 'com/ui/Button';
import LoaderOverlay from 'com/ui/LoaderOverlay';
import AssociateAssignmentModal from 'com/pages/AssociateAssignment/AssociateAssignmentModal';
import Checkbox from 'com/ui/Checkbox';
import { RoleBox } from 'com/util/RoleBox';

import { CaseRegionStatus } from 'data/constants';
import { SELECTED_ASSOCIATE_STATUS, PAGE_TITLE } from 'data/constants';

import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';
import ICON_AVATAR_SOLID from 'assets/images/icons/avatar_solid.svg';

import Settings from 'services/rest/settings';
import Firms from 'services/rest/firms';
import Users from 'services/rest/users';
import Cases from 'services/rest/cases';
import AssociateSelection from 'services/rest/associate_selection';

import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';

import './style.css';

const AssociateAssignment = (props) => {
	useTitle(PAGE_TITLE.ASSOCIATE_ASSIGNMENT);

	const filterInit = {
		case_number: '',
		clients: [],
		regions: [],
		associates: [],
		deadline: '',
		orderBy: [],
		status: [CaseRegionStatus.INSTRUCTED, CaseRegionStatus.UNINSTRUCTED],
	};

	const [checkAll, setCheckAll] = useState(false);
	const tableHeadersUserInit = [
		{
			title: (
				<Checkbox
					type="checkbox"
					name="all"
					fieldClassName="cases-list__data-table__checkbox-label"
					onChange={(e) => checkApproveAssociate(e, {})}
					checked={checkAll === true ? true : false}
				/>
			),
			field: 'all',
			sort: 'none',
			sortable: false,
		},
		{ title: 'Case Number', field: 'case_number', type: 'string', sort: 'none', sortable: true },
		{ title: 'Associate Firm', field: 'associate_firm_name', type: 'string', sort: 'none', sortable: true },
		{ title: '', field: '', type: '', sort: 'none', sortable: false },
		{ title: 'Region', field: 'region_code', type: 'string', sort: 'none', sortable: true },
		{ title: 'Deadline', field: 'due_date', type: 'date', sort: 'none', sortable: true },
	];
	const tableHeadersAdminInit = [
		{ title: 'Case Number', field: 'case_number', sort: 'none', sortable: true },
		{ title: 'Associate Firm', field: 'associate_firm_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Region', field: 'region_code', type: 'string', sort: 'none', sortable: true },
		{ title: 'Client', field: 'client_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Deadline', field: 'due_date', type: 'date', sort: 'none', sortable: true },
	];

	const [filter, setFilter] = useState(filterInit);
	const [casesRegions, setCasesRegions] = useState([]);
	const [clients, setClients] = useState([{ label: '', value: '' }]);
	const [regions, setRegions] = useState([{ label: '', value: '' }]);
	const [associates, setAssociates] = useState([{ label: '', value: '' }]);
	const [tableHeadersUser, setTableHeadersUser] = useState(tableHeadersUserInit);
	const [tableHeadersAdmin, setTableHeadersAdmin] = useState(tableHeadersAdminInit);
	const [showLoader, setShowLoader] = useState(false);
	const [showAssignAssociateModal, setShowAssignAssociateModal] = useState(false);
	const [paging, setPaging] = useState({ total_rows: 0, page: 1, limit: Paging.PAGE_SIZE[0].value });
	const [pageChange, setPageChange] = useState(1);
	const [caseRegionData, setCaseRegionData] = useState({});
	const [manualSelectionReason, setManualSelectionReason] = useState('');
	const [regionsForApproval, setRegionsForApproval] = useState([]);
	const { getFilterValue, setFilterValue } = useFilterStorage();

	useEffect(() => {
		(async () => {
			await getCasesRegions();
		})();
	}, [pageChange]);

	useEffect(() => {
		(async () => {
			let f = getFilterValue();
			if (f) setFilter(f);
			await fetchData();
		})();
	}, []);

	useEffect(() => {
		// set table headers for cases regions to be available on check-all checkbox
		setTableHeadersUser(tableHeadersUserInit);
	}, [casesRegions, checkAll]);

	const fetchData = async () => {
		try {
			let [clientsData, regions, firmsData] = await Promise.all([Users.GetAllRaw(), Settings.GetRegions(), Firms.GetAllRaw()]);

			setClients((prevState) => [
				...prevState,
				...clientsData
					.map((c) => {
						return !!(c.first_name && c.last_name) ?
								{
									label: `${c.first_name} ${c.last_name}`,
									value: c.id,
								}
							:	false;
					})
					.filter(Boolean),
			]);

			setRegions((prevState) => [
				...prevState,
				...regions.map((r) => {
					return {
						label: r.name,
						value: r.id,
					};
				}),
			]);

			setAssociates((prevState) => [
				...prevState,
				...firmsData.map((f) => {
					return {
						label: f.name,
						value: f.id,
					};
				}),
			]);
		} catch (err) {
			console.log(err);
			setShowLoader(false);
		}
	};

	const getCasesRegions = async () => {
		try {
			let f = getFilterValue();
			setShowLoader(true);
			const filterData = {
				...(f ? f : filter),
				orderBy: filter.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
				page: paging.page,
				limit: paging.limit,
			};

			let cr = await Cases.GetAllCasesRegions(filterData);
			let pa = await AssociateSelection.GetAllAssociates({ status: SELECTED_ASSOCIATE_STATUS.PENDING });

			// map cases regions to pending associates
			cr.data.map((r) => {
				const a = pa.find((d) => d.case_region_id === r.id);
				r.pendingAssociate = a ? a : null;
				r.is_rush_case = isRushCase(r.start_date, r.due_date, r.days_before_due_date, r.rush_case_time);
				return r;
			});

			setCasesRegions(cr.data);
			setPaging({ ...paging, total_rows: cr.total_rows });
			setShowLoader(false);
		} catch (err) {
			console.log(err);
			setShowLoader(false);
		}
	};

	const filterChange = (e) => {
		const { name, value } = e.target;

		if (['case_number', 'deadline'].includes(name)) {
			setFilter({
				...filter,
				[name]: value,
			});
			return;
		}

		setFilter(!!filter[name].includes(value) ? filter[name].filter((f) => f !== value) : { ...filter, [name]: value });
	};

	const filterSubmit = async () => {
		setPaging({ ...paging, total_rows: 0, page: 1 });
		setPageChange(pageChange + 1);
		setFilterValue(filter);
	};

	const clearForm = () => {
		setFilter(filterInit);
		filterSubmit();
		setFilterValue(null);
	};

	const columnSortUser = async (col) => {
		setTableHeadersUser(
			tableHeadersUser.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filter.orderBy.filter((r) => r.field === col).length === 0) {
						setFilter({
							...filter,
							orderBy: [{ field: col, direction: h.sort }, ...filter.orderBy],
						});
					} else {
						setFilter({
							...filter,
							orderBy: [{ field: col, direction: h.sort }, ...filter.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
		setPageChange(pageChange + 1);
	};

	const columnSortAdmin = async (col) => {
		setTableHeadersAdmin(
			tableHeadersAdmin.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filter.orderBy.filter((r) => r.field === col).length === 0) {
						setFilter({
							...filter,
							orderBy: [{ field: col, direction: h.sort }, ...filter.orderBy],
						});
					} else {
						setFilter({
							...filter,
							orderBy: [{ field: col, direction: h.sort }, ...filter.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
		setPageChange(pageChange + 1);
	};

	const pagingChange = async (p, l) => {
		setPaging({ ...paging, page: p, limit: l });
		setPageChange(pageChange + 1);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const openAssignAssociateModal = (e) => {
		let { crid } = e.target.dataset;
		let crData = casesRegions.find((cr) => cr.id === crid);
		if (regionsForApproval.length > 0) {
			setRegionsForApproval([]);
		}

		setCaseRegionData(crData);
		setShowAssignAssociateModal(true);
	};

	const closeAssignAssociateModal = () => {
		setShowAssignAssociateModal(false);
		setManualSelectionReason('');
	};

	const approveAssociate = async (aid, caseId, regionId, assignmentOverrideRequestId, autoApproveReason) => {
		try {
			setShowLoader(true);
			const payload = {
				status: SELECTED_ASSOCIATE_STATUS.APPROVED,
				current_status: SELECTED_ASSOCIATE_STATUS.PENDING,
				case_id: caseId,
				case_region_id: regionId,
				associate_id: aid,
				assignment_override_request_id: assignmentOverrideRequestId,
				auto_approve_reason: autoApproveReason,
			};
			await Cases.setAssociateStatus(payload);
			await getCasesRegions();
			setShowLoader(false);
		} catch (err) {
			console.log(err);
			setShowLoader(false);
		}
	};

	const bulkApproveAssociates = async () => {
		setShowLoader(true);
		try {
			for (let cr of regionsForApproval) {
				const payload = {
					status: SELECTED_ASSOCIATE_STATUS.APPROVED,
					current_status: SELECTED_ASSOCIATE_STATUS.PENDING,
					case_id: cr.case_id,
					case_region_id: cr.id,
					associate_id: cr.selected_associate_id,
				};
				await Cases.setAssociateStatus(payload);
			}
			await getCasesRegions();
			if (checkAll) {
				setCheckAll(false);
			}
			setRegionsForApproval([]);
			setShowLoader(false);
		} catch (err) {
			console.log(err);
			setShowLoader(false);
		}
	};

	const handleReasonForManualSelection = (e) => {
		setManualSelectionReason(e.target.value);
	};

	const checkApproveAssociate = (e, cr = {}) => {
		if (e.target.name === 'all' && e.target.checked) {
			setRegionsForApproval([...casesRegions.filter((d) => d.pendingAssociate !== null)]);
			return setCheckAll(true);
		}
		if (e.target.name === 'all' && !e.target.checked) {
			setRegionsForApproval([]);
			return setCheckAll(false);
		}
		if (regionsForApproval.length === 1 && !e.target.checked) {
			setCheckAll(false);
		}
		if (regionsForApproval.find((r) => r.id === cr.id)) {
			return setRegionsForApproval([...regionsForApproval.filter((d) => d.id !== cr.id)]);
		}
		return setRegionsForApproval([...regionsForApproval, cr]);
	};

	const isRushCase = (startDate, dueDate, daysBeforeDueDate, rushCaseTime) => {
		let isRush = false;
		/// temp if check for all properties (because not all are imported in database for specific regions)
		if (startDate && dueDate && rushCaseTime && daysBeforeDueDate) {
			let i = new Date(startDate);
			let timeParts = rushCaseTime.split(':');
			let limit = new Date(dueDate);

			limit.setHours(Number(timeParts[0]));
			limit.setMinutes(Number(timeParts[1]));
			limit.setSeconds(Number(timeParts[2]));

			while (daysBeforeDueDate > 0) {
				// skip weekend days
				if (limit.getDay() === 0 || limit.getDay() === 6) {
					limit.setDate(limit.getDate() - 1);
					continue;
				}
				limit.setDate(limit.getDate() - 1);
				daysBeforeDueDate--;
			}
			if (i >= limit) {
				isRush = true;
			}
		}
		return isRush;
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			filterSubmit();
		}
	};

	return (
		<AppTemplate title="Associate Assignment" headerIcon="associate_assignment" countBadge={!!paging.total_rows && paging.total_rows}>
			<AppTemplate.Sidebar>
				<div className="associate-assignment-sidebar">
					<h4 className="associate-assignment-sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						Filters
					</h4>
					<Input label="Case Number" name="case_number" value={filter.case_number} onChange={filterChange} theme="dark" onKeyDown={handleKeyDown} />
					<TagSelect label="Clients" name="clients" options={clients} values={filter.clients} onChange={filterChange} theme="dark" />
					<TagSelect label="Regions" name="regions" options={regions} values={filter.regions} onChange={filterChange} theme="dark" />
					<TagSelect label="Associates" name="associates" options={associates} values={filter.associates} onChange={filterChange} theme="dark" />
					<Input label="Deadline" name="deadline" type="date" onChange={filterChange} value={filter.deadline} theme="dark" />
					<div className="associate-assignment-sidebar_actions sidebar_actions">
						<Button onClick={filterSubmit}>Filter Firms</Button>
						<Button theme="ghost" onClick={clearForm}>
							Clear
						</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<div className="main-screen-section white">
					<LoaderOverlay showLoader={showLoader} />
					<RoleBox roles={['user', 'member', 'member_assistant']}>
						<DataTable fixedHeader={true} columns={tableHeadersUser} onColumnSort={columnSortUser} customClassName="associate-assigment-table">
							{casesRegions.length > 0 &&
								casesRegions.map((cr, i) => {
									return (
										<tr key={i}>
											<DataTable.Cell>
												{cr.pendingAssociate && cr.pendingAssociate.status === SELECTED_ASSOCIATE_STATUS.PENDING && (
													<Checkbox
														onChange={(e) => {
															checkApproveAssociate(e, cr);
														}}
														fieldClassName="associate-assigment-table__checkbox"
														checked={regionsForApproval.find((d) => d.id === cr.id) ? true : false}
													/>
												)}
											</DataTable.Cell>
											<DataTable.Cell>{cr.case_number}</DataTable.Cell>
											<DataTable.PersonCell ico={ICON_AVATAR_SOLID} theme="solid">
												{cr.pendingAssociate ? cr.pendingAssociate.firm_name : ''}
											</DataTable.PersonCell>
											<DataTable.Cell>
												{cr.pendingAssociate === null ?
													<>
														<Button className="associate-assigment-table__manual-select-btn" onClick={openAssignAssociateModal} theme="ghost" data-crid={cr.id}>
															Manual Selection
														</Button>
													</>
												: cr.pendingAssociate && cr.pendingAssociate.status === SELECTED_ASSOCIATE_STATUS.PENDING ?
													<>
														<Button
															className="associate-assigment-table__approve-btn"
															onClick={(e) => {
																approveAssociate(e.target.dataset.aid, cr.case_id, cr.id, cr.pendingAssociate.assignment_override_request_id, false);
															}}
															data-aid={cr.pendingAssociate.associate_id ? cr.pendingAssociate.associate_id : null}
														>
															Approve
														</Button>
														<Button className="associate-assigment-table__manual-select-btn" onClick={openAssignAssociateModal} theme="ghost" data-crid={cr.id}>
															Manual Selection
														</Button>
													</>
												:	null}
											</DataTable.Cell>
											<DataTable.CountryCell code={cr.region_code}>{cr.region_name}</DataTable.CountryCell>
											<DataTable.DateCell>{cr.due_date}</DataTable.DateCell>
										</tr>
									);
								})}
						</DataTable>
						{regionsForApproval.length > 0 ?
							<div className="main-screen-section__approve-all-btn-wrapper">
								<Button onClick={bulkApproveAssociates}>Approve Selected</Button>
							</div>
						:	null}
					</RoleBox>
					<RoleBox roles={['admin', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales', 'sales_manager']}>
						<DataTable fixedHeader={true} columns={tableHeadersAdmin} onColumnSort={columnSortAdmin} customClassName="associate-assigment-table">
							{casesRegions.length > 0 &&
								casesRegions.map((cr, i) => {
									return (
										<tr key={i}>
											<DataTable.Cell>{cr.case_number}</DataTable.Cell>
											{cr.pendingAssociate && cr.pendingAssociate.status === SELECTED_ASSOCIATE_STATUS.PENDING ?
												<DataTable.Cell>
													<span className="associate-assigment-table__pending-approval-label">Pending Approval</span>
												</DataTable.Cell>
											:	<DataTable.Cell>
													<RoleBox roles={['admin', 'case_manager', 'case_manager_manager']}>
														<Button onClick={openAssignAssociateModal} theme="ghost" data-crid={cr.id}>
															Manual Selection
														</Button>
													</RoleBox>
												</DataTable.Cell>
											}
											<DataTable.CountryCell code={cr.region_code}>{cr.region_name}</DataTable.CountryCell>
											<DataTable.PersonCell subtext={cr.client_firm_name} ico={ICON_AVATAR_SOLID} theme="solid">
												{cr.client_name}
											</DataTable.PersonCell>
											<DataTable.DateCell>{cr.due_date}</DataTable.DateCell>
										</tr>
									);
								})}
						</DataTable>
					</RoleBox>
					<Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange} />
				</div>
				{showAssignAssociateModal && (
					<AssociateAssignmentModal
						caseRegionData={caseRegionData}
						closeHandler={closeAssignAssociateModal}
						handleReasonForManualSelection={handleReasonForManualSelection}
						manualSelectionReason={manualSelectionReason}
						getCasesRegions={getCasesRegions}
						approveAssociate={approveAssociate}
					/>
				)}
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default AssociateAssignment;
