import { object, string } from 'yup';
import { messages } from 'util/validations';

export const FirmAddSchema = object().shape({
	firm_name: string().required(messages.required.firm_name),
	email: string().email(messages.invalid.email).required(messages.required.email),
	city: string().required(messages.required.city),
	address_1: string().required(messages.required.address_1),
	address2: string(),
	state: string(),
	postal_code: string().required(messages.required.postal_code),
	firm_country: string().required(messages.required.firm_country),
});
