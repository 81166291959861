//Template Imports
import AuthBox from 'com/templates/AuthBox';
//UI Imports
import BackButton from 'com/ui/BackButton';
//Style Imports
import './style.css';

const RegisterThankyouAzami = () => {
	return (
		<div className="thankyou-container">
			<AuthBox title="Thank you for your registration" titleColor="azami-blurple" icon="create_account" footer={<BackButton to="/">Back to login</BackButton>}>
				An email with a verification link is on it's way. Please verify your account in order to proceed with using The Azami Global portal.
			</AuthBox>
		</div>
	);
};

export default RegisterThankyouAzami;
