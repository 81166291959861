import Ajax from './base';

const GetAll = () => {
	return Ajax.Request('/firms/families', Ajax.GET, true);
};

const Create = (payload) => {
	return Ajax.Request('/firms/families', Ajax.POST, false, payload);
};

const GetOneByID = (fid) => {
	return Ajax.Request(`/firms/families/${fid}`, Ajax.GET, true);
};

const UpdateByID = (fid, payload) => {
	return Ajax.Request(`/firms/families/${fid}`, Ajax.PUT, false, payload);
};

const DeleteByID = (fid) => {
	return Ajax.Request(`/firms/families/${fid}`, Ajax.DELETE, false);
};

export default {
	GetAll,
	Create,
	GetOneByID,
	UpdateByID,
	DeleteByID,
};
