import React, { useEffect } from 'react';
import { getDomain } from 'services/strings';
import { DOMAINS } from 'data/constants';

import { PAGE_TITLE } from 'data/constants';

const useTitle = (title) => {
	const domain = getDomain();

	useEffect(() => {
		const isIpeer = DOMAINS.IPEER.includes(btoa(domain));
		if (isIpeer) {
			document.title = `${PAGE_TITLE.IPEER} - ${title}`;
			return () => (document.title = PAGE_TITLE.IPEER);
		} else {
			document.title = `${PAGE_TITLE.BASE} - ${title}`;
			return () => (document.title = PAGE_TITLE.BASE);
		}
	}, [title, domain]);
};

export default useTitle;
