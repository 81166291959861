//System Imports
import PropTypes from 'prop-types';
//UI Imports
import Input from 'com/ui/Input';
import AppLink from 'com/util/AppLink';
import Button from 'com/ui/Button';
import ErrorMessage from 'com/ui/ErrorMessage';
//Constant imports
import { LOGIN_AZAMI } from 'data/constants';
//Validator imports
import { Formik, Form } from 'formik';
import { LoginSchema } from 'services/validators/login';
//Template imports
import AuthBox from 'com/templates/AuthBox';
//Local Component imports
import LoginFooterAzami from './LoginFooterAzami';

const LoginAzami = (props) => {
	return (
		<div className="login-container">
			<div className="login-container-azami__header">
				<span className="login-container-azami__header-underline">Welcome</span>&nbsp;to&nbsp;
				<span className="login-container-azami__header-platform">Azami Global</span>
			</div>
			<span className="login-container-azami__description">{LOGIN_AZAMI}</span>
			<AuthBox footer={<LoginFooterAzami />}>
				<Formik initialValues={props.initialState} validationSchema={LoginSchema} onSubmit={props.handleSubmit} validateOnChange={false}>
					{({ values, handleChange, validateField, errors, status }) => {
						const validate = (e) => validateField(e.target.name);

						return (
							<Form>
								<Input
									name="email"
									value={values.email}
									onChange={handleChange}
									onBlur={validate}
									error={errors.email}
									label="Email"
									placeholder="email@domain.com"
									type="text"
									theme="white"
								/>
								<Input
									name="password"
									value={values.password}
									onChange={handleChange}
									onBlur={validate}
									error={errors.password}
									label="Password"
									placeholder="your password"
									type="password"
									theme="white"
								/>
								<div className="login-container__actions">
									<AppLink type="regular" to="/forgot-password">
										Forgot password?
									</AppLink>
									<Button type="submit">Login</Button>
								</div>
								{status && <ErrorMessage error={status} />}
							</Form>
						);
					}}
				</Formik>
			</AuthBox>
		</div>
	);
};

LoginAzami.propTypes = {
	initialState: PropTypes.object,
	handleSubmit: PropTypes.func,
};

LoginAzami.defaultProps = {
	initialState: {},
	handleSubmit: () => {},
};

export default LoginAzami;
