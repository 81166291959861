import Ajax from './base';

const GetServices = (queryStringData) => {
	return Ajax.Request(`/settings/services`, Ajax.GET, false, queryStringData);
};

const GetTechnologies = (queryStringData) => {
	return Ajax.Request(`/settings/technologies`, Ajax.GET, false, queryStringData);
};

const GetRegions = (queryStringData) => {
	return Ajax.Request(`/settings/regions`, Ajax.GET, false, queryStringData);
};

const GetUserRoles = (queryStringData) => {
	return Ajax.Request(`/settings/roles`, Ajax.GET, false, queryStringData);
};

const GetFirmSizes = (queryStringData) => {
	return Ajax.Request(`/settings/firms-sizes`, Ajax.GET, false, queryStringData);
};

const GetAffiliateGroups = (queryStringData) => {
	return Ajax.Request(`/settings/affiliate-groups`, Ajax.GET, false, queryStringData);
};

const GetCurrencies = (queryStringData) => {
	return Ajax.Request(`/settings/currency`, Ajax.GET, false, queryStringData);
};

const GetAvailableExchangeRatesCurrencies = (queryStringData) => {
	return Ajax.Request(`/settings/exchange-rates/available`, Ajax.GET, true, queryStringData);
};

const GetExchangeRates = (queryStringData) => {
	return Ajax.Request(`/settings/exchange-rates`, Ajax.GET, true, queryStringData);
};

const CreateExchangeRates = (payload) => {
	return Ajax.Request(`/settings/exchange-rates`, Ajax.POST, true, payload);
};

const GetExchangeRatesByDate = (date) => {
	return Ajax.Request(`/settings/exchange-rates/${date}`, Ajax.GET, true);
};

const GetPointsTransactionReasons = (queryStringData) => {
	return Ajax.Request(`/settings/points-transactions-reasons`, Ajax.GET, false, queryStringData);
};

const GetRegionsGroups = () => {
	return Ajax.Request(`/settings/regions-groups`, Ajax.GET, false);
};

const CreateRegionsGroup = (payload) => {
	return Ajax.Request(`/settings/regions-groups`, Ajax.POST, true, payload);
};

const UpdateRegionsGroup = (gid, payload) => {
	return Ajax.Request(`/settings/regions-groups/${gid}`, Ajax.PUT, true, payload);
};

const DeleteRegionsGroup = (gid) => {
	return Ajax.Request(`/settings/regions-groups/${gid}`, Ajax.DELETE, true);
};

const GetPoas = (queryStringData) => {
	return Ajax.Request('/settings/poas', Ajax.GET, false, queryStringData);
};

const GetOnePoa = (pid) => {
	return Ajax.Request(`/settings/poas/${pid}`, Ajax.GET, false);
};

const CreatePoa = (payload) => {
	return Ajax.Request('/settings/poas', Ajax.POST, true, payload);
};

const UpdatePoa = (pid, payload) => {
	return Ajax.Request(`/settings/poas/${pid}`, Ajax.PUT, true, payload);
};

const DeletePoa = (pid) => {
	return Ajax.Request(`/settings/poas/${pid}`, Ajax.DELETE, true);
};

const GetTranslationRequests = (queryStringData) => {
	return Ajax.Request('/settings/translation-requests', Ajax.GET, false, queryStringData);
};

const GetOneTranslationRequest = (id) => {
	return Ajax.Request(`/settings/translation-requests/${id}`, Ajax.GET, false);
};

const CreateTranslationRequest = (payload) => {
	return Ajax.Request('/settings/translation-requests', Ajax.POST, true, payload);
};

const UpdateTraslationRequest = (id, payload) => {
	return Ajax.Request(`/settings/translation-requests/${id}`, Ajax.PUT, true, payload);
};

const DeleteTranslationRequest = (id) => {
	return Ajax.Request(`/settings/translation-requests/${id}`, Ajax.DELETE, true);
};

const GetLanguages = (queryStringData) => {
	return Ajax.Request('/settings/languages', Ajax.GET, false, queryStringData);
};

const GetOneLanguage = (id) => {
	return Ajax.Request(`/settings/languages/${id}`, Ajax.GET, false);
};

const GetSystemPoints = (payload) => {
	return Ajax.Request(`/settings/reciprocity-points`, Ajax.GET, false, payload);
};

const UpdateSystemPoints = (payload) => {
	return Ajax.Request('/settings/reciprocity-points', Ajax.PUT, true, payload);
};

const GetStatuses = (payload) => {
	return Ajax.Request('/settings/case-statuses', Ajax.GET, false, payload);
};

const GetWipo = (payload) => {
	return Ajax.Request(`/settings/get-wipo`, Ajax.GET, true, payload);
};

/**
 * RECIPROCITY FEE THRESHOLDS
 */
const GetReciprocityFeeThresholds = (payload) => {
	return Ajax.Request(`/settings/reciprocity-fee-thresholds`, Ajax.GET, true, payload);
};

const UpdateReciprocityFeeThresholds = (payload) => {
	return Ajax.Request('/settings/reciprocity-fee-thresholds', Ajax.PUT, true, payload);
};

// market rates
const GetAllMarketRatesByRegionsAndServiceID = (payload) => {
	return Ajax.Request(`/settings/market-rates`, Ajax.GET, true, payload);
};

export default {
	GetServices,
	GetTechnologies,
	GetRegions,
	GetUserRoles,
	GetFirmSizes,
	GetAffiliateGroups,
	GetCurrencies,
	GetExchangeRates,
	CreateExchangeRates,
	GetAvailableExchangeRatesCurrencies,
	GetPointsTransactionReasons,
	GetRegionsGroups,
	CreateRegionsGroup,
	UpdateRegionsGroup,
	DeleteRegionsGroup,
	GetPoas,
	GetOnePoa,
	CreatePoa,
	UpdatePoa,
	DeletePoa,
	GetTranslationRequests,
	GetOneTranslationRequest,
	CreateTranslationRequest,
	UpdateTraslationRequest,
	DeleteTranslationRequest,
	GetLanguages,
	GetSystemPoints,
	UpdateSystemPoints,
	GetStatuses,
	GetWipo,
	GetReciprocityFeeThresholds,
	UpdateReciprocityFeeThresholds,
	GetExchangeRatesByDate,
	GetAllMarketRatesByRegionsAndServiceID,
};
