import React from 'react';
import PropTypes from 'prop-types';

import { SERVICE_KEYS } from 'data/constants';
import Input from 'com/ui/Input';
import './style.css';

const PricingLevelPreview = (props) => {
	const services = props.services;
	const ruleServices = props.ruleServices;
	const fees = props.fees;
	const group = props.group;
	const pricingLevels = props.pricingLevels;
	const pricingLevel = props.pricingLevel;
	let pricingLevelFee = fees.filter((f) => f.level_id === pricingLevel.id);
	pricingLevelFee = pricingLevelFee.length > 1 ? pricingLevelFee.find((f) => f.group === group) : pricingLevelFee[0];

	const getBasePricingLevelValue = () => {
		let basePL = pricingLevels.find((pl) => pl.is_base);
		let basePLFee = fees.filter((f) => f.level_id === basePL.id);
		basePLFee = basePLFee.length > 1 ? basePLFee.find((f) => f?.group === pricingLevelFee?.group) : basePLFee[0];
		return basePLFee?.value;
	};

	const getDiscountByService = () => {
		let chosenServices = services.filter((s) => ruleServices && ruleServices.includes(s.id));
		if (chosenServices.length < 1) return 0;

		let mainService = chosenServices.length > 1 ? SERVICE_KEYS.PCT : chosenServices[0].key;
		if (mainService == SERVICE_KEYS.EP) {
			return pricingLevel.diff_from_base_ep_validation;
		} else if (mainService == SERVICE_KEYS.TRADEMARKS) {
			return pricingLevel.diff_from_base_trademarks;
		}

		return pricingLevel.diff_from_base;
	};

	const calculatePricingLevelValue = () => {
		let basePlValue = getBasePricingLevelValue();
		let discount = getDiscountByService();
		let precentage = (100 + discount) / 100;
		let calculatedValue = basePlValue * precentage;
		let numDecimalDigits = basePlValue > 50 ? 0 : 4;
		return Number(calculatedValue).toFixed(numDecimalDigits);
	};

	const getValue = () => {
		return !!pricingLevel.is_base || !!pricingLevelFee?.value ? pricingLevelFee.value : calculatePricingLevelValue();
	};

	const getClassName = () => {
		return (
			!!pricingLevel.is_base ? 'base-pricing-value'
			: !!pricingLevelFee?.value ? 'override-value'
			: 'calculated-value'
		);
	};

	return <Input type="number" name={`value__${pricingLevel.id}__${group}`} value={getValue()} onChange={props.onChange} customClassName={getClassName()} />;
};

PricingLevelPreview.propTypes = {
	onChange: PropTypes.func,
	ruleServices: PropTypes.array,
	pricingLevel: PropTypes.object,
	services: PropTypes.array,
	pricingLevels: PropTypes.array,
	fees: PropTypes.array,
	group: PropTypes.number,
};

PricingLevelPreview.defaultProps = {
	onChange: () => {},
	ruleServices: [],
	pricingLevel: {},
	services: [],
	pricingLevels: [],
	fees: [],
	group: 0,
};

export default PricingLevelPreview;
