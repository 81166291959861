import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// util
import { RoleBox } from 'com/util/RoleBox';
// widgets
import DocumentUploader from 'com/widgets/DocumentUploader';
import RatesList from 'com/widgets/RatesList';
// ui comp
import Button from 'com/ui/Button';
import Select from 'com/ui/Select';
// services
import Storage from 'services/rest/storage';
import FirmAgents from 'services/rest/firm_agents';
import useNotification from 'services/hooks/use_notification';
// data
import { INTERNAL_ROLES, DOCUMENT_OBJECT_TYPE, DOCUMENT_OBJECT_CATEGORY } from 'data/constants';

const FirmDetailsRates = (props) => {
	const user = useSelector((state) => state.auth.user);
	const sendNotification = useNotification();

	const dataInit = { region: '', client: '' };
	const uploadBoxIndicatorsInit = { message: '', noFilesSelected: false };
	const errorsInit = { region: '', client: '' };

	const [files, setFiles] = useState([]);
	const [uploadBoxIndicators, setUploadBoxIndicators] = useState(uploadBoxIndicatorsInit);
	const [regions, setRegions] = useState([]);
	const [data, setData] = useState(dataInit);
	const [clients, setClients] = useState([]);
	const [clientsRaw, setClientsRaw] = useState([]);
	const [errors, setErrors] = useState(errorsInit);
	const [showSpinner, setShowSpinner] = useState(false);

	useEffect(() => {
		setRegions([{ label: 'All', value: '' }, ...props.firmData.regions.map((r) => ({ label: r.region_name, value: r.region_id }))]);

		if (user.role.includes('saas_user')) {
			fetchClients();
		}
	}, [props.firmData.regions]);

	const fetchClients = async () => {
		try {
			let clients = await FirmAgents.GetAllByAssociateID(props.firmData.id);
			setClientsRaw(clients);
			setClients([{ label: 'Select a client', value: '' }, ...clients.map((c) => ({ label: c.associate_name, value: c.associate_id }))]);
		} catch (error) {
			console.log('error', error);
		}
	};

	const onDataChange = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
		setErrors({
			...errors,
			[e.target.name]: '',
		});
	};

	const handleUploadFile = async () => {
		setUploadBoxIndicators(uploadBoxIndicatorsInit);
		if (validate()) return;
		setShowSpinner(true);
		let clientName = clients.find((c) => c.value === data.client).label;
		try {
			let payload = {
				object_type: DOCUMENT_OBJECT_TYPE.AGENT_INVOICE,
				object_id: props.firmData.id,
				object_name: props.firmData.name,
				client_id: data.client,
				client_name: clientName,
				category: DOCUMENT_OBJECT_CATEGORY.AGENT_INVOICE_CATEGORY,
				agent_invoice_upload: true,
			};
			// adding client name as a prefix of the filename, because we need to distinguish files in the bucket by their client
			let fp = files.map((f) => {
				let modifiedFile = new File([f], `${clientName.replace(' ', '_')}__${f.name}`, { type: f.type });
				return modifiedFile;
			});
			await Storage.DocumentUpload(fp, payload);
			sendNotification({ type: 'success', title: 'Files uploaded successfully' });
		} catch (error) {
			console.log('error', error);
			sendNotification({ type: 'error', title: 'Files not uploaded' });
		} finally {
			setShowSpinner(false);
			setFiles([]);
			setData(dataInit);
		}
	};

	const validate = () => {
		let error = false;
		if (!data.client) {
			error = true;
			setErrors((prevState) => ({ ...prevState, client: 'Required field' }));
		}
		if (files.length === 0) {
			error = true;
			setUploadBoxIndicators({ message: 'Please select a file', noFilesSelected: true });
		}
		return error;
	};

	return (
		<div className="firm-details-rates">
			{/* Commented out until we implement the automation of invoice upload. Note: Do not remove this code. */}
			{/* <RoleBox roles={['saas_user']}>
				<div className="firm-details-rates__select-container">
					<Select
						name="region"
						label="Region"
						options={regions}
						value={data.region}
						onChange={onDataChange}
						customClassName="firm-details-rates__select-container__select"
						error={errors.region}
					/>
					<Select
						name="client"
						label="Client"
						options={clients}
						value={data.client}
						onChange={onDataChange}
						customClassName="firm-details-rates__select-container__select"
						error={errors.client}
					/>
				</div>
				<span className="firm-details-rates__documents-title">Upload Invoices</span>
				<DocumentUploader files={files} setFiles={setFiles} uploadBoxIndicators={uploadBoxIndicators} title="Rates Confirmation" />
				<Button className="firm-details-rates__upload-btn" onClick={handleUploadFile} showSpinner={showSpinner}>
					Upload
				</Button>
			</RoleBox> */}
			<RatesList data={props.firmData} onChange={props.onChangeRate} clients={clientsRaw} firmCurrency={props.firmData.currency_id} />
		</div>
	);
};

export default FirmDetailsRates;
