import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { OwnerContext } from 'util/ownerContext';

import './style.css';

const Checkbox = (props) => {
	const errorBorder = props.error ? 'form-input__error-border' : '';
	const userData = useSelector((state) => state.auth.user);
	const ownerData = useContext(OwnerContext);

	const checkRoles = (uData, roles) => {
		if (props.readOnly) return true;
		if (uData.role.includes('admin')) return false;
		if (props.disabled) return props.disabled;
		if (roles.includes('$user') && ownerData.userMatch) return false;
		if (roles.includes('$firm') && ownerData.firmMatch) return false;
		if (roles.includes('$clientOfFirm') && ownerData.clientOfFirmMatch) return false;
		if (roles.includes('$agentOfFirm') && ownerData.agentForFirmMatch) return false;
		if (roles.length === 0) return false;
		let uRoles = uData.role.filter((r) => {
			return roles.includes(r);
		});
		return uRoles.length === 0;
	};

	let disabled = checkRoles(userData, props.forRoles) ? 'disabled' : '';
	let themeChecked = props.themeChecked ? `theme-checked-${props.themeChecked}` : '';
	let themeUnchecked = props.themeUnchecked ? `theme-unchecked-${props.themeUnchecked}` : '';

	return (
		<label className={`form-checkbox ${props.fieldClassName} ${disabled} ${errorBorder}`}>
			<input
				className={`form-checkbox__input ${props.customClassName}`}
				disabled={disabled}
				type="checkbox"
				onChange={props.onChange}
				onClick={props.onClick}
				value={props.value}
				name={props.name}
				checked={props.checked}
				readOnly={props.readOnly}
			/>
			<span className={`form-checkbox__checkmark ${themeChecked} ${themeUnchecked}`}></span>
			<span className={`form-checkbox__label ${props.labelClassName} ${props.multilineLabel ? 'multiline' : ''} ${props.error ? 'error' : ''}`} title={props.title ? props.title : props.label}>
				{props.img && <img src={props.img} alt="" />}
				{props.label}
			</span>
			{props.error && <span className="form-input__error">{props.error}</span>}
		</label>
	);
};

Checkbox.propTypes = {
	theme: PropTypes.string,
	fieldClassName: PropTypes.string,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	name: PropTypes.string,
	checked: PropTypes.bool,
	labelClassName: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	multilineLabel: PropTypes.bool,
	customClassName: PropTypes.string,
	disabled: PropTypes.bool,
	forRoles: PropTypes.array,
	error: PropTypes.string,
	img: PropTypes.string,
	title: PropTypes.string,
	readOnly: PropTypes.bool,
};

Checkbox.defaultProps = {
	fieldClassName: '',
	themeChecked: '',
	themeUnchecked: '',
	onChange: () => {},
	onClick: () => {},
	value: '',
	name: '',
	// checked: false,
	labelClassName: '',
	label: '',
	multilineLabel: false,
	customClassName: '',
	disabled: false,
	forRoles: [],
	error: '',
	img: '',
	title: '',
	readOnly: false,
};

export default Checkbox;
