import React from 'react';

import './style.css';

const ErrorMessage = (props) => {
	return (
		<div className="error-message">
			<span className="error-message__icon">&times;</span>
			<span className="error-message__text">{props.error}</span>
		</div>
	);
};

export default ErrorMessage;
