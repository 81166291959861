import { useDispatch } from 'react-redux';
import { toastsAdd } from 'redux/ducks/notification_toasts';

const useNotification = () => {
	const dispatch = useDispatch();
	return (data) => {
		dispatch(toastsAdd(data));
	};
};

export default useNotification;
