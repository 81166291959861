import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import store from './redux/store';
import { Userpilot } from 'userpilot';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

if (
	process.env.REACT_APP_ENVIRONMENT === 'production' &&
	(window.location.hostname === 'app.azamiglobal.com' || window.location.hostname === 'v2.azamiglobal.com' || window.location.hostname === 'ipeer.ai')
) {
	LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_TOKEN);
	setupLogRocketReact(LogRocket);
}

Userpilot.initialize(process.env.REACT_APP_USERPILOT_APP_TOKEN);

Sentry.init({
	dsn: 'https://da68e0c86a974314bfa81902188b2b00@sentry.thepctnetwork.com/6',
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 1.0,
	environment: process.env.REACT_APP_ENVIRONMENT || 'dev',
});

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root'),
);
