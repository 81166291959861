import Ajax from './base';

const Create = (fid, payload) => {
	return Ajax.Request(`/firms/${fid}/accounts`, Ajax.POST, false, payload);
};

const GetOneByID = (fid, aid, payload) => {
	return Ajax.Request(`/firms/${fid}/accounts/${aid}`, Ajax.GET, true, payload);
};

const GetAllByFirm = (fid) => {
	return Ajax.Request(`/firms/${fid}/accounts`, Ajax.GET, true);
};

const UpdateByID = (fid, aid, payload) => {
	return Ajax.Request(`/firms/${fid}/accounts/${aid}`, Ajax.PUT, false, payload);
};

const DeleteByID = (fid, aid) => {
	return Ajax.Request(`/firms/${fid}/accounts/${aid}`, Ajax.DELETE, false);
};

const AddPoints = (faid, payload) => {
	return Ajax.Request(`/reciprocity/transactions/${faid}/points`, Ajax.POST, false, payload);
};

export default {
	Create,
	GetOneByID,
	GetAllByFirm,
	UpdateByID,
	DeleteByID,
	AddPoints,
};
