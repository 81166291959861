import { TRANSFORMATION_RULE_TYPES } from 'data/constants';

export const requiredFieldsMap = {
	[TRANSFORMATION_RULE_TYPES.PREFIX]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id', 'prefix'],
	},
	[TRANSFORMATION_RULE_TYPES.PREFIX_NUMBER_REMOVE_DECIMAL]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id', 'prefix'],
	},
	[TRANSFORMATION_RULE_TYPES.PREFIX_NUMBER_REMOVE_DASHNUM_AT_END]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id', 'prefix'],
	},
	[TRANSFORMATION_RULE_TYPES.REMOVE_PREFIX]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id'],
	},
	[TRANSFORMATION_RULE_TYPES.PREFIX_DIGITS_SEPARATOR_DIGITS]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id', 'digits_front', 'digits_back', 'separators', 'first_separator'],
	},
	[TRANSFORMATION_RULE_TYPES.PREFIX_SEPARATOR_DIGITS]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id', 'prefix', 'separators', 'first_separator'],
	},
	[TRANSFORMATION_RULE_TYPES.PREFIX_SEPARATOR_DIGITS_SEPARATOR_SUFFIX]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id', 'prefix', 'separators', 'first_separator', 'second_separator', 'digits', 'suffix'],
	},
	[TRANSFORMATION_RULE_TYPES.PREFIX_OR_NUMBER_ONLY]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id', 'prefix'],
	},
	[TRANSFORMATION_RULE_TYPES.DIGITS_ONLY]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id'],
	},
	[TRANSFORMATION_RULE_TYPES.DIGITS_DECIMAL]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id'],
	},
	[TRANSFORMATION_RULE_TYPES.PREFIX_DIGITS]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id', 'prefix'],
	},
	[TRANSFORMATION_RULE_TYPES.NUMBER_PLAIN]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id'],
	},
	[TRANSFORMATION_RULE_TYPES.ALERT_ONLY]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id', 'alert_message'],
	},
	[TRANSFORMATION_RULE_TYPES.COUNTRY_CODE_OR_OPTIONAL_PREFIX_FIRST]: {
		required: ['type', 'object_type', 'base_object', 'ip_type', 'region_id'],
	},
};
