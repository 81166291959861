import { useState } from 'react';

import Button from 'com/ui/Button';

import CasesRegions from 'services/rest/cases-regions';
import { toENCADateString } from 'services/dates';

import { CASES_REGIONS_STATUSES } from 'data/constants';

const StatusUpdateButton = (props) => {
	let [loader, setLoader] = useState(false);
	const updateStatus = async () => {
		try {
			let status = props.caseStatuses.find((s) => s.status === CASES_REGIONS_STATUSES.INITIAL_CONFIRMATION_RECEIVED.status);
			setLoader(true);
			await CasesRegions.Update(props.data.case_id, props.data.id, {
				...props.data,
				case_status_id_internal: status.id,
				due_date: props.data.due_date ? toENCADateString(props.data.due_date) : null,
				start_date: props.data.start_date ? toENCADateString(props.data.start_date) : null,
			});
			props.fetchData({ order_by: [{ field: 'region_name', direction: 'up' }] });
			setLoader(false);
		} catch (e) {
			setLoader(false);
			console.log(e);
		}
	};

	return (
		<Button onClick={updateStatus} disabled={props.data.mil_order >= CASES_REGIONS_STATUSES.INITIAL_CONFIRMATION_RECEIVED.order} showSpinner={loader} theme="azami-blue">
			Mark Completed
		</Button>
	);
};

export default StatusUpdateButton;
