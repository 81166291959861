import React from 'react';
import AppLink from 'com/util/AppLink';

import ICON_BACK from 'assets/images/icons/login_back_lightblue.svg';

import './style.css';

const BackButton = (props) => {
	return (
		<AppLink type="regular" className="back-button" to={props.to}>
			<img className="back-button__icon" src={props.icon ? props.icon : ICON_BACK} alt="" />
			{props.children}
		</AppLink>
	);
};

export default BackButton;
