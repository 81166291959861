//System Imports
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
//UI Imports
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import BackButton from 'com/ui/BackButton';
import ErrorMessage from 'com/ui/ErrorMessage';
//Template Imports
import AuthBox from 'com/templates/AuthBox';
//Service Imports
import { ForgotPasswordSchema } from 'services/validators/forgotPassword';
//Style Imports
import './style.css';

const ForgotPasswordAzami = (props) => {
	return (
		<div className="forgot-password-container">
			<AuthBox title="Forgot Password" titleColor="azami-blurple" icon="password" footer={<BackButton to="/">Back to login</BackButton>}>
				<Formik initialValues={props.initialState} validationSchema={ForgotPasswordSchema} onSubmit={props.forgotPasswordSubmit} validateOnChange={false}>
					{({ values, handleChange, validateField, errors, status }) => {
						const validate = (e) => validateField(e.target.name);

						return (
							<Form>
								<span className="forgot-password-container-azami__text">Please enter your email, and we will send a password reset link.</span>
								<Input name="email" value={values.email} onChange={handleChange} onBlur={validate} error={errors.email} label="Email" placeholder="email@domain.com" type="text" />
								<div className="forgot-password-container-azami__actions">
									<Button type="submit" showSpinner={props.spinner}>
										Submit
									</Button>
								</div>
								{status && <ErrorMessage error={status} />}
							</Form>
						);
					}}
				</Formik>
			</AuthBox>
		</div>
	);
};

ForgotPasswordAzami.propTypes = {
	initialState: PropTypes.object,
	forgotPasswordSubmit: PropTypes.func,
};

ForgotPasswordAzami.defaultProps = {
	initialState: {},
	forgotPasswordSubmit: () => {},
};

export default ForgotPasswordAzami;
