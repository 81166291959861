import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Loader = (props) => {
	return (
		<div className={`spinner ${props.size} ${props.theme} ${props.customClassName}`}>
			<div className="bounce1"></div>
			<div className="bounce2"></div>
			<div className="bounce3"></div>
		</div>
	);
};

Loader.propTypes = {
	theme: PropTypes.oneOf(['light', 'darkblue', 'purple']),
	size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
	customClassName: PropTypes.string,
};

Loader.defaultProps = {
	theme: 'light',
	size: 'small',
	customClassName: '',
};

export default Loader;
