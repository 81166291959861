import Ajax from './base';

const Create = (data) => {
	return Ajax.Request('/emails', Ajax.POST, true, data);
};

const Update = (id, data) => {
	return Ajax.Request(`/emails/${id}`, Ajax.PUT, true, data);
};

const Restore = (data) => {
	return Ajax.Request(`/emails/restore`, Ajax.POST, true, data);
};

const Delete = (id) => {
	return Ajax.Request(`/emails/${id}`, Ajax.DELETE, true);
};

const Send = (data) => {
	return Ajax.Request(`/emails/send`, Ajax.POST, true, data);
};

export default {
	Create,
	Update,
	Restore,
	Delete,
	Send,
};
