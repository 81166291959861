import React from 'react';

import './style.css';

import ICON_LOGIN from 'assets/images/icons/user_login_white.svg';
import ICON_PASSWORD from 'assets/images/icons/user_login_white.svg';
import ICON_CREATE_ACCOUNT from 'assets/images/icons/create_account_white.svg';

const AuthBox = (props) => {
	let icon = '';

	switch (props.icon) {
		case 'login':
			icon = ICON_LOGIN;
			break;
		case 'password':
			icon = ICON_PASSWORD;
			break;
		case 'create_account':
			icon = ICON_CREATE_ACCOUNT;
			break;
		default:
	}

	return (
		<div className="auth-box">
			<div className="auth-box__header">
				<span className={`auth-box__header__title ${props.titleColor}`}>{props.title}</span>
			</div>
			<div className="auth-box__content">{props.children}</div>
			{props.footer}
		</div>
	);
};

export default AuthBox;
