import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BannerContainer from 'com/widgets/BannerContainer';

import Banners from 'services/rest/banner';
import { setBanners, hideBanner } from 'redux/ducks/banner';
import Cookies from 'services/cookies';

import ICON_INFO from 'assets/images/icons/ico_info.svg';
import ICON_CLOSE from 'assets/images/icons/ico_close.svg';

import './style.css';

const Banner = (props) => {
	const bannersList = useSelector((s) => s.banners);
	const dispatch = useDispatch();
	const [bannersForClose, setBannersForClose] = useState([]);
	const [bannerMaxDate, setBannerMaxDate] = useState({});

	const onClose = (id) => {
		setBannersForClose((prevState) => [...prevState, id]);
	};

	const onCancel = (id) => {
		setBannersForClose((prevState) => [...prevState.filter((bid) => bid !== id)]);
	};

	const onConfirmClose = (id, showBannerAgain = true) => {
		let maxBannerTo = new Date(bannerMaxDate.date_to);

		switch (showBannerAgain) {
			case true:
				let cookie = Cookies.getByName('closedBanners');

				// create new cookie arr
				if (!cookie) {
					let closedBanners = [id];
					document.cookie = `closedBanners=${JSON.stringify(closedBanners)};expires=${maxBannerTo};path=/`;
					return dispatch(hideBanner(id));
				}
				let closedBanners = JSON.parse(cookie);
				document.cookie = `closedBanners=${JSON.stringify([...closedBanners.filter((bid) => bid !== id), id])};expires=${maxBannerTo};path=/`;
				dispatch(hideBanner(id));
				break;

			case false:
				let c = Cookies.getByName('blackListedBanners');

				// create new blacklisted arr
				if (!c) {
					let blackListedBanners = [id];
					document.cookie = `blackListedBanners=${JSON.stringify(blackListedBanners)};expires=${maxBannerTo};path=/`;
					return dispatch(hideBanner(id));
				}
				let blackListedBanners = JSON.parse(c);
				document.cookie = `blackListedBanners=${JSON.stringify([...blackListedBanners.filter((bid) => bid !== id), id])};expires=${maxBannerTo};path=/`;
				dispatch(hideBanner(id));
				break;

			default:
				break;
		}

		// clear banner id from bannersForClose State
		onCancel(id);
	};

	const fetchData = async () => {
		try {
			let res = await Banners.GetAll({ order_by: 'date_from:desc' });
			let blackListedBanners = Cookies.getByName('blackListedBanners');
			let closedBanners = Cookies.getByName('closedBanners');

			//filter active banners
			res = res.filter((b) => {
				let currentDate = new Date();
				if (new Date(b.date_from) <= currentDate && new Date(b.date_to) >= currentDate) {
					return b;
				}
			});

			// filter blacklisted banners
			if (blackListedBanners) {
				let blackListedParsed = JSON.parse(blackListedBanners);
				res = res.filter((b) => !blackListedParsed.includes(b.id));
			}

			//filter closed banners
			if (closedBanners) {
				let closedParsed = JSON.parse(closedBanners);
				res = res.filter((b) => !closedParsed.includes(b.id));
			}

			//get banner with max date
			let maxDate = res.sort((a, b) => new Date(b.date_to) - new Date(a.date_to))[0];
			setBannerMaxDate(maxDate);

			dispatch(setBanners(res));
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		// <BannerContainer>
		bannersList.length > 0 &&
		bannersList.map((b) => {
			return (
				<div className="banner-container__item" key={b.id}>
					<div className="banner-container__item-info">
						<img src={ICON_INFO} alt="" className="banner-container__item-info__info-icon" />
						<div dangerouslySetInnerHTML={{ __html: b.text }} className="banner-container__item-info__content" />
					</div>
					{bannersForClose.includes(b.id) ?
						<div className="banner-container__item-actions">
							<span>Would you like to see this banner next time when you log in?</span>
							<button onClick={() => onConfirmClose(b.id, true)}>Yes</button>
							<button onClick={() => onConfirmClose(b.id, false)}>No</button>
							<button className="banner-container__item__cancel-btn" onClick={() => onCancel(b.id)}>
								Cancel
							</button>
						</div>
					:	<button className="banner-container__item__close-btn" onClick={() => onClose(b.id)}>
							<img src={ICON_CLOSE} alt="" />
						</button>
					}
				</div>
			);
		})
		// </BannerContainer>
	);
};

export default Banner;
