//vendor imports
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
//services
import PricingLevel from 'services/rest/pricing_levels';
import useNotification from 'services/hooks/use_notification';
//ui components
import Button from 'com/ui/Button';
import Input from 'com/ui/Input';
import Textarea from 'com/ui/Textarea';
//styles
import './style.css';

const PricingLevelForm = (props) => {
	const errorMessages = {
		required: 'Required',
		negative: 'Enter a positive value',
	};

	const initInputPricingLevel = {
		name: '',
		points_credited: 0,
		diff_from_base: 0,
		diff_from_base_ep_validation: 0,
		diff_from_base_trademarks: 0,
		description: '',
	};

	const [inputPricingLevel, setInputPricingLevel] = useState(initInputPricingLevel);
	const [errors, setErrors] = useState({});
	const history = useHistory();
	const { id } = useParams();
	const isCreateMode = !id;
	const sendNotification = useNotification();

	useEffect(async () => {
		if (!isCreateMode) {
			let p = await PricingLevel.GetOneByID(id);
			setInputPricingLevel(p);
		}
	}, [props]);

	const addFormChanged = (e) => {
		const { name, value, checked } = e.target;

		setInputPricingLevel({
			...inputPricingLevel,
			[name]: value,
		});
	};

	const validate = () => {
		let isValid = true;
		setErrors({});

		if (!inputPricingLevel.name) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, name: errorMessages.required }));
		}

		return isValid;
	};

	const submitForm = () => {
		return isCreateMode ? applyNewPricingLevel() : applyEditPricingLevel();
	};

	const applyNewPricingLevel = async () => {
		if (!validate()) {
			sendNotification({ type: 'error', title: 'Unsuccessfully adding of pricing level' });
			return false;
		}
		let p = formatPayload(inputPricingLevel);
		let res = await props.onChange(p, 'add');
		if (!res) {
			return sendNotification({ type: 'error', title: 'Unsuccessfully adding of pricing level' });
		}
		sendNotification({ type: 'success', title: 'Successfully added new pricing level' });
		history.push('/system-settings/pricing-levels');
	};

	const applyEditPricingLevel = async () => {
		if (!validate()) {
			sendNotification({ type: 'error', title: 'Unsuccessfully editing of pricing level' });
			return false;
		}
		let p = formatPayload(inputPricingLevel);
		let res = await props.onChange(p, 'edit');
		if (!res) {
			return sendNotification({ type: 'error', title: 'Unsuccessfully adding of pricing level' });
		}
		sendNotification({ type: 'success', title: 'Successfully edited pricing level' });
		history.push('/system-settings/pricing-levels');
	};

	const cancelForm = () => {
		history.push('/system-settings/pricing-levels');
	};

	const formatPayload = () => {
		//// add zero if input value is ''
		let payload = { ...inputPricingLevel };
		let numFields = ['points_credited', 'diff_from_base', 'diff_from_base_ep_validation', 'diff_from_base_trademarks'];
		for (let p in payload) {
			if (numFields.includes(p) && payload[p] === '') {
				payload[p] = 0;
			}
		}

		return payload;
	};

	return (
		<div className="main-screen-section white full-height no-padding system-details-content">
			<div className="system-settings-pricing-levels-main white">
				<div className="system-settings-pricing-levels__header">
					<span className="system-settings-pricing-levels__header__title">{isCreateMode ? 'New Pricing Level' : 'Edit Pricing Level'}</span>
				</div>
				<div className="system-settings-pricing-levels__body system-settings-table-wrapper">
					<form>
						<Input type="text" name="name" value={inputPricingLevel.name} onChange={addFormChanged} error={errors.name} label="Name" />
						<Input type="number" name="points_credited" value={inputPricingLevel.points_credited} onChange={addFormChanged} error={errors.value} label="Points Credited" />
						<Input
							type="number"
							name="diff_from_base"
							value={inputPricingLevel.diff_from_base}
							onChange={addFormChanged}
							error={errors.diff_from_base}
							label="Price Diff From Base - PCT/Paris/Etc."
						/>
						<Input
							type="number"
							name="diff_from_base_ep_validation"
							value={inputPricingLevel.diff_from_base_ep_validation}
							onChange={addFormChanged}
							error={errors.diff_from_base_ep_validation}
							label="Price Diff From Base - European Validation"
						/>
						<Input
							type="number"
							name="diff_from_base_trademarks"
							value={inputPricingLevel.diff_from_base_trademarks}
							onChange={addFormChanged}
							error={errors.diff_from_base_trademarks}
							label="Price Diff From Base - Trademarks"
						/>
						<Textarea type="text" name="description" value={inputPricingLevel.description} onChange={addFormChanged} error={errors.description} label="Description" />
					</form>
				</div>
			</div>
			<footer className="system-details-content__footer">
				<Button onClick={cancelForm} className="ghost">
					Cancel
				</Button>
				<Button onClick={submitForm}>Apply</Button>
			</footer>
		</div>
	);
};

export default PricingLevelForm;
