import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Paging from './paging';
import { RoleBox } from 'com/util/RoleBox';
import Checkbox from 'com/ui/Checkbox';
import { STATUS_COLORS } from 'data/constants';

import AppDate from 'com/util/AppDate';

import Storage from 'services/rest/storage';
import { random } from 'services/strings';

import './style.css';

import { getFlag } from 'data/flags';
import ICON_SORT_UP from 'assets/images/icons/ico_sort_up.svg';
import ICON_SORT_DOWN from 'assets/images/icons/ico_sort_down.svg';
import ICON_CALENDAR from 'assets/images/icons/ico_calendar.svg';
import ICON_AVATAR from 'assets/images/icons/avatar_placeholder_invert.svg';
import ICON_ACTIONS_MENU from 'assets/images/icons/ico_actions_menu.svg';
import ICON_ACCOUNT from 'assets/images/icons/ico_account.svg';
import ICON_ACCOUNT_DEFAULT from 'assets/images/icons/ico_account_default.svg';
import ICON_ARROW_UP from 'assets/images/icons/ico_arrow_up.svg';
import ICON_ARROW_DOWN from 'assets/images/icons/ico_arrow_down.svg';
import ICON_DATE from 'assets/images/icons/ico_date.svg';
import ICON_CLOCK from 'assets/images/icons/ico_clock.svg';
import ICON_OVERRIDE_FEE from 'assets/images/icons/ico_override_fee.svg';

import { toLocaleDateString } from 'services/dates';

const COLOR = {
	RED: 'red',
	YELLOW: 'yellow',
	GREEN: 'green',
};

const DataTable = (props) => {
	const fixedHeader = props.fixedHeader ? 'data-table__fixed-header' : '';

	return (
		<table cellPadding="0" cellSpacing="0" className={`data-table ${props.customClassName}`}>
			<thead>
				<tr className={fixedHeader}>
					{props.columns.map((c, i) => (
						<HeaderCell title={c.title} field={c.field} onClick={props.onColumnSort} type={c.type} sort={c.sort} sortable={c.sortable} key={i} />
					))}
				</tr>
			</thead>
			<tbody>{props.children}</tbody>
		</table>
	);
};

DataTable.propTypes = {
	fixedHeader: PropTypes.bool,
	customClassName: PropTypes.string,
	columns: PropTypes.array,
	onColumnSort: PropTypes.func,
};

DataTable.defaultProps = {
	fixedHeader: false,
	customClassName: '',
	columns: [],
	onColumnSort: () => {},
};

const Row = (props) => {
	return <tr className={`${props.color ? `table-row-background-${props.color}` : ''}`}>{props.children}</tr>;
};

const HeaderCell = (props) => {
	return (
		<th
			{...(props.sortable && {
				onClick: () => {
					props.onClick(props.field, props.type);
				},
			})}
		>
			<div className="data-table__header-cell">
				{props.sortable && <Sorter dir={props.sort} />}
				{props.title}
			</div>
		</th>
	);
};

HeaderCell.defaultProps = {
	sortable: true,
	sort: 'none',
};

const Sorter = (props) => {
	return (
		<div className="sorter-flags">
			<img src={ICON_SORT_UP} className={props.dir === 'down' ? 'inactive' : ''} alt="" />
			<img src={ICON_SORT_DOWN} className={props.dir === 'up' ? 'inactive' : ''} alt="" />
		</div>
	);
};

const DateCell = (props) => {
	let date = props.children;
	return (
		<td>
			<div className="table-cell table-cell-date">
				{date && props.showIco ?
					<img src={ICON_CALENDAR} className="table-cell-date__icon" alt="" />
				:	null}
				<AppDate>{date}</AppDate>
			</div>
		</td>
	);
};

DateCell.propTypes = {
	date: PropTypes.string,
	showIco: PropTypes.bool,
};

DateCell.defaultProps = {
	date: '',
	showIco: true,
};

const EditedByCell = (props) => {
	let date = props.children ? toLocaleDateString(props.children) : 'N/A';
	return (
		<td>
			<div className="table-cell table-cell-edited-by">
				<div className="table-cell table-cell-edited-by__user">{props.user ? props.user : ''}</div>
				<div className="table-cell table-cell-edited-by__date">{props.children}</div>
			</div>
		</td>
	);
};

const DateRangeCell = (props) => {
	let dateFrom = props.start ? toLocaleDateString(props.start) : null;
	let dateTo = props.end ? toLocaleDateString(props.end) : null;

	let title = '';
	let range = '';
	if (dateFrom && dateTo && dateFrom !== dateTo) {
		title = 'Between';
		range = `${dateFrom} \u2013 ${dateTo}`;
	} else if (dateFrom && dateTo && dateFrom === dateTo) {
		title = 'On Date';
		range = `${dateFrom}`;
	} else if (dateFrom) {
		title = 'Starting From';
		range = `${dateFrom}`;
	} else if (dateTo) {
		title = 'Until Date';
		range = `${dateTo}`;
	}

	return (
		<td>
			<div className="table-cell table-cell-date-range">
				<span className="table-cell-date-range__title">{title}</span>
				<span className="table-cell-date-range__content">{range}</span>
			</div>
		</td>
	);
};
DateRangeCell.propTypes = {
	dateFrom: PropTypes.string,
	dateTo: PropTypes.string,
};
DateRangeCell.defaultProps = {
	dateFrom: '',
	dateTo: '',
};

const Cell = (props) => {
	return (
		<td colspan={props.colspan} rowspan={props.rowspan}>
			<div className={`table-cell color-${props.textColor} ${props.customClassName}`} title={props.title}>
				{props.children}
			</div>
		</td>
	);
};
Cell.propTypes = {
	customClassName: PropTypes.string,
	textColor: PropTypes.string,
	title: PropTypes.string,
	colspan: PropTypes.number,
	rowspan: PropTypes.number,
};
Cell.defaultProps = {
	customClassName: '',
	textColor: 'darkgrey',
	title: '',
	colspan: 1,
	rowspan: 1,
};

const OverridePriceCell = (props) => {
	return (
		<td>
			<div className={`table-cell color-${props.textColor} ${props.customClassName} ${props.overrideIcon ? 'override-price-cell-icon-container' : ''}`}>
				{props.children}
				{props.overrideIcon ?
					<span className="override-price-cell-icon">
						<img src={ICON_OVERRIDE_FEE} alt="" />
					</span>
				:	null}
			</div>
		</td>
	);
};

OverridePriceCell.propTypes = {
	customClassName: PropTypes.string,
	textColor: PropTypes.string,
	overrideIcon: PropTypes.bool,
};

OverridePriceCell.defaultProps = {
	customClassName: '',
	textColor: 'darkgrey',
	overrideIcon: false,
};

const RuleRegionCell = (props) => {
	return (
		<td>
			<div className={`table-cell color-${props.textColor} ${props.customClassName} table-cell-rule-region`} title={props.cellTitle ? props.cellTitle : ''}>
				<div className="table-cell-rule-region__flag-container">
					<div className="table-cell-rule-region__flag-img-container">
						<img src={props.countryCode && getFlag(props.countryCode)} className="table-cell-rule-region__flag" />
					</div>
				</div>
				{props.children}
				{props.countryCount >= 1 ?
					<span className="table-cell-rule-region__counter">+{props.countryCount}</span>
				:	null}
			</div>
		</td>
	);
};

RuleRegionCell.propTypes = {
	customClassName: PropTypes.string,
	textColor: PropTypes.string,
	countryCode: PropTypes.string,
	countryCount: PropTypes.number,
	cellTitle: PropTypes.string,
};

RuleRegionCell.defaultProps = {
	customClassName: '',
	textColor: 'darkgrey',
	countryCode: 'un',
	countryCount: 0,
	cellTitle: '',
};

const TagsCell = (props) => {
	return (
		<td>
			<div className="table-cell table-cell-tags">
				{props.children.map((t, i) => (
					<span key={i}>{t}</span>
				))}
			</div>
		</td>
	);
};

const AdvancedTagsCell = (props) => {
	return (
		<td>
			<div className={`table-cell table-cell-advanced-tags`}>
				{props.children.map((tag, i) => {
					let theme = tag.use_dark_theme ? 'dark' : 'light';
					return (
						<span key={i} className={theme}>
							{tag.name}
						</span>
					);
				})}
			</div>
		</td>
	);
};

AdvancedTagsCell.propTypes = {
	use_dark_theme: PropTypes.bool,
};

AdvancedTagsCell.defaultProps = {
	use_dark_theme: false,
};

const PersonCell = (props) => {
	let theme = props.theme || 'default';

	const [avatarUrl, setAvatarUrl] = useState(ICON_AVATAR);

	useEffect(() => {
		GetImageUrl(props.ico);
	}, [props.ico]);

	const GetImageUrl = (image) => {
		if (image) {
			let string = random(20);
			setAvatarUrl(`${process.env.REACT_APP_PUBLIC_ASSETS_URL}/${image}?${string}`);
		}
	};

	return (
		<td>
			<div className={`table-cell table-cell-person ${props.customClassName}`}>
				{props.showIco && (
					<picture className={`table-cell-person__avatar-${theme}`}>
						<source srcSet="" />
						<img src={avatarUrl} alt="" />
					</picture>
				)}
				<div className="table-cell-person__info">
					<span className="table-cell-person__info__full-name">{props.children}</span>
					<span className="table-cell-person__info__subtext">{props.subtext}</span>
				</div>
			</div>
		</td>
	);
};

PersonCell.propTypes = {
	showIco: PropTypes.bool,
	ico: PropTypes.string,
	theme: PropTypes.string,
	subtext: PropTypes.string,
};

PersonCell.defaultProps = {
	showIco: true,
	ico: '',
	theme: '',
	subtext: '',
};

const MarkerCell = (props) => {
	return (
		<td>
			<div className="table-cell">
				<span className="table-cell__marker">{props.children ? props.children : 'N/A'}</span>
			</div>
		</td>
	);
};

const NullableCell = (props) => {
	return (
		<td>
			<div className="table-cell">
				<span className="table-cell">{props.children || 'N/A'}</span>
			</div>
		</td>
	);
};

const BoolCell = (props) => {
	return (
		<td>
			<div className="table-cell">{!!props.children ? 'Yes' : 'No'}</div>
		</td>
	);
};

const StatusCell = (props) => {
	const date = props.date ? toLocaleDateString(props.date) : '';

	return (
		<td>
			<div className={`table-cell table-cell-status ${props.customClassName}`}>
				{props.status && <img src={props.status.ico} title={props.status.title} />}
				<div className="table-cell-status__info">
					<div className={`table-cell-status__info__title ${props.customClassName}`}>{props.children}</div>
					{date && <div className="table-cell-status__info__date">{date}</div>}
				</div>
			</div>
		</td>
	);
};
StatusCell.propTypes = {
	date: PropTypes.string,
	status: PropTypes.object,
};
StatusCell.defaultProps = {
	date: '',
	status: {},
};

const CountryCell = (props) => {
	return (
		<td colspan={props.colspan} rowspan={props.rowspan}>
			<div className={`table-cell table-cell-country ${props.customClassName}`}>
				<img src={props.code && getFlag(props.code)} className="table-cell-country__flag" />
				{props.children}
			</div>
		</td>
	);
};
CountryCell.defaultProps = {
	code: 'un',
	children: 'Unknown',
	customClassName: '',
	colspan: 1,
	rowspan: 1,
};

CountryCell.propTypes = {
	code: PropTypes.string,
	children: PropTypes.string,
	customClassName: PropTypes.string,
	colspan: PropTypes.number,
	rowspan: PropTypes.number,
};

const PointsCell = (props) => {
	const [points, setPoints] = useState(0);
	const [color, setColor] = useState('green');

	useEffect(() => {
		let p = Number(props.children);
		if (p > 0) {
			setPoints(`+${p}`);
			setColor('green');
		} else {
			setPoints(`${p}`);
			setColor('red');
		}
	}, [props.children]);

	return (
		<td>
			<div className="table-cell">
				<span className={`${props.adjusted && 'table-cell__points-adjusted'} ${props.isAdjustmentRow && 'table-cell__points-text-italic'} table-cell__points-color-${color}`}>{points}</span>
			</div>
		</td>
	);
};
PointsCell.defaultProps = {
	adjusted: false,
	isAdjustmentRow: false,
};

const ContentWithActionCell = (props) => {
	return (
		<td>
			<div className="table-cell table-content-with-action-cell">{props.children}</div>
		</td>
	);
};

const ActionsCell = (props) => {
	return (
		<td>
			<div className="table-cell table-cell-actions">{props.children}</div>
		</td>
	);
};

const RatesCell = (props) => {
	return (
		<td>
			<div className="table-cell table-cell-rates">
				{props.children ?
					<ul className="table-cell-rates__list">
						<li title="Professional Fee" className="table-cell-rates__list-item">
							<span className="table-cell-rates__list-item__type">pf</span>
							<span className="table-cell-rates__list-item__rate">{props.children.rate_professional}</span>
							<span className="table-cell-rates__list-item__currency">{props.children.currency}</span>
						</li>
						<li title="Translation Fee" className="table-cell-rates__list-item">
							<span className="table-cell-rates__list-item__type">tf</span>
							<span className="table-cell-rates__list-item__rate">{props.children.rate_translation}</span>
							<span className="table-cell-rates__list-item__currency">{props.children.currency}</span>
						</li>
					</ul>
				:	<span className="table-cell-rates__info" title={props.emptyMessage}>
						i
					</span>
				}
			</div>
		</td>
	);
};

RatesCell.defaultProps = {
	emptyMessage: '',
};

const PriorityCell = (props) => {
	return (
		<td>
			<div className="table-cell table-cell-priority">
				<span className={`table-cell-priority-item ${props.customClassName}`}>{props.children}</span>
			</div>
		</td>
	);
};

PriorityCell.propTypes = {
	customClassName: PropTypes.string,
};

PriorityCell.defaultProps = {
	customClassName: '',
};

const ListGroupCell = (props) => {
	return (
		<td>
			<div className="table-cell table-cell-list-group">
				<ul className="table-cell-list-group__list">
					{props.list.map((c, i) => (
						<li className={`table-cell-list-group__list-item ${c.customClassName}`} key={i} title={c.label}>
							{typeof c.value === 'number' ? c.value : '-'}
						</li>
					))}
				</ul>
			</div>
		</td>
	);
};

ListGroupCell.propTypes = {
	list: PropTypes.array,
};

ListGroupCell.defaultProps = {
	list: [],
};

const ActionsMenuCell = (props) => {
	return (
		<td>
			<div className={`table-cell table-cell-actions-menu ${props.customClassName}`} onClick={props.onClick}>
				<img className="table-cell-actions-menu__toggle" src={ICON_ACTIONS_MENU} alt="" />
				<ul className="table-cell-actions-menu__list">
					{props.actions &&
						props.actions.map((item, key) => {
							return (
								<RoleBox key={key} roles={item.roles}>
									<li key={key} className={`table-cell-actions-menu__list-item ${item.disabled ? 'disabled' : ''}`} data-id={item.dataset.id || ''}>
										<button onClick={item.action} title={item.title}>
											{item.ico && <img src={item.ico} />}
											{item.label}
										</button>
									</li>
								</RoleBox>
							);
						})}
				</ul>
			</div>
		</td>
	);
};

ActionsMenuCell.propTypes = {
	list: PropTypes.array,
};

ActionsMenuCell.defaultProps = {
	list: [],
};

const AccountCell = (props) => {
	let ico = props.isDefault ? ICON_ACCOUNT_DEFAULT : ICON_ACCOUNT;

	return (
		<td>
			<div className="table-cell table-cell-account">
				<picture className={`table-cell-account__icon`}>
					<source srcSet="" />
					<img src={ico} alt="" />
				</picture>
				<div className="table-cell-account__info">
					<span className="table-cell-account__info__name">{props.children}</span>
					<span className="table-cell-account__info__subtext">{props.subtext}</span>
				</div>
			</div>
		</td>
	);
};

AccountCell.propTypes = {
	isDefault: PropTypes.bool,
	subtext: PropTypes.string,
};

AccountCell.defaultProps = {
	isDefault: false,
	subtext: '',
};

const StatusColorCell = (props) => {
	return (
		<td>
			{props.tooltip ?
				<div className={`table-cell-status-color__tooltip table-cell-status-color__indicator ${props.customClassName}`} style={{ backgroundColor: STATUS_COLORS[props.status] }}>
					<span className="table-cell-status-color__tooltip-text">{props.name}</span>
				</div>
			:	<div className="table-cell table-cell-status-color__no-tooltip">
					<div className="table-cell-status-color__indicator" style={{ backgroundColor: STATUS_COLORS[props.status] }}></div>
					<div>
						<span style={{ color: STATUS_COLORS[props.status] }}>{props.name}</span>
					</div>
				</div>
			}
		</td>
	);
};

StatusColorCell.propTypes = {
	status: PropTypes.string,
	statusName: PropTypes.string,
	tooltip: PropTypes.bool,
	customClassName: PropTypes.string,
};

StatusColorCell.defaultProps = {
	status: '',
	statusName: '',
	tooltip: false,
	customClassName: '',
};

const RatiosCell = (props) => {
	return (
		<td>
			<div className="table-cell table-cell-ratios">
				{props.children.map((p, key) => (
					<div key={key} className="table-cell-ratios__item">
						<span className="table-cell-ratios__item__service">{p.service_name}</span>
						<div className="table-cell-ratios__item__points">
							<img src={p.type === 'base' ? ICON_ARROW_DOWN : ICON_ARROW_UP} />
							<span className={`${p.overriden ? 'table-cell-ratios__item__points-overriden' : ''} table-cell__points-color-${p.type === 'base' ? 'red' : 'green'}`}>{p.points}</span>
						</div>
					</div>
				))}
			</div>
		</td>
	);
};

const DateTimeCell = (props) => {
	let d = new Date(props.date);
	let dl = d.toLocaleDateString();
	let hours = String(d.getHours()).padStart(2, 0);
	let minutes = String(d.getMinutes()).padStart(2, 0);

	return (
		<td>
			<div className={`table-cell ${props.customClassName ? props.customClassName : ''}`}>
				<img src={ICON_DATE} alt="" />
				<span>{`${dl}`}</span>
				<img src={ICON_CLOCK} alt="" />
				<span>{`${hours}:${minutes}`}</span>
			</div>
		</td>
	);
};

DateTimeCell.propTypes = {
	customClassName: PropTypes.string,
	date: PropTypes.string,
};

DateTimeCell.defaultProps = {
	customClassName: '',
	date: '',
};
const LoaderCell = (props) => {
	let rows = [];
	for (let i = 0; i < props.rows; i++) {
		let cols = [];
		for (let y = 0; y < props.cols; y++) {
			cols = [
				...cols,
				<td key={Math.random(4)}>
					<span className="table-cell-loader__animated-fake-content"></span>
				</td>,
			];
		}
		rows = [...rows, <tr key={i}>{cols}</tr>];
	}
	return rows;
};

LoaderCell.defaultProps = {
	rows: 0,
	cols: 0,
};

LoaderCell.propTypes = {
	rows: PropTypes.number,
	cols: PropTypes.number,
};

const CellWithBadge = (props) => {
	return (
		<td>
			<div className={`cell-with-badge table-cell-with-badge table-cell ${props.customClassName ? props.customClassName : ''}`}>
				{props.badge && <img src={props.badge} />}
				<div className="children">{props.children}</div>
			</div>
		</td>
	);
};

CellWithBadge.propTypes = {
	badge: PropTypes.string,
	customClassName: PropTypes.string,
};

CellWithBadge.defaultProps = {
	badge: '',
	customClassName: '',
};

DataTable.Row = Row;
DataTable.DateCell = DateCell;
DataTable.DateRangeCell = DateRangeCell;
DataTable.Cell = Cell;
DataTable.TagsCell = TagsCell;
DataTable.AdvancedTagsCell = AdvancedTagsCell;
DataTable.PersonCell = PersonCell;
DataTable.MarkerCell = MarkerCell;
DataTable.NullableCell = NullableCell;
DataTable.BoolCell = BoolCell;
DataTable.StatusCell = StatusCell;
DataTable.PointsCell = PointsCell;
DataTable.ActionsCell = ActionsCell;
DataTable.ActionsMenuCell = ActionsMenuCell;
DataTable.RatesCell = RatesCell;
DataTable.CountryCell = CountryCell;
DataTable.ContentWithActionCell = ContentWithActionCell;
DataTable.PriorityCell = PriorityCell;
DataTable.ListGroupCell = ListGroupCell;
DataTable.AccountCell = AccountCell;
DataTable.StatusColorCell = StatusColorCell;
DataTable.RatiosCell = RatiosCell;
DataTable.EditedByCell = EditedByCell;
DataTable.LoaderCell = LoaderCell;
DataTable.DateTimeCell = DateTimeCell;
DataTable.CellWithBadge = CellWithBadge;
DataTable.RuleRegionCell = RuleRegionCell;
DataTable.OverridePriceCell = OverridePriceCell;

export { Paging, COLOR };

export default DataTable;
