const errorMessages = {
	UNABLE_TO_FETCH: 'Failed to fetch',
	UNABLE_TO_FETCH_MULTIPLE: 'Failed to fetch multiple',
	UNABLE_TO_LOAD_MULTIPLE: 'Failed to load multiple',
	UNABLE_TO_INSERT: 'Failed to insert',
	UNABLE_TO_INSERT_MULTIPLE: 'Failed to insert multiple',
	UNABLE_TO_UPDATE: 'Unable to update',
	UNABLE_TO_DELETE: 'Failed to delete',
	UNABLE_TO_CREATE_OBJECT: 'Failed to create',
	VALIDATION_FAILED: 'Failed to validate',
	UNABLE_TO_FETCH_PAYLOAD: 'Failed to fetch data',
	UNABLE_TO_PARSE_PAYLOAD: 'Failed to parse data',
	UNABLE_TO_RENDER: 'Failed to render',
	AUTH_UNABLE_TO_HASH_PASSWORD: 'Failed to hash password',
	AUTH_UNABLE_TO_CREATE_JWT: 'Failed to create token',
	AUTH_ACCOUNT_DOES_NOT_EXIST: 'Account does not exist',
	AUTH_ACCOUNT_NOT_ACTIVE: 'Account has not been activated yet',
	AUTH_NO_CREDENTIALS_FOUND: 'Account credentials not found',
	AUTH_ACCOUNT_NOT_CONFIRMED: 'Account not confirmed',
	AUTH_WRONG_CREDENTIALS: 'Wrong Account credentials',
	AUTH_INSUFFICIENT_PRIVILEGES: 'Insufficient privileges',
	AUTH_CANNOT_CONFIRM_ACCOUNT: "Can't confirm Account",
	AUTH_CANNOT_CREATE_RESET_HASH: "Can't create reset hash",
	AUTH_BAD_RESET_HASH: 'Bad reset hash',
	AUTH_ACCOUNT_EXISTS: 'An account with this email already exists',
	AGENT_EXISTS: 'An agent already exists',
	AUTH_USER_EXISTS: 'A user with this email already exists',
	AUTH_FIRM_EXISTS: 'A firm with this email already exists',
	FILE_UPLOAD_FAILED: 'File upload failed',
	MY_FIRM_DATA_IS_REQUIRED: 'My firm data is required',
	MY_FIRM_DATA_FIRM_NAME_NOT_MATCH: 'My firm data does not match with the uploader firm',
};

export const getError = (code) => {
	return errorMessages[code] || 'Error';
};
