import React from 'react';

import AppTemplate from 'com/templates/ApplicationTemplate';

import { INTERNAL_ROLES, PAGE_TITLE } from 'data/constants';

import AppLink from 'com/util/AppLink';
import { RoleBox } from 'com/util/RoleBox';

import useTitle from 'services/hooks/useTitle';

import './style.css';

import ICON_TOOLS_ASSIGN_OVERRIDE from 'assets/images/icons/ico_tools_assignment_override.svg';
import ICON_TOOLS_TRANSLATIONS from 'assets/images/icons/ico_tools_translations.svg';
import ICON_TOOLS_DUPLICATE_MANAGEMENT from 'assets/images/icons/ico_tools_duplicate_management.svg';

const Tools = () => {
	useTitle(PAGE_TITLE.TOOLS);

	return (
		<AppTemplate title="Tools" headerIcon="tools">
			<AppTemplate.Content>
				<div className="main-screen-section">
					<ul className="tools-list">
						<RoleBox roles={INTERNAL_ROLES}>
							<li>
								<AppLink type="regular" to="/tools/assignment-override-requests">
									<img src={ICON_TOOLS_ASSIGN_OVERRIDE} className="tool-list__icon" alt="" />
									<div>
										<span className="tool-list__tool-title">Assignment Override Requests</span>
										<span className="tool-list__tool-description">View associate assignment requests that override regular reciprocity rules</span>
									</div>
								</AppLink>
							</li>
						</RoleBox>
						<RoleBox roles={INTERNAL_ROLES}>
							<li>
								<AppLink type="regular" to="/tools/translation-requests">
									<img src={ICON_TOOLS_TRANSLATIONS} className="tool-list__icon" alt="" />
									<div className="tool-list__text-box">
										<span className="tool-list__tool-title">Translation Requests</span>
										<span className="tool-list__tool-description">View translation requirements per region</span>
									</div>
								</AppLink>
							</li>
						</RoleBox>
						<RoleBox roles={INTERNAL_ROLES}>
							<li>
								<AppLink type="regular" to="/tools/duplicate-management">
									<img src={ICON_TOOLS_DUPLICATE_MANAGEMENT} className="tool-list__icon" alt="" />
									<div className="tool-list__text-box">
										<span className="tool-list__tool-title">Duplicate Management</span>
										<span className="tool-list__tool-description">
											Group name variations of firms and people so that a single firm or person name can be used to manage the many similar but disparate records
										</span>
									</div>
								</AppLink>
							</li>
						</RoleBox>
						<RoleBox roles={INTERNAL_ROLES}>
							<li>
								<AppLink type="regular" to="/tools/renewals/validation-rules">
									<img src={ICON_TOOLS_ASSIGN_OVERRIDE} className="tool-list__icon" alt="" />
									<div className="tool-list__text-box">
										<span className="tool-list__tool-title">Renewals Validation Rules</span>
										<span className="tool-list__tool-description">Maecenas sed metus gravida, interdum risus at, viverra nisl</span>
									</div>
								</AppLink>
							</li>
						</RoleBox>
						<RoleBox roles={INTERNAL_ROLES}>
							<li>
								<AppLink type="regular" to="/tools/renewals/transformation-rules">
									<img src={ICON_TOOLS_ASSIGN_OVERRIDE} className="tool-list__icon" alt="" />
									<div className="tool-list__text-box">
										<span className="tool-list__tool-title">Renewals Transformation Rules</span>
										<span className="tool-list__tool-description">Maecenas sed metus gravida, interdum risus at, viverra nisl</span>
									</div>
								</AppLink>
							</li>
						</RoleBox>
						<RoleBox roles={['saas_user']}>
							<li>
								<AppLink type="regular" to="/tools/client-onboarding">
									<img src={ICON_TOOLS_ASSIGN_OVERRIDE} className="tool-list__icon" alt="" />
									<div className="tool-list__text-box">
										<span className="tool-list__tool-title">Onboarding data upload</span>
										<span className="tool-list__tool-description"></span>
									</div>
								</AppLink>
							</li>
						</RoleBox>
					</ul>
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default Tools;
