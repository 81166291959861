import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// icons
import ICON_DOLLAR_GREY from 'assets/images/icons/ico_dollar_grey.svg';
import ICON_DOLLAR_COLOR from 'assets/images/icons/ico_dollar_color.svg';
// styles
import './style.css';

const PricePoints = (props) => {
	const [tresholds, setTresholds] = useState([]);
	const PRICE_POINTS_TOOLTIP = "This rating shows how your agent's fees compare to other agents offering similar services.";

	useEffect(() => {
		let arr = [];
		let lowerBound = 1;
		let range = props.threshold - lowerBound;
		for (let i = 1; i <= props.length; i++) {
			let upperBound = lowerBound + range / props.length;
			arr.push({ lowerBound, upperBound });
			lowerBound = upperBound;
		}
		setTresholds(arr);
	}, []);

	return (
		<div className="price-points" title={PRICE_POINTS_TOOLTIP}>
			{tresholds.map((t, i) => {
				return <img key={i} src={props.value >= t.lowerBound ? ICON_DOLLAR_COLOR : ICON_DOLLAR_GREY} />;
			})}
		</div>
	);
};

PricePoints.propTypes = {
	threshold: PropTypes.number,
	value: PropTypes.number,
	length: PropTypes.number,
};

PricePoints.defaultProps = {
	tresholds: 0,
	length: 5,
	value: 0,
};

export default PricePoints;
