import Ajax from './base';
const GetAllByQuoteId = (id, queryStringData) => {
	return Ajax.Request(`/quotes/${id}/regions`, Ajax.GET, false, queryStringData);
};

const sendEmailAgentInstructed = (qid, payload) => {
	return Ajax.Request(`/quotes/${qid}/emails/agent-instructed`, Ajax.POST, false, payload);
};

export default {
	GetAllByQuoteId,
	sendEmailAgentInstructed,
};
