import React, { useEffect, useState } from 'react';

import PublicTemplate from 'com/templates/PublicTemplate';
import { AppRoute } from 'com/util/AppRoute';
import AppLink from 'com/util/AppLink';
import Routes from 'data/routes';

import ICON_PHONE from 'assets/images/icons/ico_phone.svg';
import ICON_WEBSITE from 'assets/images/icons/ico_website.svg';
import ICON_EMAIL from 'assets/images/icons/ico_email.svg';

import './style.css';

const LegalInfo = (props) => {
	const [title, setTitle] = useState('');
	const location = window.location.pathname.split('/')[2];
	let year = new Date().getFullYear();

	const TITLE = {
		TERMS_AND_CONDITIONS: 'General Terms & Conditions for Services',
		ENGAGEMENT_LETTER: 'Engagement Letter',
	};

	useEffect(() => {
		switch (location) {
			case 'terms-and-conditions':
				setTitle(TITLE.TERMS_AND_CONDITIONS);
				break;
			case 'engagement-letter':
				setTitle(TITLE.ENGAGEMENT_LETTER);
				break;
			default:
				setTitle('');
				break;
		}
	}, [location]);
	return (
		<PublicTemplate title={title}>
			<PublicTemplate.Sidebar>
				<div className="legal-info-sidebar">
					<nav className="legal-info-sidebar__navigation">
						<AppLink type="nav" to="/legal-info/terms-and-conditions" className="system-settings-sidebar__navigation__link">
							<span className="legal-info-sidebar__navigation__link__label">Terms & Conditions</span>
						</AppLink>
						<AppLink type="nav" to="/legal-info/engagement-letter" className="system-settings-sidebar__navigation__link">
							<span className="legal-info-sidebar__navigation__link__label">Engagement Letter</span>
						</AppLink>
					</nav>
					<div className="legal-info-sidebar__contact__info">
						<div className="legal-info-sidebar__contact__info__item">
							<img src={ICON_PHONE} alt="" />
							<span>+1 (212) 584-2320</span>
						</div>
						<div className="legal-info-sidebar__contact__info__item">
							<img src={ICON_EMAIL} alt="" />
							<span>info@azamiglobal.com</span>
						</div>
						<div className="legal-info-sidebar__contact__info__item">
							<img src={ICON_WEBSITE} alt="" />
							<span>
								<AppLink type="" to={{ pathname: 'https://www.azamiglobal.com' }} target="_blank">
									www.azamiglobal.com
								</AppLink>
							</span>
						</div>
					</div>
				</div>
			</PublicTemplate.Sidebar>
			<PublicTemplate.Content>
				<AppRoute route={Routes.LEGAL_INFO_TERMS_AND_CONDITIONS} />
				<AppRoute route={Routes.LEGAL_INFO_ENGAGEMENT_LETTER} />
				<footer className="legal-info__footer">
					<p>Copyright {year} Azami Global. All rights reserved.</p>
				</footer>
			</PublicTemplate.Content>
		</PublicTemplate>
	);
};

export default LegalInfo;
