import PropTypes from 'prop-types';

import './style.css';

const BooleanBadge = (props) => {
	return <span className={`boolean-badge ${props.value ? 'true' : 'false'}`}></span>;
};

BooleanBadge.propTypes = {
	value: PropTypes.bool,
};

BooleanBadge.defaultProps = {
	value: true,
};

export default BooleanBadge;
