const init = {
	application_number: '',
	client_ref_number: '',
	publication_number: '',
	service_id: '',
	priority_type: null,
	recordal_type: null,
	application_technologies: [],
	regions: [],
	client_id: '',
	application_title: '',
	applicant_name: '',
	earliest_priority_date: null,
	number_of_priorities: 0,
	intl_filing_date: null,
	chapter_two_demand: false,
	claiming_priority: false,
	china_extension_fee: false,
	number_of_words: 0,
	number_of_pages: 0,
	number_of_claims: 0,
	number_of_independent_claims: 0,
	number_of_page_drawings: 0,
	number_of_page_sequences: 0,
	number_of_words_in_claims: 0,
	number_of_classes: 1,
	number_of_embodiments: 0,
	description_images_size: 0,
	entity_size: null,
	location_of_search: 'NO_REPORT',
	application_language: '',
	documents: [],
	calculated_pricing_level: false,
	additional_info: '',
	internal_quote_notes: '',
	custom_pdf_notes: '',
	agree_to_terms: false,
	quote_details_header_error: false,
	client_firm_id: '',
	firm_notes: '',
	instruct_additional_applications: false,
	completed_regions: [],
	draft: false,
	applied_optional_rules: [],
	ignored_discount_regions: {},
	client_contact_greeting: '',
	client_contact_email_for_quotes: [],
	client_contact_full_name: '',
	client_contact_bcc: [],
	client_contact_email: '',
	additional_region_info: [],
};

// constants
const SET_QUOTE_DATA = 'SET_QUOTE_DATA';
const SET_QUOTE_DATA_FROM_OBJECT = 'SET_QUOTE_DATA_FROM_OBJECT';
const CLEAR_QUOTE_DATA = 'CLEAR_QUOTE_DATA';

// actions
export const setQuoteData = (data) => {
	return {
		type: SET_QUOTE_DATA,
		payload: data,
	};
};

export const setQuoteDataFromObject = (data) => {
	return {
		type: SET_QUOTE_DATA_FROM_OBJECT,
		payload: data,
	};
};

export const clearQuoteData = () => {
	return {
		type: CLEAR_QUOTE_DATA,
		payload: init,
	};
};

// reducer
const reducer = (state = init, action) => {
	switch (action.type) {
		case SET_QUOTE_DATA:
			return { ...state, [action.payload.name]: action.payload.value };
		case SET_QUOTE_DATA_FROM_OBJECT:
			return { ...state, ...action.payload };
		case CLEAR_QUOTE_DATA:
			return { ...action.payload };
		default:
			return state;
	}
};

export default reducer;
