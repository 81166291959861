import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// widgets
import Modal from 'com/widgets/Modal';
import CaseSettingsClient from 'com/widgets/CaseSettings/CaseSettingsClient';
import CaseSettingsAssociate from 'com/widgets/CaseSettings/CaseSettingsAssociate';
// settings
import CasesDetails from 'services/rest/cases_details';
import { formatEmailsStringToJSON } from 'services/strings';
// data
import Routes from 'data/routes';
// styles
import './style.css';

const CaseSettings = () => {
	const { id } = useParams();
	const history = useHistory();

	const CLIENT_INFO_TAB_INDEX = 1;
	const ASSOCIATE_INFO_TAB_INDEX = 2;

	const caseDetailsInit = {
		id: '',
		case_id: '',
		client_contact_greeting: '',
		client_contact_full_name: '',
		client_contact_email: '',
		client_contact_email_for_quotes: [],
		client_contact_bcc: [],
		client_reminder_to_client: false,
		client_reminder_to_associate: false,
		client_automatic_reminder: false,
	};

	const [caseDetails, setCaseDetails] = useState(caseDetailsInit);
	const [activeTab, setActiveTab] = useState(CLIENT_INFO_TAB_INDEX);

	useEffect(() => {
		(async () => {
			let cd = await CasesDetails.GetByCaseID(id);
			setCaseDetails({
				...caseDetails,
				...cd,
				client_contact_greeting: cd.client_contact_greeting,
				client_contact_full_name: cd.client_contact_full_name,
				client_contact_email: cd.client_contact_email,
				client_contact_email_for_quotes: cd.client_contact_email_for_quotes,
				client_contact_bcc: cd.client_contact_bcc,
			});
		})();
	}, []);

	const handleOnChange = (e) => {
		switch (e.target.name) {
			case 'client_reminder_to_client':
			case 'client_reminder_to_associate':
			case 'client_automatic_reminder':
				setCaseDetails({
					...caseDetails,
					[e.target.name]: !caseDetails[e.target.name],
				});
				break;
			default:
				setCaseDetails({
					...caseDetails,
					[e.target.name]: e.target.value,
				});
		}
	};

	const handleTabChange = (e) => setActiveTab(parseInt(e.target.dataset.index));

	const handleCloseModal = () => history.push(Routes.CASES.path);

	const handleOnSave = async () => {
		try {
			await CasesDetails.Update(id, caseDetails.id, {
				...caseDetails,
				client_contact_email_for_quotes: formatEmailsStringToJSON(caseDetails.client_contact_email_for_quotes),
				client_contact_bcc: formatEmailsStringToJSON(caseDetails.client_contact_bcc),
			});
			history.push(Routes.CASES.path);
		} catch (error) {
			console.log('error', error);
		}
	};

	const modalActions = [{ primary: true, label: 'Save Changes', action: handleOnSave, theme: 'blurple' }];

	return (
		<Modal title="Case Configuration Settings" footerActions={modalActions} closeHandler={handleCloseModal}>
			<div className="case-configuration-settings__modal">
				<header className="case-configuration-settings__modal-header">
					<ul>
						<li className={`${activeTab === CLIENT_INFO_TAB_INDEX && 'active'}`} data-index={CLIENT_INFO_TAB_INDEX} onClick={handleTabChange}>
							Client Information
						</li>
						<li className={`${activeTab === ASSOCIATE_INFO_TAB_INDEX && 'active'}`} data-index={ASSOCIATE_INFO_TAB_INDEX} onClick={handleTabChange}>
							Associate information
						</li>
					</ul>
				</header>
				<div className="case-configuration-settings__modal-body">
					{activeTab === CLIENT_INFO_TAB_INDEX ?
						<CaseSettingsClient caseDetails={caseDetails} onChange={handleOnChange} />
					:	<CaseSettingsAssociate />}
				</div>
			</div>
		</Modal>
	);
};

export default CaseSettings;
