import React, { useEffect } from 'react';
import LOGO_SMALL from 'assets/images/azami_logo.svg';
// util
import NotificationLayer from 'com/util/NotificationLayer';
// widgets
import Footer from 'com/widgets/Footer';
import CustomerSupport from 'com/widgets/CustomerSupport';

import './style.css';

const AuthTemplate = (props) => {
	useEffect(() => {
		loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`, function () {
			console.log('Script loaded!');
		});
	}, []);

	const loadScriptByURL = (id, url, callback) => {
		const scriptExists = document.getElementById(id);
		if (!scriptExists) {
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = url;
			script.id = id;
			script.onload = function () {
				if (callback) callback();
			};
			document.querySelector('.auth-template').appendChild(script);
		}
		if (scriptExists && callback) callback();
	};

	return (
		<>
			<div className="auth-template">
				<div className="auth-template__header">
					<img src={LOGO_SMALL} className="auth-template__header-logo" alt="" />
					<CustomerSupport />
				</div>
				<div className="auth-template__content">{props.children}</div>
				<Footer customClassName="auth-box" />
			</div>
			<NotificationLayer />
		</>
	);
};

export default AuthTemplate;
