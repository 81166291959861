export const validateRule = (data, pricing_levels) => {
	let isValid = true;
	let errors = {
		name: '',
		optional_rule_text: '',
		services: '',
		regions: '',
		absolute_value_fees: '',
		by_amount_fees: '',
		select_unselect_fees: '',
		from_list_fee: '',
		fee_type: '',
		options: '',
		feesError: false,
		optionsError: false,
		discountsError: false,
		calculationValue1: '',
		calculationValue2: '',
		calculationValues: '',
		calculationField: '',
	};

	if (!data.name) {
		isValid = false;
		errors.name = errorMessages.required;
	}

	if (data.optional_rule) {
		let optional_note = '';
		optional_note = data.optional_rule_text.trim();
		if (optional_note.length === 0) {
			isValid = false;
			errors.optional_rule_text = errorMessages.required;
			errors.optionsError = true;
		}
	}

	if (!data.services.length > 0) {
		isValid = false;
		errors.services = errorMessages.required;
	}

	if (!data.regions.length > 0) {
		isValid = false;
		errors.regions = errorMessages.required;
	}

	if (data.calculation_type === 'ABSOLUTE_VALUE') {
		let base_pricing_level = pricing_levels.find((p) => p.is_base);
		let tmp = data.fees.find((f) => f.level_id === base_pricing_level.id);
		if (tmp.value === undefined) {
			errors.absolute_value_fees = errorMessages.required;
			errors.feesError = true;
			isValid = false;
		}
	}

	if (data.calculation_type === 'BY_AMOUNT') {
		let ranges = data.fees.some((f) => f.range_from === undefined || f.range_to === undefined);
		let fields = data.calculation_field && data.calculation_mode ? false : true;
		let base_pricing_level = pricing_levels.find((p) => p.is_base);
		let tmp = data.fees.some((f) => f.level_id === base_pricing_level.id && f.value === undefined);
		let range_errors = data.fees.some((f) => f.range_error);
		if (tmp || ranges || fields || range_errors) {
			errors.by_amount_fees = errorMessages.required;
			errors.feesError = true;
			isValid = false;
		}
	}

	if (data.calculation_type === 'SELECTED_UNSELECTED') {
		if (isNaN(parseFloat(data.calculation_value1)) || !data.calculation_field) {
			errors.select_unselect_fees = errorMessages.required;
			errors.feesError = true;
			errors.calculationValue1 = isNaN(parseFloat(data.calculation_value1)) ? errorMessages.required : '';
			errors.calculationField = !data.calculation_field ? errorMessages.required : '';
			isValid = false;
		}
	}

	if (data.calculation_type === 'FROM_LIST') {
		if (data.calculation_value_condition === 'IS_ONE_OF' || data.calculation_value_condition === 'IS_NOT_ONE_OF') {
			if (isNaN(parseFloat(data.calculation_value1)) || !data.calculation_field || data.calculation_values.length < 1) {
				errors.from_list_fee = errorMessages.required;
				errors.feesError = true;
				errors.calculationValue1 = isNaN(parseFloat(data.calculation_value1)) ? errorMessages.required : '';
				errors.calculationValues = data.calculation_values.length < 1 ? errorMessages.required : '';
				errors.calculationField = !data.calculation_field ? errorMessages.required : '';
				isValid = false;
			}
		} else if (isNaN(parseFloat(data.calculation_value1)) || !data.calculation_field || !data.calculation_value2) {
			errors.from_list_fee = errorMessages.required;
			errors.feesError = true;
			errors.calculationValue1 = isNaN(parseFloat(data.calculation_value1)) ? errorMessages.required : '';
			errors.calculationValue2 = !data.calculation_value2 ? errorMessages.required : '';
			errors.calculationField = !data.calculation_field ? errorMessages.required : '';
			isValid = false;
		}
	}

	if (data.calculation_type == 'DISCOUNT') {
		if (!data.calculation_value1 || !data.calculation_field || !data.calculation_value2) {
			errors.discountsError = true;
			isValid = false;
		}
	}

	if (!data.fee_type) {
		errors.optionsError = true;
		isValid = false;
		errors.fee_type = errorMessages.required;
	}
	return { isValid, errors };
};

const errorMessages = {
	required: 'Required',
};

export const regroupRanges = (data) => {
	// sort ranges by from and to
	let fees = data.sort((a, b) => {
		return a.range_from - b.range_from;
	});
	// group the ranges by group, from and to
	fees = groupRanges(fees);
	// reasign groups by their array index in the matrix
	fees = fees.map((arr, i) => {
		return arr.map((r) => {
			r.group = i;
			return r;
		});
	});
	// return flat array
	return [...fees.flat()];
};

const groupRanges = (data) => {
	const groups = {};
	data.forEach((item) => {
		const group = JSON.stringify([item.range_from, item.range_to, item.group]);
		groups[group] = groups[group] || [];
		groups[group].push(item);
	});
	return Object.keys(groups).map(function (group) {
		return groups[group];
	});
};

export const populateMissingAbsolyteFeesLevels = (pl, fees) => {
	let data = pl.map((l) => {
		let c = fees.find((r) => r.level_id === l.id);
		if (!c) {
			return {
				level_id: l.id,
				value: undefined,
				range_from: undefined,
				range_to: undefined,
				group: 0,
			};
		} else {
			return c;
		}
	});
	return data;
};

export const populateMissingByAmountFeesLevels = (pl, fees) => {
	let data = [];
	let tmp_data = groupRanges(fees);
	tmp_data.map((range) => {
		pl.map((l) => {
			let c = range.find((r) => r.level_id === l.id);
			if (c) {
				data.push(c);
			} else {
				data.push({
					level_id: l.id,
					value: undefined,
					range_from: range[0].range_from,
					range_to: range[0].range_to,
					group: range[0].group,
				});
			}
		});
	});
	return data;
};
