import Ajax from './base';

const GetAssociates = (cid) => {
	return Ajax.Request(`/associate-selection/cases-regions/${cid}`, Ajax.GET, true);
};

const GetAllAssociates = (queryStringData) => {
	return Ajax.Request('/associate-selection', Ajax.GET, false, queryStringData);
};

const UpdateOneByCaseRegionId = (payload) => {
	return Ajax.Request(`/associate-selection/${payload.case_region_id}`, Ajax.PUT, false, payload);
};

export default {
	GetAssociates,
	GetAllAssociates,
	UpdateOneByCaseRegionId,
};
