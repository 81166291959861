//Template Imports
import AuthBox from 'com/templates/AuthBox';
//UI Imports
import BackButton from 'com/ui/BackButton';
//Style Imports
import './style.css';

const ChangePasswordMessageAzami = () => {
	return (
		<div className="change-password-message-container">
			<AuthBox title="Password successfully changed" titleColor="azami-blurple" icon="password" footer={<BackButton to="/">Back to login</BackButton>}>
				Your password has been successfully changed. Please proceed to the login page and enter the system.
			</AuthBox>
		</div>
	);
};

export default ChangePasswordMessageAzami;
