import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { RoleBox } from 'com/util/RoleBox';
import { AppRoute } from 'com/util/AppRoute';
import AppLink from 'com/util/AppLink';
import Routes from 'data/routes';
import { RECIPROCITY_OVERRIDE_TYPE, RECIPROCITY_OVERRIDE_DIRECTION } from 'data/constants';

import Input from 'com/ui/Input';
import Select from 'com/ui/Select';
import MultiSelect from 'com/ui/MultiSelect';
import DataTable from 'com/widgets/DataTable';
import Modal from 'com/widgets/Modal';
import LoaderOverlay from 'com/ui/LoaderOverlay';

import Settings from 'services/rest/settings';
import Reciprocity from 'services/rest/reciprocity';

import useNotification from 'services/hooks/use_notification';
import useFilterStorage from 'services/hooks/useFilterStorage';

import ICON_INFO from 'assets/images/icons/ico_info_magenta.svg';

import './style.css';

const ReciprocityOverrides = (props) => {
	const location = useLocation();
	const prepareReciprocityTypeOptions = () => {
		let url = location.pathname.split('/');
		switch (url[1]) {
			case 'system-settings':
				return Object.keys(RECIPROCITY_OVERRIDE_TYPE)
					.filter((rod) => rod === RECIPROCITY_OVERRIDE_TYPE.SYSTEM)
					.map((key) => ({ label: key, value: key }));
			case 'firm-management':
				return Object.keys(RECIPROCITY_OVERRIDE_TYPE)
					.filter((rod) => [RECIPROCITY_OVERRIDE_TYPE.FIRM, RECIPROCITY_OVERRIDE_TYPE.ACCOUNT].includes(rod))
					.map((key) => ({ label: key, value: key }));
			default:
				throw Error(`Unknown reciprocity override type: ${url[1]}`);
		}
	};
	const rulesTableHeaders = [
		{ title: 'Date', field: 'date', type: 'string', sortable: false },
		{ title: 'Region', field: 'region', type: 'string', sortable: false },
		{ title: 'Service', field: 'service', type: 'string', sortable: false },
		{ title: 'For', field: 'for', type: 'string', sortable: false },
		{ title: 'Case Direction', field: 'direction', type: 'string', sortable: false },
		{ title: 'Amount', field: 'amount', type: 'string', sortable: false },
		{ title: '', field: '', type: 'string', sortable: false },
	];
	const types = [{ label: 'All', value: '' }, ...prepareReciprocityTypeOptions()];
	const directions = [{ label: 'All', value: '' }, ...Object.keys(RECIPROCITY_OVERRIDE_DIRECTION).map((key) => ({ label: key, value: key }))];
	const filtersInit = {
		date: '',
		regions: [],
		services: [],
		type: '',
		direction: '',
	};

	const params = useParams();
	const [regions, setRegions] = useState([]);
	const [services, setServices] = useState([]);
	const [reciprocityOverrides, setReciprocityOverrides] = useState([]);
	const [deletingOverrideId, setDeletingOverrideId] = useState('');
	const [showDeleteReciprocityOverrideModal, setShowDeleteReciprocityOverrideModal] = useState(false);
	const [filters, setFilters] = useState(filtersInit);
	const [showLoader, setShowLoader] = useState(false);
	const sendNotification = useNotification();
	const { getFilterValue, setFilterValue } = useFilterStorage();

	useEffect(() => {
		(async () => {
			let f = getFilterValue();
			if (f) setFilters(f);
			await fetchData();
		})();
	}, []);

	useEffect(async () => {
		fetchReciprocityOverrides();
	}, [filters]);

	const fetchData = async () => {
		try {
			const [regions, services] = await Promise.all([Settings.GetRegions(), Settings.GetServices()]);
			setRegions(regions.map((r) => ({ value: r.id, label: r.name })));
			setServices(services.map((s) => ({ value: s.id, label: s.name })));
		} catch (err) {
			console.log(err);
		}
	};

	const fetchReciprocityOverrides = async () => {
		try {
			setShowLoader(true);
			let ro;
			let url = location.pathname.split('/');
			switch (url[1]) {
				case 'system-settings':
					ro = await Reciprocity.GetAllSystemReciprocityOverrides(filters);
					break;
				case 'firm-management':
					ro = await Reciprocity.GetAllReciprocityOverridesByFirm(params.id, filters);
					break;
				default:
					throw Error(`Unknown reciprocity override type: ${url[1]}`);
			}
			setReciprocityOverrides(ro);
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
			return err;
		}
	};

	const openDeleteReciprocityOverrideModal = (e) => {
		setDeletingOverrideId(e.target.dataset.overrideid);
		setShowDeleteReciprocityOverrideModal(true);
	};

	const closeDeleteReciprocityOverrideModal = () => {
		setDeletingOverrideId('');
		setShowDeleteReciprocityOverrideModal(false);
	};

	const handleDeleteReciprocityOverride = async () => {
		try {
			setShowLoader(true);
			await Reciprocity.DeleteReciprocityOverride(deletingOverrideId);
			await fetchReciprocityOverrides();
			closeDeleteReciprocityOverrideModal();
			setShowLoader(false);
			sendNotification({ type: 'success', title: 'Reciprocity rule successfully deleted' });
		} catch (err) {
			console.log(err);
			setShowLoader(false);
			sendNotification({ type: 'error', title: 'Reciprocity rule deletion failed' });
		}
	};

	const filterChange = (e) => {
		let { name, value } = e.target;

		setFilters({
			...filters,
			[name]: value,
		});

		setFilterValue({
			...filters,
			[name]: value,
		});
	};

	const generateRoutePath = () => {
		let url = location.pathname.split('/');
		switch (url[1]) {
			case 'system-settings':
				return `/system-settings/reciprocity/rules`;
			case 'firm-management':
				return `/firm-management/${params.id}/reciprocity-rules`;
			default:
				throw Error(`Unknown reciprocity override type: ${url[1]}`);
		}
	};

	const getRouteSettings = () => {
		let url = location.pathname.split('/');
		switch (url[1]) {
			case 'system-settings':
				return Routes.SYSTEM_SETTINGS_RECIPROCITY_RULES_CREATE;
			case 'firm-management':
				return Routes.FIRM_DETAILS_RECIPROCITY_RULES_CREATE;
			default:
				throw Error(`Unknown reciprocity override type: ${url[1]}`);
		}
	};

	const deleteReciprocityOverrideModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeleteReciprocityOverrideModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Delete', action: handleDeleteReciprocityOverride, theme: 'azami-blue' },
	];

	return (
		<div className="reciprocity-overrides-rules">
			<LoaderOverlay showLoader={showLoader} />
			<div className="reciprocity-overrides-rules__section-header">
				<div className="reciprocity-overrides-rules__section-header__info">
					<img src={ICON_INFO} />
					<span className="title">Account Rules overrides the Firm Rules</span>
				</div>
				<RoleBox roles={['admin']}>
					<AppLink to={`${generateRoutePath()}/add`} className="form-button light">
						Add Rule
					</AppLink>
				</RoleBox>
			</div>
			<div className="reciprocity-overrides-rules__section-filters">
				<Input type="date" name="date" label="Filter Date" value={filters.date} onChange={filterChange} />
				<MultiSelect name="regions" label="Filter Regions" options={regions} values={filters.regions} onChange={filterChange} selectAllEnabled={true} />
				<MultiSelect name="services" label="Filter Services" options={services} values={filters.services} onChange={filterChange} selectAllEnabled={true} />
				<Select name="type" label="For" options={types} value={filters.type} onChange={filterChange} />
				<Select name="direction" label="Case Direction" options={directions} value={filters.direction} onChange={filterChange} />
			</div>
			<div className="reciprocity-overrides-rules__section-body">
				{reciprocityOverrides.length ?
					<DataTable fixedHeader={true} columns={rulesTableHeaders}>
						{reciprocityOverrides.map((ro, key) => (
							<tr key={key}>
								<DataTable.DateRangeCell start={ro.start_date} end={ro.end_date} />
								<DataTable.CountryCell code={ro.region_code || 'ALL'}>{ro.region_name || 'ALL'}</DataTable.CountryCell>
								<DataTable.Cell>{ro.service_name}</DataTable.Cell>
								<DataTable.Cell>{[RECIPROCITY_OVERRIDE_TYPE.SYSTEM, RECIPROCITY_OVERRIDE_TYPE.FIRM].includes(ro.object_type) ? ro.object_type : ro.object_type_name}</DataTable.Cell>
								<DataTable.Cell>{ro.direction}</DataTable.Cell>
								<DataTable.PointsCell>{ro.amount}</DataTable.PointsCell>
								<RoleBox roles={['admin']}>
									<DataTable.ActionsCell>
										<AppLink to={`${generateRoutePath()}/${ro.id}`} className="rules-table__action-edit-btn"></AppLink>
										<button className="rules-table__action-delete-btn" onClick={openDeleteReciprocityOverrideModal} data-overrideid={ro.id} title="Delete" />
									</DataTable.ActionsCell>
								</RoleBox>
							</tr>
						))}
					</DataTable>
				:	null}
				<AppRoute
					route={getRouteSettings()}
					afterSave={fetchReciprocityOverrides}
					prepareReciprocityTypeOptions={prepareReciprocityTypeOptions}
					reciprocityOverrides={reciprocityOverrides}
					firmAccounts={props.firmAccounts || []}
					exact
				/>
				{showDeleteReciprocityOverrideModal && (
					<Modal title="Delete Points Override Rule" closeHandler={closeDeleteReciprocityOverrideModal} footerActions={deleteReciprocityOverrideModalFooterActions}>
						Are you sure you want to delete this rule?
					</Modal>
				)}
			</div>
		</div>
	);
};

export default ReciprocityOverrides;
