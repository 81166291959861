import React, { useEffect, useState } from 'react';

import Checkbox from 'com/ui/Checkbox';
import Settings from 'services/rest/settings';
import LoaderOverlay from 'com/ui/LoaderOverlay';

import './style.css';

const ExpertiseList = (props) => {
	const [technologies, setTechnologies] = useState([]);
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		setShowLoader(true);
		Settings.GetTechnologies()
			.then((data) => {
				setTechnologies(data);
				setShowLoader(false);
			})
			.catch((err) => {
				console.log(err);
				setShowLoader(false);
			});
	}, []);

	const expertiseChange = (e) => {
		if (props.onChange) {
			props.onChange(e.target.value, e.target.checked);
		}
	};

	return (
		<div className="expertise-list">
			<LoaderOverlay showLoader={showLoader} />
			<span className="expertise-list__title">Areas of Expertise</span>
			<div className="expertise-list__header">
				<Checkbox label="Title" fieldClassName="expertise-list__header__checkbox" forRoles={['admin', 'sales', 'sales_manager', 'saas_user']} />
			</div>
			{props.values &&
				technologies.map((t) => {
					let checked = props.values.includes(t.id) ? true : false;
					return (
						<div className="expertise-list__item" key={t.id}>
							<Checkbox
								label={t.name}
								fieldClassName="expertise-list__items__checkbox"
								onChange={expertiseChange}
								value={t.id}
								checked={checked}
								forRoles={['admin', 'sales', 'sales_manager', '$user']}
								readOnly={!!props.readOnly}
							/>
						</div>
					);
				})}
		</div>
	);
};

export default ExpertiseList;
