import React from 'react';

import ChangePasswordMessageAzami from './ChangePasswordMessageAzami';
import ChangePasswordMessageIPeer from './ChangePasswordMessageIPeer';

import withDomainAuthTemplate from 'com/hoc/withDomainAuthTemplate';

import './style.css';

const ChangePasswordMessageTemplate = withDomainAuthTemplate(ChangePasswordMessageAzami, ChangePasswordMessageIPeer);

const ChangePasswordMessage = () => {
	return <ChangePasswordMessageTemplate />;
};

export default ChangePasswordMessage;
