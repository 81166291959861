import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Input from 'com/ui/Input';
import Select from 'com/ui/Select';
import Button from 'com/ui/Button';
import DataTable from 'com/widgets/DataTable';
import LetterBadge from 'com/ui/LetterBadge';
import BooleanBadge from 'com/ui/BooleanBadge';
import CustomizeEstimateRule from 'com/widgets/CustomizeEstimateRule';
import EstimatorRulesPreview from 'com/widgets/EstimatorRulesPreview';

import Settings from 'services/rest/settings';
import Estimates from 'services/rest/estimates';
import PricingLevel from 'services/rest/pricing_levels';

import useFilterStorage from 'services/hooks/useFilterStorage';
import useDebounce from 'services/hooks/useDebounce';

import { DEBOUNCE_DELAY_MS } from 'data/constants';

import './style.css';
import Modal from 'com/widgets/Modal';

const FirmDetailsEstimateRules = (props) => {
	const history = useHistory();
	const { id } = useParams();

	const tableHeadersInit = [
		{ title: 'Rule Name', field: 'name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Services', field: 'flat_services', type: 'string', sort: 'none', sortable: false },
		{ title: 'Regions', field: 'flat_regions', type: 'string', sort: 'none', sortable: false },
		{ title: 'Fee', field: 'fee_type', type: 'string', sort: 'none', sortable: true },
		{ title: 'Pricing Level', field: 'use_pricing_level', type: 'boolean', sort: 'none', sortable: true },
		{ title: '', field: 'associate_firm_name', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: 'delete', type: 'string', sort: 'none', sortable: false },
	];

	const feeTypes = [
		{ label: 'All', value: '' },
		{ label: 'Professional', value: 'PROFESSIONAL' },
		{ label: 'Official', value: 'OFFICIAL' },
		{ label: 'Translation', value: 'TRANSLATION' },
	];

	const badgeColors = {
		PROFESSIONAL: '#026EB3',
		OFFICIAL: '#10B5B5',
		TRANSLATION: '#BD2FEE',
	};

	const filterInit = {
		object_level: 'FIRM',
		object_id: id,
		name: '',
		region: '',
		service: '',
		fee_type: '',
		orderBy: [],
		deleted: '0',
	};

	const [services, setServices] = useState([]);
	const [regions, setRegions] = useState([]);
	const [rules, setRules] = useState([]);
	const [filters, setFilters] = useState(filterInit);
	const [createPopupShow, setCreatePopupShow] = useState(false);
	const [isEditRule, setIsEditRule] = useState(false);
	const [ruleToCustomize, setRuleToCustomize] = useState('');
	const [ruleToPreview, setRuleToPreview] = useState('');
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
	const [ruleToDelete, setRuleToDelete] = useState('');
	const [showRulePreview, setShowRulePreview] = useState(false);
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [showArchived, setShowArchived] = useState('0');
	const [pricingLevels, setPricingLevels] = useState([]);

	const { getFilterValue, setFilterValue } = useFilterStorage();
	const debouncedFilters = useDebounce(filters, DEBOUNCE_DELAY_MS);

	useEffect(() => {
		(async () => {
			try {
				let [regionData, servicesData, pl] = await Promise.all([Settings.GetRegions(), Settings.GetServices(), PricingLevel.GetAll({ show_is_archived: '0' })]);
				setRegions([
					{ label: 'All', value: '' },
					...regionData.map((c) => {
						return { ...c, label: c.name, value: c.id };
					}),
				]);
				setServices([
					{ label: 'All', value: '' },
					...servicesData.map((c) => {
						return { ...c, label: c.name, value: c.id };
					}),
				]);
				setPricingLevels(pl);
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				let filter = {
					name: filters.name,
					region: filters.region,
					service: filters.service,
					fee_type: filters.fee_type,
					object_level: filters.object_level,
					object_id: filters.object_id,
					order_by: '',
					deleted: filters.deleted,
				};
				if (!!filters.orderBy) {
					filter.order_by = filters.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(',');
				}
				let rules = await Estimates.GetAllRules(filter);
				setRules(rules.data);
			} catch (err) {
				console.log(err);
			}
		})();
	}, [debouncedFilters, createPopupShow]);

	useEffect(() => {
		let f = getFilterValue();
		if (f) setFilters(f);
	}, []);

	const idsToNames = (ids, array) => {
		if (ids && ids.length > 0) {
			return ids.map((i) => {
				return array.find((a) => a.id == i)?.name;
			});
		}
		return [];
	};

	const onFilterChange = (e) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value,
		});

		setFilterValue({
			...filters,
			[e.target.name]: e.target.value,
		});
	};

	const createNewPopupAdd = async () => {
		setIsEditRule(false);
		setCreatePopupShow(true);
	};

	const createNewPopupEdit = async (e) => {
		e.stopPropagation();
		setIsEditRule(true);
		let rule_id = e.currentTarget.dataset.id;
		setRuleToCustomize(rule_id);
		setCreatePopupShow(true);
	};

	const closeCreateNewPopup = () => {
		setCreatePopupShow(false);
	};

	const closeRulePreview = () => {
		setRuleToPreview('');
		setShowRulePreview(false);
	};

	const openRulePreview = async (e) => {
		let rule_id = e.currentTarget.dataset.id;
		let rule = await Estimates.GetOneRulesByID(rule_id);
		setRuleToPreview(rule);
		setShowRulePreview(true);
	};

	const openDeleteModal = (e) => {
		e.stopPropagation();
		if (e.target.dataset.id === undefined) {
			setRules(rules.filter((p, i) => i != e.target.dataset.index));
			return;
		}
		setShowDeleteConfirmationModal(true);
		setRuleToDelete(e.target.dataset.id);
	};

	const closeDeleteModal = () => {
		setShowDeleteConfirmationModal(false);
	};

	const rulePreviewActions = [
		{ theme: 'azami-ghost', label: 'Archive Rule', action: openDeleteModal, condition: null },
		{ theme: 'azami-blue', label: 'Customize Rule', action: createNewPopupEdit, condition: null },
	];
	const deleteRule = async (e) => {
		try {
			await Estimates.DeleteRule(ruleToDelete, 'FIRM');
			setRules(rules.filter((r) => r.id !== ruleToDelete));
			setShowDeleteConfirmationModal(false);
			setShowRulePreview(false);
		} catch (err) {
			console.log(err);
		}
	};

	const deleteModalActions = [
		{ primary: false, label: 'Close', action: closeDeleteModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Delete', action: deleteRule, theme: 'azami-blue' },
	];

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filters.orderBy.filter((r) => r.field === col).length === 0) {
						setFilters({
							...filters,
							orderBy: [{ field: col, direction: h.sort }, ...filters.orderBy],
						});
					} else {
						setFilters({
							...filters,
							orderBy: [{ field: col, direction: h.sort }, ...filters.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
	};

	const toggleArchived = (e) => {
		setFilters({ ...filters, deleted: e.target.dataset.value });
		setShowArchived(e.target.dataset.value);
	};

	return (
		<div className="firm-details-members">
			<div className="firm-details-members__section-header filters">
				<Input label="Search by name" name="name" value={filters.name} onChange={onFilterChange} />
				<Select label="Region" options={regions} name="region" value={filters.region} onChange={onFilterChange} />
				<Select label="Service" options={services} name="service" value={filters.service} onChange={onFilterChange} />
				<Select label="Fee Type" options={feeTypes} name="fee_type" value={filters.fee_type} onChange={onFilterChange} />
				<Button className="firm-details-members__section-header__add-btn" onClick={createNewPopupAdd}>
					Add Rule
				</Button>
			</div>
			<div className="firm-details-members__section-header-filters-archived">
				<span className="system-settings-pricing-levels__header__title">Estimator Rules</span>
				<ul>
					<li className={showArchived === '0' ? 'active' : ''} onClick={toggleArchived} data-value={'0'}>
						Active
					</li>
					<li className={showArchived === '1' ? 'active' : ''} onClick={toggleArchived} data-value={'1'}>
						Archived
					</li>
					<li className={showArchived === '' ? 'active' : ''} onClick={toggleArchived} data-value={''}>
						All
					</li>
				</ul>
			</div>
			<div className="firm-details-members__section-body">
				<EstimatorRulesPreview show={showRulePreview} rule={ruleToPreview} footerActions={rulePreviewActions} closeHandlrer={closeRulePreview} />
				<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort}>
					{rules.map((r, i) => {
						return (
							<tr key={r.id} onClick={openRulePreview} data-id={r.id}>
								<DataTable.Cell>{r.name}</DataTable.Cell>
								<DataTable.Cell>{idsToNames(r.flat_services, services).join(', ')}</DataTable.Cell>
								<DataTable.Cell>{idsToNames(r.flat_regions, regions).join(', ')}</DataTable.Cell>
								<DataTable.Cell>
									<LetterBadge color={badgeColors[r.fee_type]}>{r.fee_type}</LetterBadge>
								</DataTable.Cell>
								<DataTable.Cell>
									<BooleanBadge value={!!r.use_pricing_level} />
								</DataTable.Cell>
								<DataTable.Cell>
									<button className="settings-estimator-rules__edit-btn" data-id={r.id} onClick={createNewPopupEdit}></button>
								</DataTable.Cell>
								<DataTable.Cell>
									{r._deleted ?
										<button className="settings-estimator-rules__unarchive-btn" data-id={r.id} onClick={openDeleteModal} title="Unarchive"></button>
									:	<button className="settings-estimator-rules__delete-btn" data-id={r.id} onClick={openDeleteModal} title="Archive"></button>}
								</DataTable.Cell>
							</tr>
						);
					})}
				</DataTable>
			</div>
			{showDeleteConfirmationModal ?
				<Modal title="Delete Estimator Rule?" closeHandler={closeDeleteModal} footerActions={deleteModalActions}>
					<div>Are you sure you like to delete this Estimator Rule?</div>
				</Modal>
			:	null}
			{createPopupShow ?
				<CustomizeEstimateRule
					title={isEditRule ? 'Edit Custom Rule' : 'Customize Rule'}
					closeHandler={closeCreateNewPopup}
					isEdit={isEditRule}
					ruleToCustomize={ruleToCustomize}
					object_id={id}
					object_level={'FIRM'}
					sendNotification={props.sendNotification}
					services={services}
					regions={regions}
					pricingLevels={pricingLevels}
				></CustomizeEstimateRule>
			:	null}
		</div>
	);
};

export default FirmDetailsEstimateRules;
