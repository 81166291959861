import Ajax from './base';

const DocumentUpload = (files, payload) => {
	return Ajax.UploadMultiple('/storage/document', files, payload);
};

const DocumentDownload = (id) => {
	return Ajax.Request(`/storage/document/${id}`, Ajax.GET, false);
};

const DocumentDelete = (id) => {
	return Ajax.Request(`/storage/document/${id}`, Ajax.DELETE, false);
};

const UploadImage = (t, name, file) => {
	if (!['avatars', 'logos'].includes(t)) throw new Error('Invalid image type');
	return Ajax.UploadSingle(`/storage/images/${t}`, name, file);
};

const DownloadImage = (imagetype, file) => {
	if (!['avatars', 'logos'].includes(imagetype)) throw new Error('Invalid image type');
	return Ajax.Request(`/storage/images/${imagetype}/${file}`, Ajax.GET, false);
};

export default {
	DocumentUpload,
	DocumentDownload,
	DocumentDelete,
	UploadImage,
	DownloadImage,
};
