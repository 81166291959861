//vendor imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//redux
import { useDispatch } from 'react-redux';
import { toastsRemove } from 'redux/ducks/notification_toasts';
//icons
import ICON_INFO from 'assets/images/icons/ico_toast_info.svg';
import ICON_ERROR from 'assets/images/icons/ico_toast_error.svg';
import ICON_WARNING from 'assets/images/icons/ico_toast_warning.svg';
import ICON_SUCCESS from 'assets/images/icons/ico_toast_success.svg';
//styles
import './style.css';

const toastColors = {
	info: 'info',
	error: 'error',
	warning: 'warning',
	success: 'success',
};

const toastIcons = {
	info: ICON_INFO,
	error: ICON_ERROR,
	warning: ICON_WARNING,
	success: ICON_SUCCESS,
};

const Toast = (props) => {
	const [unmount, setUnmount] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		setTimeout(() => {
			setUnmount('unmount');
			setTimeout(() => {
				dispatch(toastsRemove(props.id));
			}, 300);
		}, props.timeout);
	}, []);

	const removeToast = () => {
		dispatch(toastsRemove(props.id));
	};

	return (
		<div className={`toast-notification ${toastColors[props.type]} ${unmount}`}>
			<img className="toast-notification__icon" src={toastIcons[props.type]} alt="toast icon" />
			<div className="toast-notification__content">{props.value}</div>
			{props.toastActions.length > 0 ?
				<div className="toast-notification__actions">
					{props.toastActions.map((t, i) => {
						return (
							<button key={i} onClick={t.action}>
								{t.label}
							</button>
						);
					})}
				</div>
			:	null}
			<button className="toast-notification__remove" onClick={removeToast}>
				&times;
			</button>
		</div>
	);
};

Toast.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	type: PropTypes.oneOf(['info', 'error', 'warning', 'success']),
	timeout: PropTypes.number,
	value: PropTypes.string,
	toastActions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			action: PropTypes.func,
		}),
	),
};

Toast.defaultProps = {
	id: '',
	type: 'info',
	timeout: 4000,
	value: '',
	toastActions: [],
};

export default Toast;
