import AppLink from 'com/util/AppLink';

import DataTable from 'com/widgets/DataTable';
import { RoleBox } from 'com/util/RoleBox';
import { EXTERNAL_ROLES, INTERNAL_ROLES } from 'data/constants';
import ICON_ERROR from 'assets/images/icons/ico_error_red.svg';

const FirmDetailsMembers = (props) => {
	const tableHeaders = [
		{ title: 'Name', field: 'name', type: 'string', sortable: false },
		{ title: 'Email', field: 'email', type: 'string', sortable: false },
		{ title: 'Recip. Account ', field: 'firm_account_id', type: 'string', sortable: false },
	];

	return (
		<div className="firm-details-members">
			<div className="firm-details-members__section-body">
				<DataTable fixedHeader={true} columns={tableHeaders}>
					{props.data.members &&
						props.data.members.map((member) => {
							const { first_name, last_name, email, id, image } = member;
							const recipAccount = props.data.accounts.find((a) => a.id === member.firm_account_id);
							return (
								<tr key={id}>
									<DataTable.PersonCell ico={image} uid={id} imageType="avatars">
										<RoleBox roles={INTERNAL_ROLES}>
											<AppLink type="regular" to={`/user-management/${id}`}>
												{first_name} {last_name}
											</AppLink>
										</RoleBox>
										<RoleBox roles={EXTERNAL_ROLES}>
											<p className="firm-details-members__section-body__full-name">
												{first_name} {last_name}
											</p>
										</RoleBox>
									</DataTable.PersonCell>
									<DataTable.Cell>{email}</DataTable.Cell>
									<RoleBox roles={INTERNAL_ROLES}>
										<DataTable.Cell>
											{recipAccount ?
												recipAccount.name
											:	<img
													src={ICON_ERROR}
													alt=""
													title="This account has not been assigned to any firm or belongs to another firm. Please check the user details and assign it accordingly."
												/>
											}
										</DataTable.Cell>
									</RoleBox>
									<RoleBox roles={EXTERNAL_ROLES}>
										<DataTable.Cell>{recipAccount?.name}</DataTable.Cell>
									</RoleBox>
								</tr>
							);
						})}
				</DataTable>
			</div>
			<div className="firm-details-members__section-footer"></div>
		</div>
	);
};

export default FirmDetailsMembers;
