import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ForgotPasswordAzami from './ForgotPasswordAzami';
import ForgotPasswordIPeer from './ForgotPasswordIPeer';
import { PAGE_TITLE } from 'data/constants';
import withDomainAuthTemplate from 'com/hoc/withDomainAuthTemplate';

import Auth from 'services/rest/auth';
import useTitle from 'services/hooks/useTitle';
import { getError } from 'util/errors';

import './style.css';

const ForgotPasswordTemplate = withDomainAuthTemplate(ForgotPasswordAzami, ForgotPasswordIPeer);

const ForgotPassword = () => {
	useTitle(PAGE_TITLE.FORGOT_PASSWORD);

	const [spinner, setSpinner] = useState(false);
	const history = useHistory();

	const initialState = {
		email: '',
	};

	const forgotPasswordSubmit = async (fields, { setStatus }) => {
		try {
			setSpinner(true);
			console.log(fields);
			await Auth.ForgotPassword(fields.email);
			setSpinner(false);
			history.push('/forgot-password/message');
		} catch (err) {
			setStatus(getError(err.code));
			console.log(err);
			setSpinner(false);
		}
	};

	return <ForgotPasswordTemplate initialState={initialState} forgotPasswordSubmit={forgotPasswordSubmit} spinner={spinner} />;
};

export default ForgotPassword;
