import React from 'react';
import { Switch } from 'react-router';

const AppSwitch = (props) => {
	return (
		<Switch>
			{React.Children.map(props.children, (c) => {
				return React.cloneElement(c, { path: c.props.route.path, exact: c.props.exact });
			})}
		</Switch>
	);
};

export default AppSwitch;
