import React from 'react';

import { API_HOST } from 'data/constants';

import './style.css';

const SystemSettingsIntegrations = () => {
	const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=450,height=650,left=-100,top=-100`;
	const openPopup = (url, name) => {
		window.open(url, name, params);
	};

	return (
		<div className="system-settings__integrations">
			<ol className="system-settings__integrations__list">
				<li className="salesforce__link" onClick={() => openPopup(`${API_HOST}/settings/integrations/salesforce`, 'SalesForce')}>
					SalesForce
				</li>
			</ol>
		</div>
	);
};

export default SystemSettingsIntegrations;
