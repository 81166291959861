import React from 'react';

import './style.css';

const LegalInfoEngagementLetter = () => {
	return (
		<div className="engagement-letter__content-wrapper">
			<p>
				This document sets out the terms of business between you (member) and Azami Global (AZAMI). It is provided for the purpose of clarifying some important aspects of our relationship. All
				professional work carried out will be in accordance with these terms.
			</p>
			<p>(1) Responsibilities of Azami Global</p>
			<p>
				Azami Global is a foreign filing service provider which unites like-minded patent and trademark attorneys around the world. We at Azami Global are committed to upholding the highest
				professional standards for our members and will always work in our members’ best interests. We will respond in a proficient and timely manner to our members’ needs and will be
				available to provide foreign filing support.
			</p>
			<p>We provide a number of services which deliver short and long term benefit to the applicants represented by the firms in our network including but not limited to:</p>

			<ol className="services__list">
				<li>Cost estimates</li>
				<li>Foreign Filing Support</li>
				<li>Competitive pricing</li>
				<li>Quality oversight</li>
				<li>Administrative services</li>
			</ol>

			<p>
				We charge fees for the services listed above. While AZAMI does provide reciprocal work to its member firms, AZAMI does not accept any referral fees. There are no “kickbacks” charged or
				accepted from any firm based on how many cases are sent to them. We send work to firms who satisfy our criteria for quality and we do not send cases to foreign firms unless they have
				been pre-approved by the “client” firm apart from when instructions are received with an urgent deadline approaching.
			</p>
			<p>Acting as a central point of contact, AZAMI connects hundreds of associates and assists them in the coordination of their filing requests into over 120 PCT member states.</p>
			<p>(2) Process of working with Azami Global</p>
			<p className="member__of__azami">As a member of Azami Global, the process works as follows:</p>

			<ol className="member__of__azami__list" type="I">
				<li>
					<span>You send in an application with a list of countries and we provide you with an estimate.</span>
				</li>
				<li>
					<span>
						Once the estimate is approved, you send us formal instructions to file. We add you to our docket for return filings into (Fill in country). Our ratio of reciprocity for your
						country is (Fill in ratio) for PCT National phase entries and direct filings. Our other services, namely, trademarks, European validations, designs, and utility models will be
						evaluated on a case by case basis to determine reciprocity.
					</span>
				</li>
				<li>
					<span>
						We send you a list of associates for approval, if approved, we send instructions out to the designated associates. If they are not approved, we will provide you with other
						options.
					</span>
				</li>
				<li>
					<span>
						We send you all documents required for the national phase entry for signature by your clients, you return the documents and they are filed by the associates. Once the filing is
						completed, we send you all filing receipts where available and copies of translations wherever applicable. Original documents will be sent directly to the foreign associate if
						applicable.
					</span>
				</li>
				<li>
					<span>We put you in direct contact with the designated associates for prosecution.</span>
				</li>
				<li>
					<span>We send you new cases to be filed into (Fill in country) pending your conflict of interest check within approximately 90 days of your qualifying case with us.</span>
				</li>
				<li>
					<span>We invoice you for the one-time 250USD administrative fee for entering your firm into our system.</span>
				</li>
			</ol>

			<p>
				From a billing perspective, we will bill you for the filing and then the associates will bill you for the prosecution. For your first few applications filed through the network, we may
				require advanced payment so as to establish credit. Following that we would establish a 30 day billing period assuming all accounts are up to date. Regarding reciprocity, you should
				expect to receive return work within approximately 90 days. However, we will always try to exceed expectations and send back work to you as soon as possible. Azami Global does not have
				an annual membership fee nor do we require exclusivity. Requirements for joining the network are sending in an initial case for processing and the one-time $250 administrative fee for
				adding your firm to the system.
			</p>
			<p>(3) By working together with AZAMI, our members agree to adhere to the following terms and conditions:</p>

			<ol className="terms__and__conditions__list">
				<li>
					<span>
						Azami Global provides lists of associates in advance of every filing for your approval. The purpose of this approval process is to ensure that you feel comfortable with these
						firms before sending them instructions because you will be expected to keep the case with them throughout prosecution. Among Azami Global’s members, it is NOT accepted practice
						to transfer cases after filing unless the firm in question has exhibited negligence. Should an issue arise between you and another member firm, we ask that you contact us for
						assistance prior to taking any such action. Transferring cases without notifying us first and establishing just cause, is grounds for removal from our network. If at any point
						before or during the filing process, an end-client requests to dismiss a referred firm or to transfer the filing to a different foreign firm or attorney, you are required to
						bring the matter to AZAMI for resolution and, if necessary, a substitute referral will be made
					</span>
				</li>
				<li>
					<span>
						Insofar as AZAMI has introduced your firm to a fellow member firm, you are agreeing to coordinate all future filings with said member firms, through AZAMI for as long as you
						remain a member.
					</span>
				</li>
				<li>
					<span>
						In the event that another member firm or individual reaches out to your firm directly for filing, translating or processing work, we request that you remind the member that all
						cases of this nature should be processed through AZAMI. Repeat inquiries to your firm of this type should be reported to AZAMI.
					</span>
				</li>
				<li>
					<span>
						All pricing matters, estimates, and fees that are provided by AZAMI are to remain confidential in order to respect the integrity of the agreements we have with each of our
						members.
					</span>
				</li>
				<li>
					<span>
						AZAMI members are expected to provide reasonable, market value pricing for the work they complete for other AZAMI members, and to provide prompt payment when requested.
					</span>
				</li>
				<li>
					<span>
						Azami Global will add an additional 30% for services carried out at filing although not required at the time of filing including but not limited to services such as: Filing of
						Amendments, Request for Exam (when not required at time of filing) etc.
					</span>
				</li>
				<li>
					<span>Violating these terms will lead to termination of your membership in AZAMI, without warning or period of notice.</span>
				</li>
				<li>
					<span>
						We may also terminate our relationship with members for any reason connected to lack of responsiveness, quality or integrity or lack of timely payment of invoices both to Azami
						Global as well as to other member firms. Likewise, we may terminate the relationship in case of conflict of interest. In such cases, we reserve the right to refrain from taking
						further action for the member. Once our relationship with a member is concluded, all non-public information provided by that member up to that point will remain confidential,
						in accordance with accepted rules of professional conduct and privacy regulations. AZAMI will pay out all invoices to the member firm for services rendered through that point
						assuming the member does not have outstanding invoices owed to AZAMI.
					</span>
				</li>
				<li>
					<span>Azami Global reserves the right to use its members logos both in order to promote the use of that firm as well as for marketing purposes.</span>
				</li>
			</ol>

			<p>
				The member may terminate its membership with AZAMI at any time and for any reason. If the member wishes to terminate its membership, such member shall remain obligated to pay any fees
				owed to AZAMI for services rendered through that point.
			</p>
			<p>
				Our General Terms and Conditions for Services (found on our website www.azamiglobal.com and provided upon request) are incorporated by reference in their entirety into this Engagement
				Letter. Acceptance of this Engagement Letter constitutes member’s confirmation that he has read, and accepts such General Terms and Conditions for Services.
			</p>
			<p>
				Azami Global successfully coordinates the filing of thousands of applications each year with the assistance of hundreds of firms worldwide. We look forward to welcoming you to our
				network!
			</p>
		</div>
	);
};

export default LegalInfoEngagementLetter;
