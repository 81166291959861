import Ajax from './base';

const GetAll = (queryStringData) => {
	return Ajax.Request('/quotes', Ajax.GET, true, queryStringData);
};

const GetAllForUser = (queryStringData) => {
	return Ajax.Request('/quotes/me', Ajax.GET, true, queryStringData);
};

const GetOne = (id, queryStringData) => {
	return Ajax.Request(`/quotes/${id}`, Ajax.GET, true, queryStringData);
};

const GetLatest = (queryStringData) => {
	return Ajax.Request('/quotes/latest', Ajax.GET, false, queryStringData);
};

const UpdateByID = (id, payload) => {
	return Ajax.Request(`/quotes/${id}`, Ajax.PUT, true, payload);
};

const DeleteByID = (id) => {
	return Ajax.Request(`/quotes${id}`, Ajax.DELETE, true);
};

const Create = (payload) => {
	return Ajax.Request('/quotes', Ajax.POST, true, payload);
};

const CreateDraft = (payload) => {
	return Ajax.Request('/quotes/draft', Ajax.POST, true, payload);
};

const UpdateDraftByID = (id, payload) => {
	return Ajax.Request(`/quotes/${id}/draft`, Ajax.PUT, true, payload);
};

const GetByClientsFirm = (queryStringData, signal) => {
	return Ajax.Request('/quotes/clients', Ajax.GET, false, queryStringData, signal);
};

const UpdateCompletedRegionsById = (id, completedRegions) => {
	return Ajax.Request(`/quotes/${id}/completed-regions`, Ajax.PATCH, true, completedRegions);
};

const Hide = (id) => {
	return Ajax.Request(`/quotes/${id}/hidden`, Ajax.PATCH, true);
};

const SetExchangeRates = (id, payload) => {
	return Ajax.Request(`/quotes/${id}/exchange-rates`, Ajax.PATCH, true, payload);
};

export default {
	GetAll,
	GetAllForUser,
	GetOne,
	GetLatest,
	UpdateByID,
	DeleteByID,
	Create,
	GetByClientsFirm,
	UpdateCompletedRegionsById,
	Hide,
	CreateDraft,
	UpdateDraftByID,
	SetExchangeRates,
};
