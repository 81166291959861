import Modal from 'com/widgets/Modal';

const QuoteDuplicateApplicationNumberModal = (props) => {
	return (
		<Modal footerActions={props.duplicatesModalFooterActions} title="Duplicate Found">
			<div className="quote-details__duplicates-modal_content">
				<span>The application number entered already exists in {props.duplicateQuote.case_number}.</span>
				<span>Click Continue to be redirected to that quote.</span>
				<span>Click Cancel to edit the application number entered.</span>
			</div>
		</Modal>
	);
};

export default QuoteDuplicateApplicationNumberModal;
