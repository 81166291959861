import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { OwnerContext } from 'util/ownerContext';

import './style.css';

const Switch = (props) => {
	const userData = useSelector((state) => state.auth.user);
	const ownerData = useContext(OwnerContext);

	const checkRoles = (uData, roles) => {
		if (props.disabled) return props.disabled;
		if (uData.role.includes('admin')) return false;
		if (roles.includes('$user') && ownerData.userMatch) return false;
		if (roles.includes('$firm') && ownerData.firmMatch) return false;
		if (roles.includes('$clientOfFirm') && ownerData.clientOfFirmMatch) return false;
		if (roles.includes('$agentOfFirm') && ownerData.agentOfFirmMatch) return false;
		if (roles.length === 0) return false;
		let uRoles = uData.role.filter((r) => {
			return roles.includes(r);
		});
		return uRoles.length === 0;
	};

	let disabled = checkRoles(userData, props.forRoles) ? 'disabled' : '';
	let theme = props.theme ? props.theme : '';

	return (
		<label className={`form-switch ${disabled ? 'disabled' : ''} ${props.customClassName}`} onClick={props.onClickParent} title={props.title}>
			<input type="checkbox" onChange={props.onChange} checked={props.value} name={props.name} disabled={disabled} />
			<span className={`form-switch__pill ${theme}`}>
				<span className="form-switch__ball"></span>
			</span>
			<span className="form-switch__label">
				{props.img && <img src={props.img} alt="" />}
				{props.label}
			</span>
		</label>
	);
};

Switch.propTypes = {
	theme: PropTypes.oneOf(['', 'blurple']),
	name: PropTypes.string,
	value: PropTypes.any,
	label: PropTypes.string,
	onChange: PropTypes.func,
	onClickParent: PropTypes.func,
	disabled: PropTypes.bool,
	forRoles: PropTypes.array,
	customClassName: PropTypes.string,
	img: PropTypes.string,
};

Switch.defaultProps = {
	theme: '',
	name: '',
	label: '',
	onChange: () => {},
	onClickParent: () => {},
	disabled: false,
	forRoles: [],
	customClassName: '',
	img: '',
};

export default Switch;
