import React, { useState } from 'react';

import './style.css';

const Tooltip = (props) => {
	let timeout;
	const [active, setActive] = useState(false);

	const showTip = () => {
		timeout = setTimeout(() => {
			setActive(true);
		}, props.delay || 400);
	};

	const hideTip = () => {
		clearInterval(timeout);
		setActive(false);
	};

	return (
		<div className="tooltip-wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
			<span className={`comment ${props.customClassName || 'lightgrey'}`}></span>

			{active && <div className={`tooltip ${props.direction || 'top'}`}>{props.content}</div>}
		</div>
	);
};

export default Tooltip;
