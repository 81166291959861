import React, { useEffect, useState } from 'react';

import DataTable from 'com/widgets/DataTable';
import Button from 'com/ui/Button';
import Modal from 'com/widgets/Modal';
import Input from 'com/ui/Input';
import Select from 'com/ui/Select';
import { random } from 'services/strings';

const SystemSettingsReciprocityGroups = (props) => {
	const errorMessages = {
		required: 'Required',
	};
	const errorsInit = {
		groupName: '',
		groupRegions: '',
	};

	const [tableHeaders, setTableHeaders] = useState([]);
	const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
	const [showEditGroupModal, setShowEditGroupModal] = useState(false);
	const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
	const [groupName, setGroupName] = useState('');
	const [groupRegions, setGroupRegions] = useState([]);
	const [selectedRegion, setSelectedRegion] = useState('');
	const [editingGroup, setEditingGroup] = useState(null);
	const [deletingGroup, setDeletingGroup] = useState(null);
	const [errors, setErrors] = useState(errorsInit);

	useEffect(async () => {
		try {
			setTableHeaders([
				{ title: 'Group Name', field: 'name', type: 'string', sortable: false },
				{ title: 'Regions', field: 'regions', type: 'string', sortable: false },
				{ title: '', field: '', type: 'string', sortable: false },
			]);
		} catch (err) {
			console.log(err);
		}
	}, []);

	const openCreateGroupModal = () => {
		setGroupName('');
		setGroupRegions([]);
		setSelectedRegion('');
		setErrors(errorsInit);
		setShowCreateGroupModal(true);
	};

	const closeCreateGroupModal = () => {
		setShowCreateGroupModal(false);
	};

	const openEditGroupModal = (e) => {
		let { groupid } = e.target.dataset;
		let group = props.regionsGroups.find((g) => g.id == groupid);
		setEditingGroup(group);
		setGroupName(group.name);
		setGroupRegions(group.regions);
		setSelectedRegion('');
		setErrors(errorsInit);
		setShowEditGroupModal(true);
	};

	const closeEditGroupModal = () => {
		setShowEditGroupModal(false);
	};

	const openDeleteGroupModal = (e) => {
		let { groupid } = e.target.dataset;
		let group = props.regionsGroups.find((g) => g.id == groupid);
		setDeletingGroup(group);
		setShowDeleteGroupModal(true);
	};

	const closeDeleteGroupModal = () => {
		setShowDeleteGroupModal(false);
	};

	const handleGroupNameChange = (e) => {
		setErrors((prevState) => ({ ...prevState, groupName: errorsInit.groupName }));
		let { value } = e.target;
		setGroupName(value);
	};

	const handleAddRegion = (e) => {
		setErrors((prevState) => ({ ...prevState, groupRegions: errorsInit.groupRegions }));
		let { value } = e.target;
		if (groupRegions.find((r) => r.id == value)) {
			return;
		}
		setSelectedRegion(value);
		let region = props.regions.find((r) => r.id == value);
		setGroupRegions([...groupRegions, region]);
	};

	const handleRemoveRegion = (e) => {
		let { regionid } = e.target.dataset;
		setGroupRegions(groupRegions.filter((r) => r.id != regionid));
	};

	const validate = () => {
		let isValid = true;
		setErrors(errorsInit);
		if (groupName === '') {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, groupName: errorMessages.required }));
		}
		if (!groupRegions.length) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, groupRegions: errorMessages.required }));
		}
		return isValid;
	};

	const handleCreateGroup = () => {
		if (!validate()) {
			return false;
		}

		let newGroup = {
			id: random(8),
			name: groupName,
			regions: groupRegions,
			action: 'created',
		};
		let data = [...props.regionsGroups, newGroup];
		props.onChangeRegionsGroups(data);
		closeCreateGroupModal();
	};

	const handleEditGroup = () => {
		if (!validate()) {
			return false;
		}

		let data = props.regionsGroups.map((g) => {
			if (g.id == editingGroup.id) {
				let data = {
					...g,
					name: groupName,
					regions: groupRegions,
				};
				if (!editingGroup.action || editingGroup.action != 'created') {
					data['action'] = 'edited';
				}
				return data;
			}
			return g;
		});
		props.onChangeRegionsGroups(data);
		closeEditGroupModal();
	};

	const handleDeleteGroup = () => {
		if (!deletingGroup.action || deletingGroup.action != 'created') {
			props.setDeletedGroups((prevState) => [...prevState, deletingGroup]);
		}
		let data = props.regionsGroups.filter((g) => g.id != deletingGroup.id);
		props.onChangeRegionsGroups(data);
		closeDeleteGroupModal();
	};

	const columnSort = () => {};

	const createGroupModalFooterActions = [
		{ primary: false, label: 'Close', action: closeCreateGroupModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Create', action: handleCreateGroup, theme: 'azami-blue' },
	];

	const editGroupModalFooterActions = [
		{ primary: false, label: 'Close', action: closeEditGroupModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Apply', action: handleEditGroup, theme: 'azami-blue' },
	];

	const deleteGroupModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeleteGroupModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Delete', action: handleDeleteGroup, theme: 'azami-blue' },
	];

	return (
		<div className="system-settings-reciprocity-groups">
			<div className="system-settings-reciprocity-groups__header">
				<span className="system-settings-reciprocity-groups__header__title">Groups</span>
				<Button onClick={openCreateGroupModal}>Create Group</Button>
			</div>
			<div className="system-settings-reciprocity-groups__main-screen">
				<DataTable fixedHeader={false} columns={tableHeaders} onColumnSort={columnSort}>
					{props.regionsGroups.map((group) => {
						return (
							<tr key={random(4)}>
								<DataTable.Cell>{group.name}</DataTable.Cell>
								<DataTable.TagsCell>{group.regions.map((r) => r.name)}</DataTable.TagsCell>
								<DataTable.ActionsCell>
									<button className="groups-table__action-edit-btn" onClick={openEditGroupModal} data-groupid={group.id} />
									<button className="groups-table__action-delete-btn" onClick={openDeleteGroupModal} data-groupid={group.id} />
								</DataTable.ActionsCell>
							</tr>
						);
					})}
				</DataTable>
			</div>
			{showCreateGroupModal && (
				<Modal title="Create Group" closeHandler={closeCreateGroupModal} footerActions={createGroupModalFooterActions}>
					<CreateGroupModal
						regions={props.regionsSelectOptions}
						groupName={groupName}
						groupRegions={groupRegions}
						selectedRegion={selectedRegion}
						errors={errors}
						handleGroupNameChange={handleGroupNameChange}
						handleAddRegion={handleAddRegion}
						handleRemoveRegion={handleRemoveRegion}
					/>
				</Modal>
			)}
			{showEditGroupModal && (
				<Modal title="Edit Group" closeHandler={closeEditGroupModal} footerActions={editGroupModalFooterActions}>
					<CreateGroupModal
						regions={props.regionsSelectOptions}
						groupName={groupName}
						groupRegions={groupRegions}
						selectedRegion={selectedRegion}
						errors={errors}
						handleGroupNameChange={handleGroupNameChange}
						handleAddRegion={handleAddRegion}
						handleRemoveRegion={handleRemoveRegion}
					/>
				</Modal>
			)}
			{showDeleteGroupModal && (
				<Modal title="Delete group" closeHandler={closeDeleteGroupModal} footerActions={deleteGroupModalFooterActions}>
					Are you sure that you want to delete <strong>{deletingGroup.name}</strong>?
				</Modal>
			)}
		</div>
	);
};

const CreateGroupModal = (props) => {
	return (
		<>
			<Input name="name" label="Group Name" onChange={props.handleGroupNameChange} value={props.groupName} error={props.errors.groupName} />
			<Select name="regions" options={props.regions} label="Regions" onChange={props.handleAddRegion} value={props.selectedRegion} error={props.errors.groupRegions} />
			<div className="selected-regions-container">
				{props.groupRegions.map((r) => (
					<div key={r.id} className="selected-region-item">
						<span>{r.name}</span>
						<span className="selected-region-remove-btn" onClick={props.handleRemoveRegion} data-regionid={r.id}>
							&times;
						</span>
					</div>
				))}
			</div>
		</>
	);
};

export default SystemSettingsReciprocityGroups;
