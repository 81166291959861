import Ajax from './base';

const getUploadersByCaseId = (id, queryStringData) => {
	return Ajax.Request(`/documents/${id}/uploaders`, Ajax.GET, true, queryStringData);
};

const getByCase = (id, queryStringData) => {
	return Ajax.Request(`/documents/case/${id}`, Ajax.GET, true, queryStringData);
};

const UpdateByID = (id, payload) => {
	return Ajax.Request(`/documents/${id}`, Ajax.PUT, true, payload);
};

const deleteByID = (id) => {
	return Ajax.Request(`/documents/${id}`, Ajax.DELETE, true);
};

export default {
	getUploadersByCaseId,
	getByCase,
	UpdateByID,
	deleteByID,
};
