import { object, string, ref, boolean } from 'yup';
import { messages } from 'util/validations';

export const ChangePasswordSchema = object().shape({
	password: string().required(messages.required.password),
	password_repeat: string()
		.oneOf([ref('password'), null], messages.required.password_repeat)
		.required(messages.required.password),
	tos_agreement: boolean().required(messages.required.tos_agreement).oneOf([true], messages.required.tos_agreement),
});
