import Ajax from './base';

const GetAll = (queryStringData) => {
	return Ajax.Request('/settings/pricing-levels', Ajax.GET, false, queryStringData);
};

const GetOneByID = (pid) => {
	return Ajax.Request(`/settings/pricing-levels/${pid}`, Ajax.GET, true);
};

const DeleteByID = (pid) => {
	return Ajax.Request(`/settings/pricing-levels/${pid}`, Ajax.DELETE, true);
};

const Create = (payload) => {
	return Ajax.Request('/settings/pricing-levels', Ajax.POST, false, payload);
};

const UpdateByID = (pid, payload) => {
	return Ajax.Request(`/settings/pricing-levels/${pid}`, Ajax.PUT, true, payload);
};

export default {
	GetAll,
	DeleteByID,
	GetOneByID,
	Create,
	UpdateByID,
};
