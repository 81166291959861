import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router';

const AppZones = (props) => {
	let auth = useSelector((state) => state.auth);
	let matchFound = false;
	const childrenWithProps = React.Children.map(props.children, (child) => {
		if (!matchFound && React.isValidElement(child)) {
			if (child.type.displayName === 'Private' && auth.loggedIn) {
				matchFound = true;
				return (
					<Switch>
						{React.Children.map(child.props.children, (c) => {
							return React.cloneElement(c, { path: c.props.route.path, exact: c.props.exact });
						})}
					</Switch>
				);
			} else if (child.type.displayName === 'Public' && !auth.loggedIn) {
				matchFound = true;
				return (
					<Switch>
						{React.Children.map(child.props.children, (c) => {
							return React.cloneElement(c, { path: c.props.route.path, exact: c.props.exact });
						})}
					</Switch>
				);
			}
		}
	});
	return childrenWithProps;
};

const Private = (props) => props.children;
Private.displayName = 'Private';

const Public = (props) => props.children;
Public.displayName = 'Public';

export { AppZones, Public, Private };
