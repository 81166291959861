//vendor imports
import React from 'react';
import PropTypes from 'prop-types';
import AppLink from 'com/util/AppLink';
//icons
import ICON_NO_DATA from 'assets/images/icons/ico_no_data.svg';
//styles
import './style.css';

const StatTableCard = (props) => {
	return (
		<div className={`stat-table-card ${props.customClassName}`}>
			<div className="stat-table-card__header">
				<div className="stat-table-card__header-left">
					<img className="stat-table-card__header-icon" src={props.icon} alt="" />
					<span className="stat-table-card__header-left__title">{props.title}</span>
					{props.amount > 0 ?
						<span className="stat-table-card__header-left__amount">{props.amount}</span>
					:	null}
				</div>
				<AppLink to={props.link} className="stat-table-card__header-link">
					View all
				</AppLink>
			</div>
			<div className="stat-table-card__main">{props.children}</div>
		</div>
	);
};

StatTableCard.defaultProps = {
	amount: 0,
	icon: '',
	link: '',
	title: '',
};

StatTableCard.propTypes = {
	amount: PropTypes.number,
	icon: PropTypes.string,
	link: PropTypes.string,
	title: PropTypes.string,
};

StatTableCard.NoData = (props) => {
	return (
		<table className="stat-table-card__main">
			<tbody>
				<tr>
					<td colSpan={props.colspan} className="no-data">
						<img className="no-data__icon" src={ICON_NO_DATA} alt="" />
						<span className="no-data__title">{props.noData_title}</span>
						<span className="no-data__subtitle">{props.noData_subtitle}</span>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

StatTableCard.NoData.defaultProps = {
	colspan: 0,
	noData_title: '',
	noData_subtitle: '',
};

StatTableCard.NoData.propTypes = {
	colspan: PropTypes.number,
	noData_title: PropTypes.string,
	noData_subtitle: PropTypes.string,
};

export default StatTableCard;
