import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';

import AppTemplate from 'com/templates/ApplicationTemplate';

import Button from 'com/ui/Button';
import Input from 'com/ui/Input';
import Select from 'com/ui/Select';

import { PAGE_TITLE } from 'data/constants';

import Users from 'services/rest/users';
import Firm from 'services/rest/firms';
import Settings from 'services/rest/settings';

import useTitle from 'services/hooks/useTitle';

import ErrorMessage from 'com/ui/ErrorMessage';

import { UserAddSchema } from 'services/validators/userAdd';
import { getError } from 'util/errors';
import { titles } from 'data/lists';

import './style.css';

const UserAdd = (props) => {
	useTitle(PAGE_TITLE.USER_CREATE);

	const [spinner, setSpinner] = useState(false);
	const [fetchedFirmAccounts, setFetchedFirmAccounts] = useState([{ value: '', label: '' }]);
	const [firms, setFirms] = useState([]);
	const [countries, setCountries] = useState([]);
	const history = useHistory();
	const formRef = useRef();

	const initialState = {
		first_name: '',
		last_name: '',
		title: 'mr',
		email: '',
		phone: '',
		country: 'usa',
		firm_code: '',
		firm_account_id: '',
		firm_accounts: [],
	};

	const random = (length) => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};

	const handleSubmit = async (fields, { setStatus }) => {
		const data = {
			title: fields.title,
			first_name: fields.first_name,
			last_name: fields.last_name,
			email: fields.email,
			phone: fields.phone,
			country: fields.country,
			firm_code: fields.firm_code,
			firm_account_id: fields.firm_account_id,
			password: random(22), // autogenerated, as these users are not active customers
		};

		try {
			setSpinner(true);
			await Users.Create(data);
			setSpinner(false);
			history.push('/user-management');
		} catch (err) {
			setStatus(getError(err.code));
			setSpinner(false);
		}
	};

	const submit = () => {
		if (formRef.current) {
			formRef.current.handleSubmit();
		}
	};

	const onChangeFirm = async (e, cb) => {
		cb(e);
		if (!e.target.value) return setFetchedFirmAccounts([]);
		try {
			let { data } = await Firm.GetAll({ code: e.target.value });
			let f = await Firm.GetOneByID(data[0].id);
			setFetchedFirmAccounts([
				{ label: '', value: '' },
				...f.accounts.map((fa) => {
					return { label: fa.name, value: fa.id };
				}),
			]);
		} catch (error) {
			console.log('error', error);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const [firmsData, countries] = await Promise.all([Firm.GetAllRaw(), Settings.GetRegions({ is_country: 1 })]);

				setFirms([
					{ label: '', value: '' },
					...firmsData.map((f) => {
						return { label: `${f.name} (${f.code})`, value: f.code };
					}),
				]);

				setCountries([
					{ label: '', value: '' },
					...countries.map((c) => {
						return { label: c.name, value: c.code };
					}),
				]);
			} catch (error) {
				console.log('error', error);
			}
		})();
	}, []);

	return (
		<AppTemplate title="User Details" headerIcon="user_management">
			<AppTemplate.Sidebar>
				<div className="user-sidebar"></div>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<div className="main-screen-section white full-height no-padding user-add-content">
					<header className="user-add-content__header"></header>
					<div className="user-add-content__body">
						<Formik initialValues={initialState} enableReinitialize={true} validationSchema={UserAddSchema} onSubmit={handleSubmit} validateOnChange={false} innerRef={formRef}>
							{({ values, handleChange, validateField, errors, status, setFieldValue }) => {
								const validate = (e) => validateField(e.target.name);

								return (
									<Form>
										<div className="register-container__fields">
											<div className="register-container__input">
												<Select
													name="title"
													value={values.title}
													onChange={handleChange}
													error={errors.title}
													label="Title"
													placeholder="Mr."
													options={titles}
													onBlur={validate}
												/>
											</div>
											<div className="register-container__input">
												<Input
													name="first_name"
													value={values.first_name}
													onChange={handleChange}
													error={errors.first_name}
													label="First Name"
													placeholder="John"
													type="text"
													onBlur={validate}
												/>
											</div>
											<div className="register-container__input">
												<Input
													name="last_name"
													value={values.last_name}
													onChange={handleChange}
													error={errors.last_name}
													label="Last Name"
													placeholder="Smith"
													type="text"
													onBlur={validate}
												/>
											</div>
											<div className="register-container__input">
												<Input
													name="email"
													value={values.email}
													onChange={handleChange}
													error={errors.email}
													label="Email"
													placeholder="email@domain.com"
													type="text"
													onBlur={validate}
												/>
											</div>
											<div className="register-container__input">
												<Input
													name="phone"
													value={values.phone}
													onChange={handleChange}
													error={errors.phone}
													label="Phone"
													placeholder="555 1234"
													type="text"
													onBlur={validate}
												/>
											</div>
											<div className="register-container__input">
												<Select
													name="country"
													value={values.country}
													onChange={handleChange}
													label="Country"
													placeholder="United States"
													options={countries}
													onBlur={validate}
												/>
											</div>
											<div className="register-container__input">
												<Select
													name="firm_code"
													value={values.firm_code}
													onChange={(e) => onChangeFirm(e, handleChange)}
													error={errors.firm_code}
													label="Firm name"
													options={firms}
													onBlur={validate}
												/>
											</div>
											<div className="register-container__input">
												<Select
													name="firm_account_id"
													value={values.firm_account_id}
													onChange={handleChange}
													error={errors.firm_account_id}
													label="Firm Account"
													placeholder=""
													options={fetchedFirmAccounts}
													onBlur={validate}
												/>
											</div>
										</div>
										{status && <ErrorMessage error={status} />}
									</Form>
								);
							}}
						</Formik>
					</div>
					<footer className="firm-add-content__footer">
						<Button onClick={submit} type="submit" showSpinner={spinner}>
							Submit
						</Button>
					</footer>
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default UserAdd;
