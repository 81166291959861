//inital state
const initState = [];

//constants
const SET_BANNERS = 'SET_BANNERS';
const HIDE_BANNER = 'HIDE_BANNER';

//actions
export const setBanners = (data) => {
	return {
		type: SET_BANNERS,
		payload: data,
	};
};

export const hideBanner = (id) => {
	return {
		type: HIDE_BANNER,
		payload: id,
	};
};

//reducer
const reducer = (state = initState, action) => {
	switch (action.type) {
		case SET_BANNERS:
			return [...action.payload];
		case HIDE_BANNER:
			return [...state.filter((b) => b.id !== action.payload)];
		default:
			return state;
	}
};

export default reducer;
