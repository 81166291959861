import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import Button from 'com/ui/Button';
import Textarea from 'com/ui/Textarea';
import Select from 'com/ui/Select';
import Editor from 'com/ui/Editor';

import useNotification from 'services/hooks/use_notification';
import Settings from 'services/rest/settings';
import EmailTemplates from 'services/rest/email-templates';
import { RegionalTemplateSchema } from 'services/validators/regionalTemplate';

import './style.css';

const SystemSettingsRegionalTemplatesDetails = () => {
	const user = useSelector((state) => state.auth.user);

	const dataInit = {
		service_id: '',
		region_id: '',
		email_template_id: '',
		description: '',
		created_by: '',
		content: '',
	};

	const errorsInit = {
		service_id: '',
		region_id: '',
		email_template_id: '',
	};

	const sendNotification = useNotification();
	const { id } = useParams();
	const location = useLocation();
	const [action, setAction] = useState('');
	const [data, setData] = useState(dataInit);
	const [services, setServices] = useState([]);
	const [regions, setRegions] = useState([]);
	const [emailTemplates, setEmailTemplates] = useState([]);
	const [spinner, setSpinner] = useState(false);
	const history = useHistory();
	const [errors, setErrors] = useState(errorsInit);

	useEffect(() => {
		(async () => {
			try {
				let [servicesData, regionsData] = await Promise.all([Settings.GetServices(), Settings.GetRegions()]);

				setServices([{ label: 'Select Service', value: '' }, ...servicesData.map((s) => ({ label: s.name, value: s.id }))]);

				setRegions([
					{ label: 'Select Region', value: '' },
					...regionsData.map((c) => {
						return { ...c, label: c.name, value: c.id };
					}),
				]);

				setAction(location.pathname.split('/').at(-1));
				if (!!id) {
					let templateData = await EmailTemplates.GetOneRegionalByID(id);
					setData(templateData);
				}
			} catch (err) {
				console.log(err);
				sendNotification({ type: 'error', title: 'Something went wrong with loading the data.' });
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (!data.service_id) return;

			try {
				let emailTemplatesData = await EmailTemplates.GetAll({ service_id: data.service_id });
				setEmailTemplates([
					{ label: 'All', value: '' },
					...emailTemplatesData?.data.map((c) => {
						return { ...c, label: c.name, value: c.id };
					}),
				]);
			} catch (err) {
				console.log(err);
				sendNotification({ type: 'error', title: 'Something went wrong with loading the data.' });
			}
		})();
	}, [data.service_id]);

	const fieldUpdate = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	};

	const editorUpdate = (state) => {
		setData({
			...data,
			content: state,
		});
	};

	const submit = async () => {
		let validation = RegionalTemplateSchema(data);
		setErrors(validation.errors);
		if (!validation.isValid) {
			sendNotification({ type: 'error', title: 'Regional Template Submition Failed. Fix errors above.' });
			return;
		}

		try {
			setSpinner(true);
			if (action === 'add' || action === 'duplicate') {
				delete data.id;
				await EmailTemplates.CreateRegional({ ...data, created_by: user.uid });
			} else {
				await EmailTemplates.UpdateRegional(id, { ...data, created_by: user.uid });
			}
			sendNotification({ type: 'success', title: 'Regional Template Saved.' });
			history.push('/system-settings/regional-templates');
		} catch (err) {
			sendNotification({ type: 'error', title: 'Regional Template Submition Failed' });
			console.log(err);
		} finally {
			setSpinner(false);
		}
	};

	const cancel = () => {
		history.goBack();
	};

	return (
		<div className="main-screen-section white full-height no-padding system-details-content settings-regional-templates">
			<div className="system-details-content__body">
				<span className="system-details-content__body__title">{`${action.charAt(0).toUpperCase() + action.slice(1)} Template`}</span>
				<div className="settings-regional-templates-template-flex-row">
					<div className="settings-regional-templates-template-services-input-container">
						<Select label="Service" options={services} onChange={fieldUpdate} name="service_id" value={data.service_id} error={errors.service_id} />
					</div>
					<div className="settings-regional-templates-template-regions-input-container">
						<Select label="Region" options={regions} onChange={fieldUpdate} name="region_id" value={data.region_id} error={errors.region_id} />
					</div>
				</div>
				<div className="settings-regional-templates-template-emails-input-container">
					<Select label="Apply to Template" options={emailTemplates} onChange={fieldUpdate} name="email_template_id" value={data.email_template_id} error={errors.email_template_id} />
				</div>
				<div className="settings-regional-templates-template-description-input-container">
					<Textarea label="Description" customClassName="text-input" name="description" value={data.description} onChange={fieldUpdate} />
				</div>
				<div className="settings-regional-templates-template-content-input-container">
					<Editor label="Content" name="contnet" theme="snow" value={data.content} onChange={editorUpdate} />
				</div>
			</div>
			<footer className="system-details-content__footer">
				<Button onClick={cancel} theme={'azami-light-violet'}>
					Cancel
				</Button>
				<Button onClick={submit} showSpinner={spinner}>
					Save
				</Button>
			</footer>
		</div>
	);
};
export default SystemSettingsRegionalTemplatesDetails;
