import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'com/ui/Loader';

import './style.css';
import SystemSettingsFirmFamilies from 'com/pages/SystemSettingsFirmFamilies';

const LoaderOverlay = (props) => {
	return props.showLoader ?
			<div className="loader-overlay">
				<div className="loader-overlay__positioner">
					<Loader {...props} />
				</div>
			</div>
		:	null;
};

LoaderOverlay.propTypes = {
	theme: PropTypes.oneOf(['light', 'darkblue', 'purple']),
	size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
	showLoader: PropTypes.bool,
};

LoaderOverlay.defaultProps = {
	theme: 'purple',
	size: 'medium',
	showLoader: false,
};

export default LoaderOverlay;
