import Ajax from './base';

const GetAll = (queryStringData) => {
	return Ajax.Request('/email-templates', Ajax.GET, true, queryStringData);
};

const Create = (data) => {
	return Ajax.Request('/email-templates', Ajax.POST, true, data);
};

const Update = (id, data) => {
	return Ajax.Request(`/email-templates/${id}`, Ajax.PUT, true, data);
};

const GetOneByID = (id) => {
	return Ajax.Request(`/email-templates/${id}`, Ajax.GET, true);
};

const Delete = (id) => {
	return Ajax.Request(`/email-templates/${id}`, Ajax.DELETE, true);
};

const GetAllRegional = (queryStringData) => {
	return Ajax.Request('/email-templates/regional', Ajax.GET, true, queryStringData);
};

const CreateRegional = (data) => {
	return Ajax.Request('/email-templates/regional', Ajax.POST, true, data);
};

const UpdateRegional = (id, data) => {
	return Ajax.Request(`/email-templates/regional/${id}`, Ajax.PUT, true, data);
};

const GetOneRegionalByID = (id) => {
	return Ajax.Request(`/email-templates/regional/${id}`, Ajax.GET, true);
};

const DeleteRegional = (id) => {
	return Ajax.Request(`/email-templates/regional/${id}`, Ajax.DELETE, true);
};

export default {
	GetAll,
	Create,
	Update,
	GetOneByID,
	Delete,
	GetAllRegional,
	CreateRegional,
	UpdateRegional,
	GetOneRegionalByID,
	DeleteRegional,
};
