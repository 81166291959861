import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { OwnerContext } from 'util/ownerContext';
import { ENVIRONMENTS } from 'data/constants';

export const RoleBox = (props) => {
	const userData = useSelector((state) => state.auth.user);
	const ownerData = useContext(OwnerContext);

	let allowed = false;

	const [role, env] = props.roles.flatMap((role) => (role.includes(':') && userData.role.includes(role.split(':')[0]) ? role.split(':') : []));

	if (userData.role) {
		for (let r of userData.role) {
			allowed =
				props.roles.includes(r) ||
				(props.roles.includes('$user') && ownerData.userMatch) ||
				(props.roles.includes('$firm') && ownerData.firmMatch) ||
				(role && env && r === role && process.env.REACT_APP_ENVIRONMENT === ENVIRONMENTS[env.toUpperCase()]) ||
				(props.roles.includes('$saasUser') && userData.role.includes('saas_user') && ownerData.firmMatch) ||
				(props.roles.includes('$clientOfFirm') && ownerData.clientOfFirmMatch) ||
				(props.roles.includes('$agentOfFirm') && ownerData.agentOfFirmMatch);
			if (allowed) break;
		}
	}
	return allowed ? <>{props.children}</> : null;
};

const RoleNoPermissions = () => {
	return <h1 style={{ textAlign: 'center', color: 'red' }}>You have no permissions to view this info!</h1>;
};

RoleBox.RoleBoxNoPermissons = RoleNoPermissions;
