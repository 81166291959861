import React from 'react';

import ExpertiseList from 'com/widgets/ExpertiseList';
import RegionsList from 'com/widgets/RegionsList';
import { RoleBox } from 'com/util/RoleBox';

const FirmDetailsExpertiseRegions = (props) => {
	const extractTechnologyIDs = (technologies) => {
		if (technologies) {
			return technologies.map((e) => e.technology_id);
		}
		return [];
	};

	return (
		<div className="user-details-expertise">
			<RoleBox roles={['$firm', 'admin', 'sales', 'sales_manager', '$clientOfFirm']}>
				<ExpertiseList values={extractTechnologyIDs(props.technologies)} readOnly={true} />
				<RegionsList values={props.regions} onChange={props.onChangeRegions} title="Regions where the firm is licensed to file" editable={true} regionsList="all" name={props.firmName} />
			</RoleBox>
		</div>
	);
};

export default FirmDetailsExpertiseRegions;
