// Minimum token expiration time before renewal is 10 minutes
const MIN_TOKEN_EXPIRATION_TIME = 600;
// Interval time for token expiration check
const RENEW_TOKEN_CHECK_INTERVAL_TIME = 60 * 1000;

/**
 * Parse JWT token
 */
const parseJWT = (token) => {
	let base64Url = token.split('.')[1];
	let base64 = base64Url.replace('-', '+').replace('_', '/');
	return JSON.parse(window.atob(base64));
};

/**
 * Check token expiration time.
 * 0 - token is expired
 * 1 - token is valid
 * 2 - token is less then MIN_TOKEN_EXPIRATION_TIME before expiring
 */
const checkTokenExpirationTime = (token_exp) => {
	let currentTime = new Date().getTime();
	let timeDiff = token_exp - currentTime;
	if (timeDiff > MIN_TOKEN_EXPIRATION_TIME) {
		return 1;
	} else if (timeDiff < MIN_TOKEN_EXPIRATION_TIME && timeDiff > 0) {
		return 2;
	} else {
		return 0;
	}
};

/**
 * Renew existing token
 */
const renewToken = (token_exp) => {
	// TODO implement method
};

export default {
	MIN_TOKEN_EXPIRATION_TIME,
	RENEW_TOKEN_CHECK_INTERVAL_TIME,
	parseJWT,
	checkTokenExpirationTime,
	renewToken,
};
