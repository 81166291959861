const init = {
	loggedIn: false,
	user: { role: [], agentOfFirms: [], clientOfFirms: [] },
};

// constants
const SET_LOGGED_IN = 'SET_LOGGED_IN';
const SET_USER_DATA = 'SET_USER_DATA';
const CLEAR_USER_DATA = 'CLEAR_USER_DATA';

// actions
export const setUserData = (data) => {
	return {
		type: SET_USER_DATA,
		payload: data,
	};
};

export const setUserDataAsync = (dispatch, data) => {
	dispatch({
		type: SET_USER_DATA,
		payload: data,
	});
	return Promise.resolve();
};

export const setLoggedIn = (data) => {
	return {
		type: SET_LOGGED_IN,
		payload: data,
	};
};

export const clearUserData = () => {
	return {
		type: CLEAR_USER_DATA,
		payload: init,
	};
};

// reducer
const reducer = (state = init, action) => {
	switch (action.type) {
		case SET_LOGGED_IN:
			return { ...state, loggedIn: action.payload };
		case SET_USER_DATA:
			return { ...state, user: action.payload };
		case CLEAR_USER_DATA:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export default reducer;
