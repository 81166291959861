import Ajax from './base';

const Update = (data) => {
	return Ajax.Request('/preferences', Ajax.PUT, false, data);
};

const GetSystemRecords = () => {
	return Ajax.Request(`/preferences/system`, Ajax.GET, true);
};

const GetSystemRecordsByGroup = (group) => {
	return Ajax.Request(`/preferences/system/${group}`, Ajax.GET, true);
};

const GetSystemRecordsByKey = (group, key) => {
	return Ajax.Request(`/preferences/system/${group}/${key}`, Ajax.GET, true);
};

const GetFirmRecordsById = (id) => {
	return Ajax.Request(`/preferences/firm/${id}`, Ajax.GET, true);
};

const GetFirmRecordsByGroup = (id, group) => {
	return Ajax.Request(`/preferences/firm/${id}/${group}`, Ajax.GET, true);
};

const GetFirmRecordsByKey = (id, group, key) => {
	return Ajax.Request(`/preferences/firm/${id}/${group}/${key}`, Ajax.GET, true);
};

const GetUserRecordsById = (id) => {
	return Ajax.Request(`/preferences/user/${id}`, Ajax.GET, true);
};

const GetUserRecordsByGroup = (id, group) => {
	return Ajax.Request(`/preferences/user/${id}/${group}`, Ajax.GET, true);
};

const GetUserRecordsByKey = (id, group, key) => {
	return Ajax.Request(`/preferences/user/${id}/${group}/${key}`, Ajax.GET, true);
};

export default {
	Update,
	GetSystemRecords,
	GetSystemRecordsByGroup,
	GetSystemRecordsByKey,
	GetFirmRecordsById,
	GetFirmRecordsByGroup,
	GetFirmRecordsByKey,
	GetUserRecordsById,
	GetUserRecordsByGroup,
	GetUserRecordsByKey,
};
