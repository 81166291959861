import React from 'react';
// ui comp
import Input from 'com/ui/Input';
// styles
import './style.css';

const CaseSettingsAssociate = () => {
	return (
		<div className="case-settings-associate">
			<div className="case-settings-associate__input-group">
				<Input />
				<Input />
			</div>
		</div>
	);
};

export default CaseSettingsAssociate;
