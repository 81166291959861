import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AssignmentOverride from 'services/rest/assignment_override';

import Select from 'com/ui/Select';
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import Textarea from 'com/ui/Textarea';

import './style.css';

const AssignmentOverrideRequestsForm = (props) => {
	const { id } = useParams();
	const isCreateMode = !id;
	const user = useSelector((state) => state.auth.user);

	const formDataInit = {
		requested_by: user.uid,
		status: props.status.PENDING,
		priority_id: '',
		firm_id: '',
		user_id: '',
		region_id: '',
		requested_cases: '',
		request_comment: '',
	};

	const errorMessages = {
		required: 'Required',
		negative: 'Enter a positive value',
	};

	const errorsInit = {
		priority_id: '',
		firm_id: '',
		user_id: '',
		region_id: '',
		requested_cases: '',
		request_comment: '',
	};

	const [formData, setFormData] = useState(formDataInit);
	const [errors, setErrors] = useState(errorsInit);
	const [filteredUsers, setFilteredUsers] = useState();

	useEffect(async () => {
		if (!isCreateMode) {
			let aor = await AssignmentOverride.GetOneRequest(id);
			setFormData(aor);
			setFilteredUsersList(aor.firm_id);
		}
	}, [props]);

	const setFilteredUsersList = (firm_id) => {
		setFilteredUsers([{ label: '', value: '' }, ...props.users.filter((u) => u.firm_id === firm_id)]);
	};

	const fieldChange = (e) => {
		const { name, value } = e.target;

		setFormData({
			...formData,
			[name]: value,
		});

		if (name === 'firm_id') {
			setFilteredUsersList(value);
		}
	};

	const validate = () => {
		let isValid = true;
		setErrors(errorsInit);

		if (!formData.priority_id) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, priority_id: errorMessages.required }));
		}
		if (!formData.firm_id) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, firm_id: errorMessages.required }));
		}
		if (!formData.region_id) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, region_id: errorMessages.required }));
		}
		if (!formData.requested_cases) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, requested_cases: errorMessages.required }));
		}
		if (formData.requested_cases < 0) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, requested_cases: errorMessages.negative }));
		}

		return isValid;
	};

	const cancelForm = () => {
		props.history.push('/tools/assignment-override-requests');
	};

	const submitForm = () => {
		return isCreateMode ? applyNewRequest() : applyEditRequest();
	};

	const applyNewRequest = async () => {
		if (!validate()) {
			return false;
		}
		props.onChange(formData, 'add');
		props.history.push('/tools/assignment-override-requests');
	};

	const applyEditRequest = async () => {
		if (!validate()) {
			return false;
		}
		props.onChange(formData, 'edit');
		props.history.push('/tools/assignment-override-requests');
	};

	return (
		<div className="main-screen-section white full-height no-padding">
			<div className="assignment-override-requests-main white">
				<div className="assignment-override-requests__header">
					<span className="assignment-override-requests__header__title">{isCreateMode ? 'New Request' : 'Edit Request'}</span>
				</div>
				<div className="assignment-override-requests__body">
					<form>
						<Select name="priority_id" label="Priority" options={props.priorities} value={formData.priority_id} onChange={fieldChange} error={errors.priority_id} />
						<Select name="firm_id" label="Firm Name" options={props.firms} value={formData.firm_id} onChange={fieldChange} error={errors.firm_id} />
						<Select name="user_id" label="Firm Contact" options={filteredUsers} value={formData.user_id} onChange={fieldChange} />
						<Select name="region_id" label="Region" options={props.regions} value={formData.region_id} onChange={fieldChange} error={errors.region_id} />
						<Input type="number" name="requested_cases" label="Requested Cases" value={formData.requested_cases} onChange={fieldChange} error={errors.requested_cases} />
						<Textarea name="request_comment" label="Request Comment" value={formData.request_comment} onChange={fieldChange} />
					</form>
				</div>
			</div>
			<footer className="assignment-override-requests__footer">
				<Button onClick={cancelForm} className="ghost">
					Cancel
				</Button>
				<Button onClick={submitForm}>Apply</Button>
			</footer>
		</div>
	);
};

export default AssignmentOverrideRequestsForm;
