import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { OwnerContext } from 'util/ownerContext';
import ICON_INFO_MAGENTA from 'assets/images/icons/ico_toast_info_violet.svg';

import './style.css';

const Select = (props) => {
	const errorBorder = props.error ? 'form-input__error-border' : '';
	const userData = useSelector((state) => state.auth.user);
	const ownerData = useContext(OwnerContext);

	const getOptionValue = (option) => {
		const defaultValue = option.value || '';
		if (props.getOptionValue) {
			return props.getOptionValue(option) || defaultValue;
		}

		return defaultValue;
	};

	const getOptionLabel = (option) => {
		const defaultLabel = option.label || '';
		if (props.getOptionLabel) {
			return props.getOptionLabel(option) || defaultLabel;
		}

		return defaultLabel;
	};

	const checkRoles = (uData, roles) => {
		if (props.disabled) return props.disabled;
		if (uData.role.includes('admin')) return false;
		if (roles.includes('$user') && ownerData.userMatch) return false;
		if (roles.includes('$firm') && ownerData.firmMatch) return false;
		if (roles.includes('$clientOfFirm') && ownerData.clientOfFirmMatch) return false;
		if (roles.includes('$agentOfFirm') && ownerData.agentOfFirmMatch) return false;
		if (roles.length === 0) return false;
		let uRoles = uData.role.filter((r) => {
			return roles.includes(r);
		});
		return uRoles.length === 0;
	};

	let disabled = checkRoles(userData, props.forRoles) ? true : '';

	return (
		<label className={`form-select ${props.customClassName ? props.customClassName : ''}`} onMouseDown={props.onMouseDown} onClick={props.onClick}>
			<span className={`form-select__label ${props.theme}`}>
				{props.label}
				{props.required ?
					<span className="form-select__label__required" title={'This field is required.'}>
						&#42;
					</span>
				:	null}
			</span>
			{props.infoHint ?
				<span className={`form-select__info-hint ${props.showInfoHint ? 'show-info-hint' : ''}`}>
					<img src={ICON_INFO_MAGENTA} alt="" />
					<span>{props.infoHint}</span>
				</span>
			:	null}
			<select
				disabled={disabled}
				className={`form-select__input ${props.theme} ${errorBorder}`}
				placeholder={props.placeholder}
				onChange={props.onChange}
				onBlur={props.onBlur}
				value={String(props.value)}
				name={props.name}
			>
				{props.options &&
					props.options.map((o, i) => {
						return (
							<option value={getOptionValue(o)} key={i}>
								{getOptionLabel(o)}
							</option>
						);
					})}
			</select>
			{props.error && <span className="form-select__error">{props.error}</span>}
			{!props.error && props.previewValue && (
				<span className="form-select__preview" title={props.previewToolTip}>
					{props.previewValue}
				</span>
			)}
		</label>
	);
};

Select.propTypes = {
	disabled: PropTypes.bool,
	customClassName: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
	error: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	theme: PropTypes.oneOf(['light', 'dark', 'mid']),
	forRoles: PropTypes.array,
	previewValue: PropTypes.any,
	previewToolTip: PropTypes.string,
	required: PropTypes.bool,
	infoHint: PropTypes.string,
	showInfoHint: PropTypes.bool,
	onMouseDown: PropTypes.func,
	onClick: PropTypes.func,
};

Select.defaultProps = {
	disabled: false,
	customClassName: '',
	label: '',
	placeholder: '',
	name: '',
	options: [],
	error: '',
	previewToolTip: '',
	onChange: () => {},
	onBlur: () => {},
	theme: 'light',
	forRoles: [],
	required: false,
	infoHint: '',
	showInfoHint: false,
	onMouseDown: () => {},
	onClick: () => {},
};

export default Select;
