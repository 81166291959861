import React, { createContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const OwnerContext = createContext();

const OwnerContextProvider = (props) => {
	const [userId, setUserId] = useState('');
	const [firmId, setFirmId] = useState('');
	const [userMatch, setUserMatch] = useState(false);
	const [firmMatch, setFirmMatch] = useState(false);
	const userData = useSelector((state) => state.auth.user);
	const [clientOfFirmMatch, setClientOfFirmMatch] = useState(false);
	const [agentOfFirmMatch, setAgentForFirmMatch] = useState(false);
	useEffect(() => {
		setUserId(props.userId);
		setUserMatch(userData.uid === props.userId);
	}, [props.userId]);

	useEffect(() => {
		setFirmId(props.firmId);
		setFirmMatch(userData.fid === props.firmId);
		setClientOfFirmMatch(userData.clientOfFirms && userData.clientOfFirms.length > 0 ? userData.clientOfFirms.includes(props.firmId) : false);
		setAgentForFirmMatch(userData.agentOfFirms && userData.agentOfFirms.length > 0 ? userData.agentOfFirms.includes(props.firmId) : false);
	}, [props.firmId]);

	useEffect(() => {
		setFirmId(props.firmId);
		setFirmMatch(userData.fid === props.firmId);
	}, [props.firmId]);

	return <OwnerContext.Provider value={{ userId, firmId, userMatch, firmMatch, agentOfFirmMatch, clientOfFirmMatch }}>{props.children}</OwnerContext.Provider>;
};

OwnerContextProvider.propTypes = {
	userId: PropTypes.string,
	firmId: PropTypes.string,
	userMatch: PropTypes.bool,
	firmMatch: PropTypes.bool,
};

export { OwnerContextProvider, OwnerContext };
