import { useState, useEffect, useRef, useCallback } from 'react';
import { useMapEvents, useMap, Marker } from 'react-leaflet';
import { divIcon } from 'leaflet';

const Markers = (props) => {
	const mapInstance = useMap();
	const mapRef = useRef(null);
	mapRef.current = mapInstance;

	const [mapZoom, setMapZoom] = useState(mapInstance.getZoom());
	useMapEvents({
		zoomend: () => {
			setMapZoom(mapInstance.getZoom());
		},
		click: (e) => {
			if (e.latlng) {
				const { lat, lng } = e.latlng;
				mapInstance.setView([lat, lng], 6);
			}
		},
	});

	useEffect(() => {
		if (props.selectedRegion.id && props.selectedRegion.center) return mapInstance.setView(props.selectedRegion.center, 6);
	}, [props.selectedRegion]);

	const onMarkerClick = useCallback(
		(regionId) => {
			const regionData = props.regions.find((r) => r.region_id === regionId);
			mapInstance.setView(regionData.center, 6);
			return props.setSelectedRegion({ id: regionId, center: regionData.center });
		},
		[props.regions],
	);

	return props.regions.map((r) => <CustomMarker key={r.region_id} position={r.center} onClick={onMarkerClick} casesInRegion={r.agents_in_reg} regionId={r.region_id} />);
};
const CustomMarker = ({ position, key, onClick, casesInRegion, regionId }) => {
	const icon = divIcon({
		className: 'dashboard-map__map-marker',
		iconSize: [25, 25],
		iconAnchor: [10, 10],
		html: `<div>${casesInRegion}</div>`,
	});

	return (
		<Marker
			position={position}
			icon={icon}
			key={key}
			eventHandlers={{
				click: () => onClick(regionId),
			}}
		/>
	);
};

export default Markers;
