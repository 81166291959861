import Ajax from './base';

export const GetFirmReport = (firmID, queryStringData) => {
	return Ajax.Request(`/reports/firm/${firmID}`, Ajax.GET, false, queryStringData);
};

export const GetClientReport = (firmID, caseID, queryStringData) => {
	return Ajax.Request(`/reports/firm/${firmID}/case/${caseID}`, Ajax.GET, false, queryStringData);
};

export const GetCaseReport = (caseID, queryStringData) => {
	return Ajax.Request(`/reports/case/${caseID}`, Ajax.GET, false, queryStringData);
};

export const GetRegionReport = (regionID, queryStringData) => {
	return Ajax.Request(`/reports/region/${regionID}`, Ajax.GET, false, queryStringData);
};

export const GetTop10Report = (regionID, queryStringData) => {
	return Ajax.Request(`/reports/region/${regionID}/top-ten`, Ajax.GET, false, queryStringData);
};

export const GetOwedAssociatesReport = (queryStringData) => {
	return Ajax.Request(`/reports/regional/owed-associates`, Ajax.GET, false, queryStringData);
};

export const DownloadRegionalReport = async (data) => {
	return await Ajax.Download('/document-generator/reports/regional', Ajax.POST, false, data);
};

export const DownloadClientReport = async (data, type) => {
	return await Ajax.Download(`/document-generator/reports/member/${type}`, Ajax.POST, false, data);
};

export const DownloadCaseReport = async (data) => {
	return await Ajax.Download('/document-generator/reports/case', Ajax.POST, false, data);
};

export const DownloadTopTenReport = async (data) => {
	return await Ajax.Download('/document-generator/reports/top-ten', Ajax.POST, false, data);
};

export const DownloadOwedAssociatesReport = async (data) => {
	return await Ajax.Download('/document-generator/reports/owed-associates', Ajax.POST, false, data);
};

export const DownloadReportSpreadsheetTest = async (data) => {
	return await Ajax.Download('/spreadsheet-worker/reports/member', Ajax.POST, false, data);
};

export const DownloadEstimateReport = async (id, quoteId, data) => {
	return await Ajax.Download(`/document-generator/reports/estimate/${id}/${quoteId}`, Ajax.POST, false, data);
};

export const DownloadXlsxEstimateReport = async (id, qid) => {
	return await Ajax.Download(`/document-generator/reports/estimate/xlsx/${id}/${qid}`, Ajax.POST, false);
};

export default {
	GetFirmReport,
	GetClientReport,
	GetCaseReport,
	GetRegionReport,
	GetTop10Report,
	DownloadRegionalReport,
	DownloadClientReport,
	DownloadCaseReport,
	DownloadTopTenReport,
	DownloadOwedAssociatesReport,
	DownloadReportSpreadsheetTest,
	DownloadEstimateReport,
	DownloadXlsxEstimateReport,
};
