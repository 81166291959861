export const EmailTemplateSchema = (data) => {
	let isValid = true;
	let errors = {
		name: '',
		recipient: '',
		subject: '',
	};

	if (!data.name) {
		isValid = false;
		errors.name = errorMessages.required;
	}

	if (!data.recipient) {
		isValid = false;
		errors.recipient = errorMessages.required;
	}

	if (!data.subject) {
		isValid = false;
		errors.subject = errorMessages.required;
	}

	return { isValid, errors };
};

const errorMessages = {
	required: 'Required',
};
