import React from 'react';

import ForgotPasswordMessageAzami from './ForgotPasswordMessageAzami';
import ForgotPasswordMessageIPeer from './ForgotPasswordMessageiPeer';

import withDomainAuthTemplate from 'com/hoc/withDomainAuthTemplate';

const ForgotPasswordMessageTemplate = withDomainAuthTemplate(ForgotPasswordMessageAzami, ForgotPasswordMessageIPeer);

const ForgotPasswordMessage = () => {
	return <ForgotPasswordMessageTemplate />;
};

export default ForgotPasswordMessage;
