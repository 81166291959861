import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { OwnerContext } from 'util/ownerContext';

import './style.css';

const IconButton = (props) => {
	let theme = props.theme ? props.theme : 'light';
	let size = props.size ? props.size : '';
	const userData = useSelector((state) => state.auth.user);
	const ownerData = useContext(OwnerContext);

	const checkRoles = (uData, roles) => {
		if (uData.role.includes('admin')) return false;
		if (props.disabled) return props.disabled;
		if (roles.includes('$user') && ownerData.userMatch) return false;
		if (roles.includes('$firm') && ownerData.firmMatch) return false;
		if (roles.includes('$clientOfFirm') && ownerData.clientOfFirmMatch) return false;
		if (roles.includes('$agentOfFirm') && ownerData.agentOfFirmMatch) return false;
		if (roles.length === 0) return false;
		let uRoles = uData.role.filter((r) => {
			return roles.includes(r);
		});
		return uRoles.length === 0;
	};

	let disabled = checkRoles(userData, props.forRoles) ? 'disabled' : '';

	return (
		<button title={props.title} type={props.type} className={`form-icon-button ${theme} ${size}`} onClick={props.onClick} name={props.name} disabled={disabled}>
			<img src={props.icon} alt="" />
		</button>
	);
};

IconButton.propTypes = {
	title: PropTypes.string,
	size: PropTypes.string,
	theme: PropTypes.string,
	type: PropTypes.string,
	icon: PropTypes.node,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	forRoles: PropTypes.array,
};

IconButton.defaultProps = {
	title: '',
	size: 'large',
	theme: 'light',
	icon: null,
	onClick: () => {},
	disabled: false,
	forRoles: [],
};

export default IconButton;
