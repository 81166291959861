import React from 'react';

import { RoleBox } from 'com/util/RoleBox';
import { INTERNAL_ROLES, PAGE_TITLE } from 'data/constants';

import AppTemplate from 'com/templates/ApplicationTemplate';

import AppLink from 'com/util/AppLink';

import useTitle from 'services/hooks/useTitle';

import './style.css';

import ICON_REPORTS_CASE from 'assets/images/icons/ico_reports_cases_blurple.svg';
import ICON_REPORTS_REGIONS from 'assets/images/icons/ico_reports_regions_blurple.svg';
import ICON_REPORTS_TOP10 from 'assets/images/icons/ico_reports_top_blurple.svg';
import ICON_REPORTS_CLIENTS from 'assets/images/icons/ico_reports_customer_blurple.svg';

const Reports = () => {
	useTitle(PAGE_TITLE.REPORTS);
	return (
		<AppTemplate title="Reports" headerIcon="reports">
			<AppTemplate.Content>
				<div className="main-screen-section">
					<h2 className="main-screen-section__title">Data reports</h2>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pharetra, ipsum eget tincidunt commodo, tellus neque semper lorem, vel porta diam nunc quis lacus. Maecenas sed
						metus gravida, interdum risus at, viverra nisl. Sed sodales venenatis convallis.
					</p>
					<ul className="reports-list">
						<RoleBox roles={INTERNAL_ROLES}>
							<li>
								<AppLink type="regular" to="/reports/member">
									<img src={ICON_REPORTS_CLIENTS} className="report-list__icon" alt="" />
									<div>
										<span className="report-list__report-title">Member Report</span>
										<span className="report-list__report-description">View a summary of sent and received cases, with corresponding reciprocity points</span>
									</div>
								</AppLink>
							</li>
							<li>
								<AppLink type="regular" to="/reports/case">
									<img src={ICON_REPORTS_CASE} className="report-list__icon" alt="" />
									<div className="report-list__text-box">
										<span className="report-list__report-title">Case Report</span>
										<span className="report-list__report-description">View points breakdown for a single case</span>
									</div>
								</AppLink>
							</li>
							<li>
								<AppLink type="regular" to="/reports/regional">
									<img src={ICON_REPORTS_REGIONS} className="report-list__icon" alt="" />
									<div>
										<span className="report-list__report-title">Regional Report</span>
										<span className="report-list__report-description">View member point status according to service and region</span>
									</div>
								</AppLink>
							</li>
							<li>
								<AppLink type="regular" to="/reports/top-10">
									<img src={ICON_REPORTS_TOP10} className="report-list__icon" alt="" />
									<div>
										<span className="report-list__report-title">Top 10 Report</span>
										<span className="report-list__report-description">View the top 10 associates by region</span>
									</div>
								</AppLink>
							</li>
							<li>
								<AppLink type="regular" to="/reports/owed-associates">
									<img src={ICON_REPORTS_TOP10} className="report-list__icon" alt="" />
									<div>
										<span className="report-list__report-title">Owed Associates</span>
										<span className="report-list__report-description">View associates owed cases by region and account executive</span>
									</div>
								</AppLink>
							</li>
						</RoleBox>
					</ul>
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default Reports;
