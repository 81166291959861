import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { random } from 'services/strings';
import useNotification from 'services/hooks/use_notification';

import ICON_SIDEBAR_DOCS_UPLOAD from 'assets/images/icons/ico_sidebar_title_docs_upload.svg';
import ICON_DOCU_UPLOAD_HINT from 'assets/images/icons/docu_upload_hint.svg';
import ICON_SIDEBAR_DOCU_BTN_DELETE from 'assets/images/icons/ico_btn_delete_white.svg';
import ICON_PAPERCLIP from 'assets/images/icons/ico_paperclip.svg';

import { ACCEPTED_FILE_TYPES } from 'data/constants';

import './style.css';
import { RoleBox } from 'com/util/RoleBox';

const DocumentUploader = (props) => {
	const dropContainer = useRef(null);
	const sendNotification = useNotification();
	const fileChange = (e) => {
		let filesArr = Array.from(e.target.files);
		let duplicates = [];

		filesArr.forEach((f, i) => {
			if (props.files.find((d) => d.name === f.name)) {
				props.setDuplicateFiles(duplicates);
				props.setDuplicatesModalOpen(true);
				duplicates.push(f);
				f.id = null;
				f.is_uploaded = false;
			} else {
				if (props.checkFileType) {
					if (ACCEPTED_FILE_TYPES.includes(f.type)) {
						f.id = random(8);
						f.is_uploaded = false;
					} else {
						sendNotification({ type: 'error', title: 'Wrong File Type' });
						f.id = null;
					}
				} else {
					f.id = random(8);
					f.is_uploaded = false;
				}
			}
		});

		props.setFiles([...props.files, ...filesArr.filter((f) => f.id !== null)]);
		props.setUploadBoxIndicators((prevState) => {
			return { ...prevState, noFilesSelected: false };
		});
		e.target.value = '';
		dropContainer.current.classList.remove('document-uploader__document-upload-box__drag-enter');
	};

	const removeFile = (e) => {
		props.setFiles([...props.files.filter((f) => f.id !== e.target.closest('li').dataset.fid)]);
	};

	const onDragEnter = () => {
		dropContainer.current.classList.add('document-uploader__document-upload-box__drag-enter');
	};

	const onDragLeave = () => {
		dropContainer.current.classList.remove('document-uploader__document-upload-box__drag-enter');
	};

	return (
		<div className="document-uploader">
			<div className="document-uploader__title">
				{props.title && <img src={ICON_SIDEBAR_DOCS_UPLOAD} />}
				{props.title}
			</div>
			{!props.editMode ?
				<div className="document-uploader__body">
					<div className={`document-uploader__document-upload-box ${props.uploadBoxIndicators.noFilesSelected === true ? 'empty' : ''}`} ref={dropContainer}>
						<label htmlFor="document-uploader__document-upload-box-input">
							<input
								type="file"
								multiple
								className="document-uploader__document-upload-box-input"
								id="document-uploader__document-upload-box-input"
								onChange={fileChange}
								onDragEnter={onDragEnter}
								onDragLeave={onDragLeave}
							/>
							<img src={ICON_DOCU_UPLOAD_HINT} />
							<span className="">Upload documents</span>
						</label>
					</div>
					<div className="document-uploader__body__message-container">
						<span>{props.uploadBoxIndicators.message}</span>
					</div>
					<ul className="document-uploader__uploaded-documents-list">
						{props.files.length > 0 &&
							props.files.map((f) => {
								if (f.is_uploaded === true) {
									return null;
								}
								return (
									<li key={f.id} data-fid={f.id}>
										<img src={ICON_PAPERCLIP} className="document-uploader__icon-paperclip" />
										<a href="#">{f.name}</a>
										<button onClick={removeFile}>
											<img src={ICON_SIDEBAR_DOCU_BTN_DELETE} />
										</button>
									</li>
								);
							})}
					</ul>
				</div>
			:	<div className="document-uploader__edit">
					<div className="document-uploader__edit__content">
						<img src={ICON_PAPERCLIP} className="document-uploader__icon-paperclip" />
						<a href="#">{props.documentForUpdate.name}</a>
					</div>
				</div>
			}
		</div>
	);
};

DocumentUploader.propTypes = {
	title: PropTypes.string,
	files: PropTypes.array,
	setFiles: PropTypes.func,
	setDuplicateFiles: PropTypes.func,
	setDuplicatesModalOpen: PropTypes.func,
	uploadBoxIndicators: PropTypes.object,
	setUploadBoxIndicators: PropTypes.func,
	documentForUpdate: PropTypes.object,
	editMode: PropTypes.bool,
};

DocumentUploader.defaultProps = {
	title: '',
	files: [],
	setFiles: () => {},
	setDuplicateFiles: () => {},
	setDuplicatesModalOpen: () => {},
	uploadBoxIndicators: { message: '', noFilesSelected: false },
	setUploadBoxIndicators: () => {},
	documentForUpdate: {},
	editMode: false,
};

export default DocumentUploader;
