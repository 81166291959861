export const RegionalTemplateSchema = (data) => {
	let isValid = true;
	let errors = {
		service_id: '',
		region_id: '',
		email_template_id: '',
	};

	if (!data.service_id) {
		isValid = false;
		errors.service_id = errorMessages.required;
	}

	if (!data.region_id) {
		isValid = false;
		errors.region_id = errorMessages.required;
	}

	if (!data.email_template_id) {
		isValid = false;
		errors.email_template_id = errorMessages.required;
	}

	return { isValid, errors };
};

const errorMessages = {
	required: 'Required',
};
