const AppDate = (props) => {
	const locale = navigator.language;
	const localeInput = locale ? locale : undefined;
	const date = props.children ? new Date(props.children) : '';
	const day = new Intl.DateTimeFormat(localeInput, { day: '2-digit' });
	const month = new Intl.DateTimeFormat(localeInput, { month: 'short' });
	const year = new Intl.DateTimeFormat(localeInput, { year: '2-digit' });

	if (!date) {
		return '';
	}
	let d = day.format(date);
	let m = month.format(date);
	let y = year.format(date);
	return <>{`${d}-${m}-${y}`}</>;
};

export default AppDate;
