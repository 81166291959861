import Ajax from './base';

const GetAllByCaseId = (id, queryStringData) => {
	return Ajax.Request(`/cases/${id}/regions`, Ajax.GET, false, queryStringData);
};

const Instruct = (id, payload) => {
	return Ajax.Request(`/cases/${id}/regions/instruct`, Ajax.PATCH, true, payload);
};

const Update = (cid, rid, payload) => {
	return Ajax.Request(`/cases/${cid}/regions/${rid}`, Ajax.PUT, true, payload);
};

export default {
	GetAllByCaseId,
	Instruct,
	Update,
};
