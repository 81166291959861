import React from 'react';

import { AppRoute } from 'com/util/AppRoute';
import Routes from 'data/routes';

import './style.css';

import SystemSettingsRegionalTemplatesMain from './SystemSettingsRegionalTemplatesMain';
import SystemSettingsRegionalTemplatesDetails from './SystemSettingsRegionalTemplatesDetails';

const SystemSettingsRegionalTemplates = () => {
	return (
		<>
			<AppRoute route={Routes.SYSTEM_SETTINGS_REGIONAL_TEMPLATES_MAIN} exact />
			<AppRoute route={Routes.SYSTEM_SETTINGS_REGIONAL_TEMPLATES_ADD} exact />
			<AppRoute route={Routes.SYSTEM_SETTINGS_REGIONAL_TEMPLATES_EDIT} exact />
			<AppRoute route={Routes.SYSTEM_SETTINGS_REGIONAL_TEMPLATES_DUPLICATE} exact />
		</>
	);
};

export { SystemSettingsRegionalTemplatesMain, SystemSettingsRegionalTemplatesDetails };

export default SystemSettingsRegionalTemplates;
