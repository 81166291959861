import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import AppTemplate from 'com/templates/ApplicationTemplate';
import { AppRoute } from 'com/util/AppRoute';
import AppLink from 'com/util/AppLink';
import Routes from 'data/routes';

import { PAGE_TITLE } from 'data/constants';

import useTitle from 'services/hooks/useTitle';

import EventLoggerEvents from 'services/rest/event_logger';

import './style.css';

import ICON_RECIPROCITY from '../../../assets/images/icons/settings_reciprocity.svg';
import ICON_RECIPROCITY_ACTIVE from '../../../assets/images/icons/settings_reciprocity_active.svg';
import ICON_RECIPROCITY_FEE_THRESHOLDS from '../../../assets/images/icons/settings_reciprocity_fee_thresholds.svg';
import ICON_RECIPROCITY_FEE_THRESHOLDS_ACTIVE from '../../../assets/images/icons/settings_reciprocity_fee_thresholds_active.svg';
import ICON_POAS from '../../../assets/images/icons/settings_poas.svg';
import ICON_POAS_ACTIVE from '../../../assets/images/icons/settings_poas_active.svg';
import ICON_TECHNOLOGIES from '../../../assets/images/icons/settings_technologies.svg';
import ICON_TECHNOLOGIES_ACTIVE from '../../../assets/images/icons/settings_technologies_active.svg';
import ICON_PRICING_LEVELS from '../../../assets/images/icons/settings_pricing_levels.svg';
import ICON_BANNERS from '../../../assets/images/icons/ico_banner.svg';
import ICON_BANNERS_ACTIVE from '../../../assets/images/icons/ico_banner_active.svg';
import ICON_PRICING_LEVELS_ACTIVE from '../../../assets/images/icons/settings_pricing_levels_active.svg';
import ICON_ESTIMATOR_RULES from '../../../assets/images/icons/settings_estimator_rules.svg';
import ICON_ESTIMATOR_RULES_ACTIVE from '../../../assets/images/icons/settings_estimator_rules_active.svg';
import ICON_FIRM_FAMILIES from '../../../assets/images/icons/settings_firm_families.svg';
import ICON_FIRM_FAMILIES_ACTIVE from '../../../assets/images/icons/settings_firm_families_active.svg';
import ICON_EXCHANGE_RATES from '../../../assets/images/icons/settings_exchange_rates.svg';
import ICON_EXCHANGE_RATES_ACTIVE from '../../../assets/images/icons/settings_exchange_rates_active.svg';
import ICON_EMAIL from '../../../assets/images/icons/settings_email.svg';
import ICON_EMAIL_ACTIVE from '../../../assets/images/icons/settings_email_active.svg';
import ICON_EMAIL_TEMPATES from '../../../assets/images/icons/settings_email_templates.svg';
import ICON_EMAIL_TEMPATES_ACTIVE from '../../../assets/images/icons/settings_email_templates_active.svg';

const SystemSettings = (props) => {
	useTitle(PAGE_TITLE.SYSTEM_SETTINGS);

	const user = useSelector((state) => state.auth.user);
	const location = window.location.pathname.split('/')[2];

	return (
		<AppTemplate title="System Settings" headerIcon="system_settings">
			<AppTemplate.Sidebar>
				<div className="system-settings-sidebar">
					<nav className="system-settings-sidebar__navigation">
						<AppLink type="nav" to="/system-settings/reciprocity" className="system-settings-sidebar__navigation__link" trackClick={true} event="System Settings Reciprocity Tab Opened">
							{location === Routes.SYSTEM_SETTINGS_RECIPROCITY.path.split('/')[2] ?
								<img src={ICON_RECIPROCITY_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_RECIPROCITY} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">Reciprocity</span>
						</AppLink>
						<AppLink
							type="nav"
							to="/system-settings/firm-families"
							className="system-settings-sidebar__navigation__link"
							trackClick={true}
							event="System Settings Firm families Tab Opened"
						>
							{location === Routes.SYSTEM_SETTINGS_FIRM_FAMILIES.path.split('/')[2] ?
								<img src={ICON_FIRM_FAMILIES_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_FIRM_FAMILIES} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">Firm families</span>
						</AppLink>
						<AppLink type="nav" to="/system-settings/technologies" className="system-settings-sidebar__navigation__link" trackClick={true} event="System Settings Technologies Tab Opened">
							{location === Routes.SYSTEM_SETTINGS_TECHNOLOGIES.path.split('/')[2] ?
								<img src={ICON_TECHNOLOGIES_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_TECHNOLOGIES} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">Technologies</span>
						</AppLink>
						<AppLink
							type="nav"
							to="/system-settings/poa-management"
							className="system-settings-sidebar__navigation__link"
							trackClick={true}
							event="System Settings POA management Tab Opened"
						>
							{location === Routes.SYSTEM_SETTINGS_POAS.path.split('/')[2] ?
								<img src={ICON_POAS_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_POAS} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">POA management</span>
						</AppLink>
						<AppLink
							type="nav"
							to="/system-settings/estimator-rules"
							className="system-settings-sidebar__navigation__link"
							trackClick={true}
							event="System Settings Estimator Rules Tab Opened"
						>
							{location === Routes.SYSTEM_SETTINGS_ESTIMATOR_RULES.path.split('/')[2] ?
								<img src={ICON_ESTIMATOR_RULES_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_ESTIMATOR_RULES} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">Estimator Rules</span>
						</AppLink>
						<AppLink
							type="nav"
							to="/system-settings/pricing-levels"
							className="system-settings-sidebar__navigation__link"
							trackClick={true}
							event="System Settings Pricing Levels Tab Opened"
						>
							{location === Routes.SYSTEM_SETTINGS_PRICING_LEVELS.path.split('/')[2] ?
								<img src={ICON_PRICING_LEVELS_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_PRICING_LEVELS} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">Pricing Levels</span>
						</AppLink>
						<AppLink
							type="nav"
							to="/system-settings/exchange-rates"
							className="system-settings-sidebar__navigation__link"
							trackClick={true}
							event="System Settings Exchange Rates Tab Opened"
						>
							{location === Routes.SYSTEM_SETTINGS_EXCHANGE_RATES.path.split('/')[2] ?
								<img src={ICON_EXCHANGE_RATES_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_EXCHANGE_RATES} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">Exchange Rates</span>
						</AppLink>
						<AppLink type="nav" to="/system-settings/banners" className="system-settings-sidebar__navigation__link" trackClick={true} event="System Settings Banners Tab Opened">
							{location === Routes.SYSTEM_SETTINGS_BANNERS.path.split('/')[2] ?
								<img src={ICON_BANNERS_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_BANNERS} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">Banners</span>
						</AppLink>
						<AppLink
							type="nav"
							to="/system-settings/reciprocity-fee-thresholds"
							className="system-settings-sidebar__navigation__link"
							trackClick={true}
							event="System Settings Reciprocity Fee Thresholds Tab Opened"
						>
							{location === Routes.SYSTEM_SETTINGS_RECIPROCITY_FEE_THRESHOLDS.path.split('/')[2] ?
								<img src={ICON_RECIPROCITY_FEE_THRESHOLDS_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_RECIPROCITY_FEE_THRESHOLDS} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">Reciprocity Fee Thresholds</span>
						</AppLink>
						<AppLink type="nav" to="/system-settings/email" className="system-settings-sidebar__navigation__link" trackClick={true} event="System Settings General Azami Email Tab Opened">
							{location === Routes.SYSTEM_SETTINGS_EMAIL.path.split('/')[2] ?
								<img src={ICON_EMAIL_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_EMAIL} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">General Azami Email</span>
						</AppLink>
						<AppLink
							type="nav"
							to="/system-settings/email-templates"
							className="system-settings-sidebar__navigation__link"
							trackClick={true}
							event="System Settings Email Templates Tab Opened"
						>
							{location === Routes.SYSTEM_SETTINGS_EMAIL_TEMPLATES.path.split('/')[2] ?
								<img src={ICON_EMAIL_TEMPATES_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_EMAIL_TEMPATES} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">Email Templates</span>
						</AppLink>
						<AppLink
							type="nav"
							to="/system-settings/regional-templates"
							className="system-settings-sidebar__navigation__link"
							trackClick={true}
							event="System Settings Regional Templates Tab Opened"
						>
							{location === Routes.SYSTEM_SETTINGS_REGIONAL_TEMPLATES.path.split('/')[2] ?
								<img src={ICON_EMAIL_TEMPATES_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_EMAIL_TEMPATES} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">Regional Templates</span>
						</AppLink>
						<AppLink type="nav" to="/system-settings/integrations" className="system-settings-sidebar__navigation__link" data-mx-log="integrations">
							{location === Routes.SYSTEM_SETTINGS_EMAIL.path.split('/')[2] ?
								<img src={ICON_EMAIL_ACTIVE} className="system-settings-sidebar__navigation__link__icon" />
							:	<img src={ICON_EMAIL} className="system-settings-sidebar__navigation__link__icon" />}
							<span className="system-settings-sidebar__navigation__link__label">Integrations</span>
						</AppLink>
					</nav>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<AppRoute route={Routes.SYSTEM_SETTINGS_RECIPROCITY_FEE_THRESHOLDS} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_RECIPROCITY} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_POAS} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_TECHNOLOGIES} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_FIRM_FAMILIES} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_ESTIMATOR_RULES} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_PRICING_LEVELS} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_EXCHANGE_RATES} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_BANNERS} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_EMAIL} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_INTEGRATIONS} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_EMAIL_TEMPLATES} />
				<AppRoute route={Routes.SYSTEM_SETTINGS_REGIONAL_TEMPLATES} />
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default SystemSettings;
