import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AppRoute } from 'com/util/AppRoute';
import Routes from 'data/routes';

import Button from 'com/ui/Button';
import LoaderOverlay from 'com/ui/LoaderOverlay';

import AppLink from 'com/util/AppLink';

import './style.css';

import SystemSettingsReciprocityGroups from './SystemSettingsReciprocityGroups';
import SystemSettingsReciprocityRatios from './SystemSettingsReciprocityRatios';
import SystemSettingsReciprocityRules from './SystemSettingsReciprocityRules';

import Settings from 'services/rest/settings';
import EventLoggerEvents from 'services/rest/event_logger';
import { formatString } from 'services/strings';

const SystemSettingsReciprocity = (props) => {
	const updatedTabsInit = {
		groups: false,
		points: false,
	};

	const [updatedTabs, setUpdatedTabs] = useState(updatedTabsInit);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [regionsGroups, setRegionsGroups] = useState([]);
	const [regions, setRegions] = useState([]);
	const [regionsSelectOptions, setRegionsSelectOptions] = useState([]);
	const [deletedGroups, setDeletedGroups] = useState([]);
	const [services, setServices] = useState([]);
	const [regionPoints, setRegionPoints] = useState([]);
	const [regionsGroupsSelectOptions, setRegionsGroupsSelectOptions] = useState([]);
	const [regionsWithPoints, setRegionsWithPoints] = useState([]);
	const user = useSelector((state) => state.auth.user);

	useEffect(async () => {
		try {
			await fetchData();
		} catch (err) {
			console.log(err);
		}
	}, []);

	const fetchData = async () => {
		try {
			setShowLoader(true);
			let rg = await Settings.GetRegionsGroups();
			setRegionsGroups(rg);
			let s = await Settings.GetServices();
			setServices(s);
			let p = await Settings.GetSystemPoints();
			setRegionPoints(p);
			let r = await Settings.GetRegions();
			setRegions(r);
			mapRegionsToPoints(r, p);
			setRegionsSelectOptions(r.map((i) => ({ label: i.name, value: i.id })));
			setRegionsGroupsSelectOptions(rg.map((i) => ({ label: i.name, value: i.id })));
			setShowLoader(false);
		} catch (err) {
			console.log(err);
			setShowLoader(false);
		}
	};

	const onChangeRegionsGroups = (data) => {
		setRegionsGroups(data);
		setUpdatedTabs((prevState) => ({ ...prevState, groups: true }));
	};

	const mapRegionsToPoints = (regions, points) => {
		regions.forEach((r) => {
			const regionPoints = points.filter((p) => p.region_id === r.id);
			r.points = regionPoints;
		});
		setRegionsWithPoints(regions);
	};

	const updatePoints = useCallback((region) => {
		setUpdatedTabs((prevState) => ({ ...prevState, points: true }));
		setRegionsWithPoints((prevState) => {
			return prevState.map((r) => {
				if (r.id === region.id) {
					r = { ...r, points: region.points, modified: true };
				}
				return r;
			});
		});
	}, []);

	const onSaveRegionPoints = (regions) => {
		let regionPointsPayload = [];
		regions.forEach((r) => {
			regionPointsPayload = [...regionPointsPayload, ...r.points.filter((r) => r.modified)];
		});

		return regionPointsPayload;
	};

	const applyRegionGroupPoints = (groupPoints, regionIds) => {
		setUpdatedTabs((prevState) => ({ ...prevState, points: true }));
		setRegionsWithPoints((prevState) => {
			return prevState.map((r) => {
				if (regionIds.includes(r.id)) {
					groupPoints.forEach((gp) => {
						const existingPoints = r.points.find((p) => p.service_id === gp.service_id);
						if (existingPoints) {
							const elemIndex = r.points.findIndex((o) => o.service_id === existingPoints.service_id);
							r.points[elemIndex] = { ...r.points[elemIndex], base_points: gp.base_points, reward_points: gp.reward_points };
							r.points = [...r.points];
						} else {
							r.points = [...r.points, { ...gp, region_id: r.id, name: 'SYSTEM', override: 0 }];
						}
					});
				}
				return r;
			});
		});
	};

	const handleSaveReciprocitySettings = async () => {
		setShowSpinner(true);
		try {
			// SAVE GROUPS
			let createdGroups = regionsGroups.filter((g) => g.action == 'created');
			let editedGroups = regionsGroups.filter((g) => g.action == 'edited');
			for (let g of createdGroups) {
				delete g.id;
				await Settings.CreateRegionsGroup(g);
			}
			for (let g of editedGroups) {
				await Settings.UpdateRegionsGroup(g.id, g);
			}
			for (let g of deletedGroups) {
				await Settings.DeleteRegionsGroup(g.id);
			}
			let rg = await Settings.GetRegionsGroups();
			setRegionsGroups(rg);

			// SAVE POINTS
			let modifiedPoints = regionsWithPoints.filter((r) => r.modified);
			// Check if there are any modifications to be saved on the points
			if (modifiedPoints.length > 0) {
				let p = onSaveRegionPoints(modifiedPoints);
				await Settings.UpdateSystemPoints(p);
				let sp = await Settings.GetSystemPoints();
				let r = await Settings.GetRegions();
				mapRegionsToPoints(r, sp);
				Object.keys(updatedTabs).map((u) => {
					if (updatedTabs[u] !== true) {
						return u;
					}
					u = formatString(u);
					EventLoggerEvents.EventLogger({ event: `System Settings Reciprocity ${u} Tab Updated`, data: { user: user } });
				});
			}
			setUpdatedTabs(updatedTabsInit);
			setShowSpinner(false);
		} catch (err) {
			console.log(err);
			setShowSpinner(false);
		}
	};

	return (
		<div className="main-screen-section white full-height no-padding system-details-content">
			<LoaderOverlay showLoader={showLoader} />
			<header className="system-details-content__header">
				<nav>
					<AppLink type="nav" to={`/system-settings/reciprocity`} activeClassName="active" exact={true}>
						{updatedTabs.points ?
							<span className="system-details-content__header__update-indicator"></span>
						:	null}
						Ratios
					</AppLink>
					<AppLink type="nav" to={`/system-settings/reciprocity/rules`} activeClassName="active">
						Rules
					</AppLink>
					<AppLink type="nav" to={`/system-settings/reciprocity/groups`} activeClassName="active">
						{updatedTabs.groups ?
							<span className="system-details-content__header__update-indicator"></span>
						:	null}
						Groups
					</AppLink>
				</nav>
			</header>
			<div className="system-details-content__body">
				<AppRoute
					exact
					route={Routes.SYSTEM_SETTINGS_RECIPROCITY_RATIOS}
					regions={regions}
					regionsGroups={regionsGroups}
					services={services}
					regionsGroupsSelectOptions={regionsGroupsSelectOptions}
					applyRegionGroupPoints={applyRegionGroupPoints}
					regionsWithPoints={regionsWithPoints}
					updatePoints={updatePoints}
					setRegionsWithPoints={setRegionsWithPoints}
				/>
				<AppRoute route={Routes.SYSTEM_SETTINGS_RECIPROCITY_RULES} />
				<AppRoute
					exact
					route={Routes.SYSTEM_SETTINGS_RECIPROCITY_GROUPS}
					regionsGroups={regionsGroups}
					regions={regions}
					regionsSelectOptions={regionsSelectOptions}
					onChangeRegionsGroups={onChangeRegionsGroups}
					setDeletedGroups={setDeletedGroups}
				/>
			</div>
			<footer className="system-details-content__footer">
				<Button onClick={handleSaveReciprocitySettings} showSpinner={showSpinner}>
					Save
				</Button>
			</footer>
		</div>
	);
};

export { SystemSettingsReciprocityGroups, SystemSettingsReciprocityRatios, SystemSettingsReciprocityRules };

export default SystemSettingsReciprocity;
