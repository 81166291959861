import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import Button from 'com/ui/Button';
import Input from 'com/ui/Input';
import Textarea from 'com/ui/Textarea';
import Select from 'com/ui/Select';
import Editor from 'com/ui/Editor';

import useNotification from 'services/hooks/use_notification';
import Settings from 'services/rest/settings';
import EmailTemplates from 'services/rest/email-templates';
import { EmailTemplateSchema } from 'services/validators/emailTemplate';

import './style.css';

const SystemSettingsEmailTemplatesDetails = () => {
	const user = useSelector((state) => state.auth.user);

	const emailTypeInit = [{ label: 'Case', value: 'CASE' }];

	const dataInit = {
		name: '',
		description: '',
		email_type: 'CASE',
		created_by: '',
		recipient: '',
		service_id: '',
		case_status_id: '',
		subject: '',
		content: '',
	};

	const errorsInit = {
		name: '',
		description: '',
		recipient: '',
		service_id: '',
		case_status_id: '',
		subject: '',
		content: '',
	};

	const recipients = [
		{ label: 'Select Recipient', value: '' },
		{ value: 'CLIENT', label: 'Client' },
		{ value: 'ASSOCIATE', label: 'Associate' },
		{ value: 'BILLING', label: 'Billing' },
		{ value: 'ACCOUNT_EXECUTIVE', label: 'Account Executive' },
	];

	const tokenList = [
		'{APPLICANT_NAME}',
		'{APPLICATION_LANGUAGE}',
		'{APPLICATION_TITLE}',
		'{EARLIEST_PRIORITY_DATE}',
		'{INTL_FILING_DATE}',
		'{#_CLASSES}',
		'{APPLICATION_NUMBER}',
		'{EPV_PUBLICATION_NUMBER}',
		'{EPV_APPLICATION_NUMBER}',
		'{SERVICE}',
		'{ASSOCIATE_REGION_REF}',
		'{AZAMI_CASE_REF}',
		'{CASE_DEADLINE}',
		'{CLIENT_CASE_REF}',
		'{CLIENT_FIRM_NAME}',
		'{CLIENT_REGION_REF}',
		'{COUNTRIES}',
		'{REGION_DEADLINE}',
		'{CLAIMING_PRIORITY}',
		'{CLIENT_CONTACT_INFO}',
	];

	const sendNotification = useNotification();
	const { id } = useParams();
	const location = useLocation();
	const [action, setAction] = useState('');
	const [emailTypes, setEmailTypes] = useState(emailTypeInit);
	const [data, setData] = useState(dataInit);
	const [services, setServices] = useState([]);
	const [statuses, setStatuses] = useState([]);
	const [tokens, setTokens] = useState([]);
	const [spinner, setSpinner] = useState(false);
	const history = useHistory();
	const [errors, setErrors] = useState(errorsInit);

	useEffect(() => {
		(async () => {
			try {
				let [serviceData, statusData] = await Promise.all([Settings.GetServices(), Settings.GetStatuses()]);

				setServices([{ label: 'Select Service', value: '' }, ...serviceData.map((s) => ({ label: s.name, value: s.id }))]);

				setStatuses([{ label: 'Select Status', value: '' }, ...statusData.map((s) => ({ label: s.name, value: s.id }))]);

				setTokens([{ label: '', value: '' }, ...tokenList.map((t) => ({ label: t, value: t }))]);

				setAction(location.pathname.split('/').at(-1));
				if (!!id) {
					let templateData = await EmailTemplates.GetOneByID(id);
					setData(templateData);
				}
			} catch (err) {
				console.log(err);
				sendNotification({ type: 'error', title: 'Something went wrong with loading the data.' });
			}
		})();
	}, []);

	const fieldUpdate = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	};

	const editorUpdate = (state) => {
		setData({
			...data,
			content: state,
		});
	};

	const copyTag = (e) => {
		let selectedTag = e.target.value;
		navigator.clipboard.writeText(selectedTag);
		sendNotification({ type: 'info', title: `${selectedTag} tag copied. It can be used in Subject and Content` });
	};

	const submit = async () => {
		let validation = EmailTemplateSchema(data);
		setErrors(validation.errors);
		if (!validation.isValid) {
			sendNotification({ type: 'error', title: 'Email Template Submition Failed. Fix errors above.' });
			return;
		}

		try {
			setSpinner(true);
			if (action === 'add' || action === 'duplicate') {
				delete data.id;
				await EmailTemplates.Create({ ...data, created_by: user.uid });
			} else {
				await EmailTemplates.Update(id, { ...data, created_by: user.uid });
			}
			setSpinner(false);
			sendNotification({ type: 'success', title: 'Email Template Saved.' });
			history.push('/system-settings/email-templates');
		} catch (err) {
			sendNotification({ type: 'error', title: 'Email Template Submition Failed' });
			console.log(err);
			setSpinner(false);
		}
	};

	const cancel = () => {
		history.goBack();
	};

	return (
		<>
			{action === 'add' || data.subject !== '' ?
				<div className="main-screen-section white full-height no-padding system-details-content settings-email-templates">
					<div className="system-details-content__body">
						<span className="system-details-content__body__title">{`${action.charAt(0).toUpperCase() + action.slice(1)} Template`}</span>
						<div className="settings-email-templates-template-flex-row">
							<div className="settings-email-templates-template-email-type-input-container">
								<Select label="Email Type" options={emailTypes} onChange={fieldUpdate} name="email_type" value={data.email_type} />
							</div>
							<div className="settings-email-templates-template-services-input-container">
								<Select label="Service" options={services} onChange={fieldUpdate} name="service_id" value={data.service_id} />
							</div>
							<div className="settings-email-templates-template-recipients-input-container">
								<Select label="Email Receiver" options={recipients} onChange={fieldUpdate} name="recipient" value={data.recipient} error={errors.recipient} />
							</div>
							<div className="settings-email-templates-template-milestone-trigger-input-container">
								<Select label="Milestone Trigger" options={statuses} onChange={fieldUpdate} name="case_status_id" value={data.case_status_id} />
							</div>
						</div>
						<div className="settings-email-templates-template-name-input-container">
							<Input label="Name" customClassName="text-input" name="name" value={data.name} onChange={fieldUpdate} error={errors.name} />
						</div>
						<div className="settings-email-templates-template-description-input-container">
							<Textarea label="Description" customClassName="text-input" name="description" value={data.description} onChange={fieldUpdate} error={errors.description} />
						</div>
						<div className="settings-email-templates-template-flex-row">
							<div className="settings-email-templates-template-subject-input-container">
								<Input label="Subject" customClassName="text-input" name="subject" value={data.subject} onChange={fieldUpdate} error={errors.subject} />
							</div>
							<div className="settings-email-templates-template-insert-tag-input-container">
								<Select label="Available Tags" options={tokens} onChange={copyTag} />
							</div>
						</div>
						<div className="settings-email-templates-template-content-input-container">
							<Editor label="Content" name="contnet" theme="snow" value={data.content} onChange={editorUpdate} />
						</div>
					</div>
					<footer className="system-details-content__footer">
						<Button onClick={cancel} theme={'azami-light-violet'}>
							Cancel
						</Button>
						<Button onClick={submit} showSpinner={spinner}>
							Save
						</Button>
					</footer>
				</div>
			:	<></>}
		</>
	);
};
export default SystemSettingsEmailTemplatesDetails;
