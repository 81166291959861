import React from 'react';

import './style.css';

const LegalInfoTermsAndConditions = () => {
	return (
		<div className="terms-and-conditions__content-wrapper">
			<h5>
				{' '}
				<span>1.</span> APPLICABILITY
			</h5>
			<p>
				These General Terms and Conditions for Services (“General Conditions”), apply to all services provided to you (“you” or “Member”) by Azami Global or its affiliates (together “AZAMI”)
				unless specified otherwise in writing (the “Services”). Your acceptance of an Engagement Letter or similar agreement however defined (an “Agreement”) calling for AZAMI to provide
				certain services automatically entails acceptance of the General Conditions, including for follow-up services, as well as waiver of Member’s own terms and conditions (if applicable).
				Member hereby agrees to comply with all rules, regulations and policies of AZAMI, as may be amended from time to time by AZAMI. AZAMI reserves the right to adopt additional regulations
				and to modify these General Conditions, as may be deemed necessary by AZAMI.
			</p>

			<h5>
				<span>2.</span> PAYMENT FOR SERVICES
			</h5>
			<p>
				All invoices shall be sent via email only. The Member shall be responsible for payment of services ordered and such payment is not contingent on Member or Member’s firm receiving
				payment from an end-client. Full payment on any invoice shall be due within a grace period of 30 days from the date that appears on the invoice, unless a prearranged written agreement
				exists. The invoice will include the application number, countries and regions, as well as the service provided and AZAMI reference number. All bank fees incurred for wire transfers
				are the responsibility of the Member. AZAMI reserves the right to charge 1.5% in late payment fees for each month on overdue accounts, until payment is received in full. Member shall
				be required to pay this late payment surcharge and all other costs involved (legal or otherwise) in the process of recovering monies owed to AZAMI. Payment of fees is not conditional
				upon any guaranteed outcome, and AZAMI is not bound by the success or acceptance of Member’s application into any country or region for which AZAMI coordinated such filing. Please note
				that AZAMI is not a law firm. All information provided and fees charged, are based on previously collected information from AZAMI’s associates in each respective country or region.
				This information is subject to change, and we do not take responsibility for any errors or omissions in the information. Prices are dependent on the fluctuation of currency exchange
				rates, and all fees are subject to change. Member shall be responsible for all applicable federal, state and local sales, use or other taxes due in connection with the Services
				rendered, except for taxes based on AZAMI’s income.
			</p>

			<h5>
				<span>3.</span> WARRANTIES
			</h5>
			<p>
				EXCEPT AS SPECIFICALLY PROVIDED FOR IN THESE GENERAL TERMS, ALL SERVICES PROVIDED BY AZAMI ARE PROVIDED “AS IS”. AZAMI MAKES NO WARRANTIES, EXPRESS OR IMPLIED, AND HEREBY DISCLAIMS ALL
				IMPLIED WARRANTIES, INCLUDING ANY WARRANTY OF MERCHANTABILITY AND WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE.
			</p>

			<h5>
				<span>4.</span> LIABILITY
			</h5>
			<p>
				<span>4.1</span> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AZAMI WILL NOT BE LIABLE TO THE MEMBER OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY,
				PUNITIVE OR SPECIAL DAMAGES ARISING OUT OF OR IN RELATION TO THE SERVICES, THESE GENERAL TERMS OR ANY OTHER AGREEMENT ENTERED INTO BETWEEN AZAMI AND MEMBER.
			</p>
			<p>
				<span>4.2</span> Moreover, AZAMI shall not be liable for any amounts in connection with any claim resulting or arising from a matter in which: (i) Member instructions are not complete,
				clear, accurate and/or timely; (ii) Member does not adhere to relevant deadlines; or (iii) Member does not ensure that AZAMI safely received any information sent to its attention via
				email or post. Member shall be responsible for any loss, damage or expenses sustained by the AZAMI as a result of, or in connection with, unconfirmed instructions in to AZAMI writing.
			</p>
			<p>
				<span>4.3</span> AZAMI may occasionally turn to third parties (such as legal advisers, researchers and other specialists) to assist in the execution of a Member’s case. These third
				parties will only be of a high caliber and judged by us to have the knowledge and experience we believe is necessary to complete members case. All these partners act in good faith, but
				if deficiencies in their service arise, AZAMI will not be liable for any loss, damage or cost, relating to such deficiencies in connection with the work of such third parties. For the
				avoidance of doubt, nothing in these General Conditions shall relieve AZAMI of liability for direct damages in connection with the gross negligence or non-performance of AZAMI of its
				duties to a Member under any Agreement.
			</p>

			<h5>
				<span>5.</span> CONFIDENTIALITY
			</h5>
			<p>
				All information and materials belonging to and furnished by AZAMI in connection with the Services, shall remain property of AZAMI and shall be used by the Member solely in connection
				with the Services, and shall be held in strict confidence by the Member. Member shall not at any time use or approach any customer, associates or other member of AZAMI, either past or
				present, without AZAMI’s written consent. All information and materials belonging to and furnished by Member to AZAMI shall remain property of Member and shall be used by AZAMI solely
				in connection with the Services. The manner in which AZAMI treats all such information and materials is set forth in the AZAMI privacy policy, available upon Member request.
			</p>

			<h5>
				<span>6.</span> MISCELLANEOUS
			</h5>
			<p>
				These General Conditions shall remain in effect through the completion of the Services. Either AZAMI or Member may terminate the Services at any time and for any reason, without
				liability to the other Party. Upon termination by AZAMI (other than in connection with a breach of these General Conditions by Member), Member shall be liable only for payment for the
				Services rendered through the termination date, and shall receive from AZAMI a pro-rata refund of any unused, prepaid fees for Services. AZAMI is an independent contractor and no
				employer-employee relationship between the Member and AZAMI is established or implied with these General Conditions. These General Conditions shall not be construed as to confer upon
				or give any third party any rights or remedies with respect to the subject matter hereof. These General Conditions shall be governed by and construed in accordance with the laws of the
				State of New York. The parties hereto agree that, in any action by and between the parties which arise out of these General Conditions, shall be exclusively resolved in the competent
				courts of Kings County. Notwithstanding the above, to the extent allowed by law, AZAMI may choose to bring any claim arising against Member or an affiliate of Member in connection with
				these General Conditions, in (i) any other court, and (ii) concurrent proceedings in numerous jurisdictions. These General Terms constitute the entire agreement between the AZAMI and
				the Member, and supersede all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. No modification, amendment or waiver
				of any provision of these General Terms shall be effective unless in writing and signed by the party against whom the modification, amendment or waiver is to be asserted. If any
				provision of these Terms and Conditions is held to be unenforceable for any reason, such provision shall be reformed to the extent necessary to make it enforceable.
			</p>
		</div>
	);
};

export default LegalInfoTermsAndConditions;
