import React, { useState, useEffect } from 'react';
// ui comp
import Input from 'com/ui/Input';
import Switch from 'com/ui/Switch';
// styles
import './style.css';

const CaseSettingsClient = (props) => {
	const { caseDetails, onChange } = props;

	return (
		<div className="case-settings-client">
			<div className="case-settings-client__input-group">
				<Input
					name="client_contact_greeting"
					label="Client Greeting Name"
					value={caseDetails.client_contact_greeting}
					onChange={onChange}
					customClassName="case-settings-client__input-group__input"
				/>
				<Input
					name="client_contact_full_name"
					label="Client Full Name"
					value={caseDetails.client_contact_full_name}
					onChange={onChange}
					customClassName="case-settings-client__input-group__input"
				/>
			</div>
			<div className="case-settings-client__input-group">
				<Input
					name="client_contact_email"
					label='Client "To" Address'
					value={caseDetails.client_contact_email}
					onChange={onChange}
					customClassName="case-settings-client__input-group__input"
				/>
				<Input
					name="client_contact_email_for_quotes"
					label='Client "CC" Address(es)'
					value={caseDetails.client_contact_email_for_quotes}
					onChange={onChange}
					customClassName="case-settings-client__input-group__input"
				/>
				<Input
					name="client_contact_bcc"
					label='Client "BCC" Address(es)'
					value={caseDetails.client_contact_bcc}
					onChange={onChange}
					customClassName="case-settings-client__input-group__input"
				/>
			</div>
			<div className="case-settings-client__switch-group">
				<Switch
					name="client_reminder_to_client"
					label="Toggle automatic reminder to client"
					value={caseDetails.client_reminder_to_client}
					onChange={onChange}
					customClassName="case-settings-client__switch-group__switch"
					theme="blurple"
				/>
				<Switch
					name="client_reminder_to_associate"
					label="Toggle automatic reminder to associate"
					value={caseDetails.client_reminder_to_associate}
					onChange={onChange}
					customClassName="case-settings-client__switch-group__switch"
					theme="blurple"
				/>
				<Switch
					name="client_automatic_reminder"
					label="Send automatic email reminders requesting confirmation of filling 4 days before deadline"
					value={caseDetails.client_automatic_reminder}
					onChange={onChange}
					customClassName="case-settings-client__switch-group__switch"
					theme="blurple"
				/>
			</div>
		</div>
	);
};

export default CaseSettingsClient;
