import { object, string, ref } from 'yup';
import { messages } from 'util/validations';

export const UserAddSchema = object().shape({
	firm_code: string().required(messages.required.firm_code),
	firm_account_id: string().required(messages.required.firm_account_id),
	title: string().required(messages.required.title),
	first_name: string().required(messages.required.first_name),
	last_name: string().required(messages.required.last_name),
	email: string().email(messages.invalid.email).required(messages.required.email),
	phone: string().required(messages.required.phone),
	country: string().required(messages.required.country),
});
