import Ajax from './base';

const GetAllGroups = (type, payload) => {
	return Ajax.Request(`/name-flattener/${type}/groups`, Ajax.GET, true, payload);
};

const GetOneGroup = (type, id, payload) => {
	return Ajax.Request(`/name-flattener/${type}/groups/${id}`, Ajax.GET, true, payload);
};

const GetAllUnmatched = (type, payload) => {
	return Ajax.Request(`/name-flattener/${type}/unmatched`, Ajax.GET, true, payload);
};

const GetVariations = (type, id, payload) => {
	return Ajax.Request(`/name-flattener/${type}/unmatched/${id}`, Ajax.GET, true, payload);
};

const CreateGroup = (type, payload) => {
	return Ajax.Request(`/name-flattener/${type}/unmatched`, Ajax.PATCH, true, payload);
};

const UpdateGroup = (type, payload) => {
	return Ajax.Request(`/name-flattener/${type}/groups`, Ajax.PATCH, true, payload);
};

export default {
	GetAllGroups,
	GetOneGroup,
	GetAllUnmatched,
	GetVariations,
	CreateGroup,
	UpdateGroup,
};
