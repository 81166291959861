import React, { useEffect, useState } from 'react';

import DataTable, { Paging } from 'com/widgets/DataTable';
import Modal from 'com/widgets/Modal';

import Button from 'com/ui/Button';
import Input from 'com/ui/Input';
import Select from 'com/ui/Select';
import AppLink from 'com/util/AppLink';
import AppDate from 'com/util/AppDate';

import Settings from 'services/rest/settings';
import EmailTemplates from 'services/rest/email-templates';

import useNotification from 'services/hooks/use_notification';
import useDebounce from 'services/hooks/useDebounce';

import { DEBOUNCE_DELAY_MS } from 'data/constants';

import './style.css';

const SystemSettingsRegionalTemplatesMain = (props) => {
	const filterInit = {
		text_search: '',
		service_id: '',
		region_id: '',
		template_id: '',
		orderBy: [],
		deleted: '0',
	};

	const tableHeadersInit = [
		{ title: 'Service', field: 'service_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Region', field: 'region_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Apply to Template', field: 'template_id', type: 'string', sort: 'none', sortable: true },
		{ title: 'Description', field: 'description', type: 'string', sort: 'none', sortable: true },
		{ title: 'Last Edited', field: '_modified', type: 'string', sort: 'none', sortable: true },
		{ title: '', field: 'action', type: 'string', sort: 'none', sortable: false },
	];

	const [filters, setFilters] = useState(filterInit);
	const [services, setServices] = useState([]);
	const [regions, setRegions] = useState([]);
	const [templates, setTemplates] = useState([]);
	const [emailTemplates, setEmailTemplates] = useState([]);
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [paging, setPaging] = useState({ total_rows: 0, page: 1, limit: Paging.PAGE_SIZE[0].value });
	const [pageChange, setPageChange] = useState(1);
	const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
	const [deleteTemplateID, setDeleteTemplateID] = useState('');
	const sendNotification = useNotification();
	const debouncedFilters = useDebounce(filters, DEBOUNCE_DELAY_MS);

	useEffect(async () => {
		try {
			await fetchData();
		} catch (err) {}
	}, [pageChange, debouncedFilters]);

	useEffect(() => {
		(async () => {
			await fetchInitData();
		})();
	}, []);

	const fetchInitData = async () => {
		try {
			let [servicesData, regionsData, emailTemplatesData] = await Promise.all([Settings.GetServices(), Settings.GetRegions(), EmailTemplates.GetAll()]);
			setServices([
				{ label: 'All', value: '' },
				...servicesData.map((c) => {
					return { ...c, label: c.name, value: c.id };
				}),
			]);
			setRegions([
				{ label: 'All', value: '' },
				...regionsData.map((c) => {
					return { ...c, label: c.name, value: c.id };
				}),
			]);
			setEmailTemplates([
				{ label: 'All', value: '' },
				...emailTemplatesData?.data.map((c) => {
					return { ...c, label: c.name, value: c.id };
				}),
			]);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchData = async () => {
		try {
			let filter = {
				...filters,
				order_by: '',
				page: paging.page,
				limit: paging.limit,
			};
			if (!!filters.orderBy) {
				filter.order_by = filters.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(',');
			}

			let ts = await EmailTemplates.GetAllRegional(filter);
			setTemplates(ts.data);
			setPaging({ ...paging, total_rows: ts.total_rows });
		} catch (err) {
			console.log(err);
			sendNotification({ type: 'error', title: 'Something went wrong with loading the data.' });
		}
	};

	const onFilterChange = (e) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value,
		});
	};

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filters.orderBy.filter((r) => r.field === col).length === 0) {
						setFilters({
							...filters,
							orderBy: [{ field: col, direction: h.sort }, ...filters.orderBy],
						});
					} else {
						setFilters({
							...filters,
							orderBy: [{ field: col, direction: h.sort }, ...filters.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
	};

	const pagingChange = (p, l) => {
		setPaging({ ...paging, page: p, limit: l });
		setPageChange(pageChange + 1);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const openDeleteTemplateModal = (e) => {
		let { tid } = e.target.dataset;
		setDeleteTemplateID(tid);
		setShowDeleteTemplateModal(true);
	};

	const closeDeleteTemplateModal = () => {
		setShowDeleteTemplateModal(false);
	};

	const handleDeleteTemplate = async () => {
		try {
			await EmailTemplates.DeleteRegional(deleteTemplateID);
			setTemplates(templates.filter((t) => t.id !== deleteTemplateID));
			sendNotification({ type: 'success', title: 'Successfully delete template' });
		} catch (err) {
			sendNotification({ type: 'error', title: 'Failed deleting template' });
		}
		closeDeleteTemplateModal();
	};

	const deleteTemplateModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeleteTemplateModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Delete', action: handleDeleteTemplate, theme: 'azami-blue' },
	];

	return (
		<div className="main-screen-section white full-height system-details-content settings-regional-templates__list-view">
			<div className="settings-regional-templates__filters">
				<Input label="Search" name="text_search" value={filters.text_search} onChange={onFilterChange} customClassName="text-search" placeholder="Search" />
				<Select label="Service" options={services} name="service_id" value={filters.service_id} onChange={onFilterChange} />
				<Select label="Region" options={regions} name="region_id" value={filters.region_id} onChange={onFilterChange} />
				<Select label="Template" options={emailTemplates} name="email_template_id" value={filters.email_template_id} onChange={onFilterChange} />
			</div>
			<div className="system-settings-regional-templates__header">
				<span className="system-settings-regional-templates__header__title">List of Templates</span>
				<AppLink type="nav" to={`/system-settings/regional-templates/add`} exact={true} className="form-button blurple">
					Create Template
				</AppLink>
			</div>
			<div className="system-settings-regional-templates__body">
				<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort}>
					{!!templates &&
						templates.map((t, i) => {
							return (
								<tr key={t.id}>
									<DataTable.Cell>{t.service_name}</DataTable.Cell>
									<DataTable.Cell>{t.region_name}</DataTable.Cell>
									<DataTable.Cell>{t.email_template_name}</DataTable.Cell>
									<DataTable.Cell>{t.description}</DataTable.Cell>
									<DataTable.EditedByCell user={`${t.created_by_name || ''}`}>
										<AppDate>{t._modified}</AppDate>
									</DataTable.EditedByCell>
									<DataTable.Cell>
										<AppLink
											type="nav"
											to={`/system-settings/regional-templates/${t.id}/edit`}
											exact={true}
											className="system-settings-regional-templates-main-edit-btn"
											title="Edit"
										></AppLink>
										<AppLink
											type="nav"
											to={`/system-settings/regional-templates/${t.id}/duplicate`}
											exact={true}
											className="system-settings-regional-templates-main-duplicate-btn"
											title="Duplicate"
										></AppLink>
										<Button onClick={openDeleteTemplateModal} data-tid={t.id} data-index={i} theme="none" className="system-settings-regional-templates-main-delete-btn" />
									</DataTable.Cell>
								</tr>
							);
						})}
				</DataTable>
				<Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange} />
				{showDeleteTemplateModal && (
					<Modal title="Delete Regional Template" closeHandler={closeDeleteTemplateModal} footerActions={deleteTemplateModalFooterActions}>
						Are you sure that you want to delete this template?
					</Modal>
				)}
			</div>
		</div>
	);
};
export default SystemSettingsRegionalTemplatesMain;
