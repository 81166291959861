//System Imports
import PropTypes from 'prop-types';
//Template Imports
import AuthBox from 'com/templates/AuthBox';
//UI Imports
import BackButton from 'com/ui/BackButton';
//Constant Imports
import { ACCOUNT_CONFIMATION_MESSAGES } from 'data/constants';
//ICON imports
import ICON_BACK_LOGIN from 'assets/images/icons/login_back_light_purple_blue.svg';
//Style Imports
import './style.css';

const ConfirmAccountIPeer = (props) => {
	return (
		<div className="confirm-account-container">
			<AuthBox
				title="Account confirmation"
				titleColor="dark-grey"
				icon="password"
				footer={
					<BackButton icon={ICON_BACK_LOGIN} to="/">
						Back to login
					</BackButton>
				}
			>
				{props.message && ACCOUNT_CONFIMATION_MESSAGES[props.message]}
			</AuthBox>
		</div>
	);
};

ConfirmAccountIPeer.propTypes = {
	message: PropTypes.string,
};

ConfirmAccountIPeer.defaultProps = {
	message: '',
};

export default ConfirmAccountIPeer;
