import Ajax from './base';

const GetAllTransactions = (queryStringData) => {
	return Ajax.Request(`/reciprocity/transactions`, Ajax.GET, true, queryStringData);
};

const DeleteTransaction = (id) => {
	return Ajax.Request(`/reciprocity/transactions/${id}`, Ajax.DELETE, true);
};

const CreateReciprocityOverride = (payload) => {
	return Ajax.Request('/reciprocity/overrides', Ajax.POST, true, payload);
};

const GetOneReciprocityOverride = (id) => {
	return Ajax.Request(`/reciprocity/overrides/${id}`, Ajax.GET, true);
};

const UpdateReciprocityOverride = (id, payload) => {
	return Ajax.Request(`/reciprocity/overrides/${id}`, Ajax.PUT, true, payload);
};

const GetAllReciprocityOverridesByFirm = (id, payload) => {
	return Ajax.Request(`/reciprocity/overrides/firms/${id}`, Ajax.GET, true, payload);
};

const DeleteReciprocityOverride = (id) => {
	return Ajax.Request(`/reciprocity/overrides/${id}`, Ajax.DELETE, true);
};

const GetAllSystemReciprocityOverrides = (payload) => {
	return Ajax.Request(`/reciprocity/overrides/system`, Ajax.GET, true, payload);
};

export default {
	GetAllTransactions,
	DeleteTransaction,
	CreateReciprocityOverride,
	GetOneReciprocityOverride,
	UpdateReciprocityOverride,
	GetAllReciprocityOverridesByFirm,
	DeleteReciprocityOverride,
	GetAllSystemReciprocityOverrides,
};
