//Template Imports
import AuthBox from 'com/templates/AuthBox';
//UI Imports
import BackButton from 'com/ui/BackButton';
//Constant Imports
import { ACCOUNT_CONFIMATION_MESSAGES } from 'data/constants';
//Style Imports
import './style.css';

const ConfirmAccountAzami = (props) => {
	return (
		<div className="confirm-account-container">
			<AuthBox title="Account confirmation" titleColor="azami-blurple" icon="password" footer={<BackButton to="/">Back to login</BackButton>}>
				{props.message && ACCOUNT_CONFIMATION_MESSAGES[props.message]}
			</AuthBox>
		</div>
	);
};

export default ConfirmAccountAzami;
