import React, { useState, useEffect } from 'react';
import Select from 'com/ui/Select';

const PAGE_SIZE = [
	{ value: '20', label: '20' },
	{ value: '50', label: '50' },
	{ value: '100', label: '100' },
];

const Paging = (props) => {
	const [paging, setPaging] = useState([1, 2, 3, 4, 5]);
	const [pages, setPages] = useState({ first: 1, prev: 1, next: 1, last: 1 });

	useEffect(() => {
		let mask = Number(props.mask);
		let page = Number(props.page);
		let trs = Number(props.totalRows);
		let limit = Number(props.limit);
		let pagesNum = (trs % limit > 0 ? 1 : 0) + (trs - (trs % limit)) / limit;
		setPages({
			first: 1,
			prev: page > 1 ? page - 1 : 1,
			next: page < pagesNum ? page + 1 : pagesNum,
			last: pagesNum,
		});
		setPaging(generatePagingArray(page, pagesNum, mask));
	}, [props.page, props.totalRows, props.limit]);

	const generatePagingArray = (p, max, mask) => {
		let out = [];
		let half = Math.floor(mask / 2);
		let start = p - half + 1 - (mask % 2);
		let end = p + half;
		if (mask > max) {
			mask = max;
		}
		// handle start boundary case
		if (start <= 0) {
			start = 1;
			end = mask;
		}
		// handle end boundary case
		if (end > max) {
			start = max - mask + 1;
			end = max;
		}
		// populate the visible paging numbers array
		for (let i = start; i <= end; i++) {
			out.push(i);
		}
		return out;
	};

	const onPageChange = (e) => {
		props.onClick(e.target.dataset.page, props.limit);
	};

	const onLimitChange = (e) => {
		props.onClick(pages.first, e.target.value);
	};

	const displayingFrom = props.limit * (props.page - 1) + 1;
	const displayingTo = props.limit * props.page > props.totalRows ? props.totalRows : props.limit * props.page;
	return (
		<div className="table-paging">
			<div className="table-paging__right">
				<span>Entries per page</span>
				<Select options={PAGE_SIZE} customClassName={`table-paging__right__select`} value={props.limit} onChange={onLimitChange} />
				<span>
					Displaying {displayingFrom} - {displayingTo} of {props.totalRows} entries
				</span>
			</div>
			<div className="table-paging__left">
				<button onClick={onPageChange} data-page={pages.first} className="table-paging__left__paging-button dark">
					first
				</button>
				<button onClick={onPageChange} data-page={pages.prev} className="table-paging__left__paging-button dark">
					prev
				</button>
				{paging.map((v, i) => (
					<button onClick={onPageChange} key={i} data-page={v} className={`table-paging__left__paging-button ${v === Number(props.page) ? 'active' : ''}`}>
						{v}
					</button>
				))}
				<button onClick={onPageChange} data-page={pages.next} className="table-paging__left__paging-button dark">
					next
				</button>
				<button onClick={onPageChange} data-page={pages.last} className="table-paging__left__paging-button dark">
					last
				</button>
			</div>
		</div>
	);
};
Paging.defaultProps = {
	mask: 5,
};

Paging.PAGE_SIZE = PAGE_SIZE;
export default Paging;
