import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useNotification from 'services/hooks/use_notification';

import Select from 'com/ui/Select';
import TagSelect from 'com/ui/TagSelect';
import Input from 'com/ui/Input';
import Textarea from 'com/ui/Textarea';
import Switch from 'com/ui/Switch';

import { IP_TYPE_OPTIONS, VALIDATION_RULE_TYPES, VALIDATION_RULE_LABELS, VALIDATION_OBJECT_TYPE, VALIDATION_RULE_DESCRIPTION } from 'data/constants';

import ValidationRules from 'services/rest/renewals_validation_rules';

import { requiredFieldsMap } from './requiredFieldsMap';

import Modal from 'com/widgets/Modal';

import './style.css';

const CreateRuleModal = (props) => {
	const errorsInit = {
		type: '',
		region_id: '',
		// region_ids: '',
		object_type: '',
		ip_type: '',
		pct_number_required: '',
		prefix: '',
		suffix: '',
		digits: '',
		first_separator: '',
		second_separator: '',
		digit_start_with: '',
		decimals: '',
		alert_message: '',
	};

	const [errors, setErrors] = useState(errorsInit);
	const sendNotification = useNotification();

	const handleChange = (e) => {
		let { name, value } = e.target;

		switch (name) {
			case 'type':
				props.setNewRule((prevState) => {
					if (props.editMode) {
						return { ...props.newRuleInit, [name]: value, id: prevState.id };
					}
					return { ...props.newRuleInit, [name]: value };
				});
				break;
			case 'pct_number_required':
				props.setNewRule((prevState) => {
					return { ...prevState, [name]: !prevState.pct_number_required };
				});
				break;
			// separators must be in same order how they are defined in the rule
			case 'first_separator':
				let oneSeparatorArr = props.newRule.separators;
				oneSeparatorArr[0] = value;

				props.setNewRule((prevState) => {
					return { ...prevState, separators: oneSeparatorArr };
				});
				break;
			case 'second_separator':
				let twoSeparatorsArr = props.newRule.separators;
				twoSeparatorsArr[1] = value;

				props.setNewRule((prevState) => {
					return { ...prevState, separators: twoSeparatorsArr };
				});
				break;
			default:
				props.setNewRule((prevState) => {
					return { ...prevState, [name]: value };
				});
				break;
		}

		if (name === 'type') {
			setErrors(errorsInit);
		}
	};

	const validate = (ruleData) => {
		let out = {};
		let err = false;
		let msgRequired = 'Required';

		for (let i in ruleData) {
			let ruleType = ruleData['type'];
			let fieldValue = ruleData[i];
			let field = i;

			if (!ruleType) {
				out[field] = msgRequired;
				err = true;
				break;
			}
			if (!requiredFieldsMap[ruleType]) break;

			switch (i) {
				case 'region_id':
					// case 'regionIds':
					if ((props.editMode && !ruleData['region_id']) || (!props.editMode && ruleData['region_ids'].length === 0)) {
						out['region_id'] = msgRequired;
						err = true;
					} else {
						out['region_id'] = '';
					}
					break;
				case 'separators':
					if (requiredFieldsMap[ruleType].required.includes('first_separator') && !fieldValue[0]) {
						out['first_separator'] = msgRequired;
						err = true;
					}
					if (requiredFieldsMap[ruleType].required.includes('second_separator') && !fieldValue[1]) {
						out['second_separator'] = msgRequired;
						err = true;
					}
					break;
				default:
					if (!requiredFieldsMap[ruleType].required.includes(field)) {
						continue;
					}

					if (!fieldValue) {
						out[field] = msgRequired;
						err = true;
					} else {
						out[field] = '';
					}
			}
		}
		setErrors((prevState) => {
			return { ...out };
		});
		return err;
	};

	const createRule = async () => {
		try {
			let payload = [];
			props.newRule.region_ids.forEach((id) => {
				let out = { ...props.newRule, region_id: id };
				payload.push(out);
			});
			let verr = validate(props.newRule);
			if (verr) return;
			props.setShowLoader(true);
			await ValidationRules.Create(payload);
			await props.getRules();
			props.setShowLoader(false);
			props.setNewRule(props.newRuleInit);
			props.closeModal();
			sendNotification({ type: 'success', title: 'Rule created' });
		} catch (error) {
			sendNotification({ type: 'error', title: 'Rule create failed' });
			console.log(error);
			props.setShowLoader(false);
		}
	};

	const updateRule = async () => {
		try {
			let verr = validate(props.newRule);
			if (verr) return;
			props.setShowLoader(true);
			await ValidationRules.Update(props.newRule, props.newRule.id);
			await props.getRules();
			props.setShowLoader(false);
			props.setNewRule(props.newRuleInit);
			props.setEditMode(false);
			props.closeModal();
			sendNotification({ type: 'success', title: 'Rule updated' });
		} catch (error) {
			sendNotification({ type: 'error', title: 'Rule update failed' });
			console.log(error);
			props.setShowLoader(false);
		}
	};

	const modalFooterActions = [
		{ primary: false, label: 'Cancel', action: props.closeModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Save', action: props.editMode ? updateRule : createRule, theme: 'azami-blue' },
	];

	return (
		<Modal title={!props.editMode ? 'Create Rule' : 'Edit Rule'} closeHandler={props.closeModal} footerActions={modalFooterActions}>
			<div className="create-rule-modal__content-wrapper">
				<Select options={VALIDATION_RULE_LABELS} label="Rule" onChange={handleChange} name="type" value={props.newRule.type} required={true} error={errors.type} />
				<Textarea label="Description" name="description" customClassName="create-rule-modal__description" value={VALIDATION_RULE_DESCRIPTION[props.newRule.type]} disabled={true} />
				<Select options={VALIDATION_OBJECT_TYPE} label="For" onChange={handleChange} name="object_type" value={props.newRule.object_type} error={errors.object_type} />
				<div className="create-rule-modal__form__elements_wrapper">
					{!props.editMode ?
						<TagSelect
							label="Regions"
							name="region_ids"
							customClassName="validation-rules__regions-select"
							options={props.regions}
							values={props.newRule.region_ids}
							onChange={handleChange}
							error={errors.region_id}
						/>
					:	<Select options={props.regions} label="Region" onChange={handleChange} name="region_id" value={props.newRule.region_id} error={errors.region_id} />}

					<Select options={IP_TYPE_OPTIONS} label="IP Type" onChange={handleChange} name="ip_type" value={props.newRule.ip_type} error={errors.ip_type} />
				</div>
				<div className="create-rule-modal__form__elements_wrapper">
					{props.newRule.type === VALIDATION_RULE_TYPES.PREFIX && <Input label="Prefix" name="prefix" onChange={handleChange} value={props.newRule.prefix} error={errors.prefix} />}
					{props.newRule.type === VALIDATION_RULE_TYPES.PREFIX_AND_DIGITS && (
						<>
							<Input label="Prefix" name="prefix" onChange={handleChange} value={props.newRule.prefix} error={errors.prefix} />
							<Input label="Digits" name="digits" type="number" onChange={handleChange} value={props.newRule.digits || ''} error={errors.digits} />
						</>
					)}
					{props.newRule.type === VALIDATION_RULE_TYPES.PREFIX_SEPARATOR_DIGITS && (
						<>
							<Input label="Prefix" name="prefix" onChange={handleChange} value={props.newRule.prefix} error={errors.prefix} />
							<Input label="Digits" name="digits" type="number" onChange={handleChange} value={props.newRule.digits || ''} error={errors.digits} />
							<Input label="Separator after prefix" name="first_separator" onChange={handleChange} error={errors.first_separator} value={props.newRule.separators[0] || ''} />
						</>
					)}
					{props.newRule.type === VALIDATION_RULE_TYPES.PREFIX_DIGITS_STARTS_WITH && (
						<>
							<Input label="Prefix" name="prefix" onChange={handleChange} value={props.newRule.prefix} error={errors.prefix} />
							<Input
								label="Digit start with"
								name="digit_start_with"
								type="number"
								onChange={handleChange}
								value={props.newRule.digit_start_with || ''}
								error={errors.digit_start_with}
							/>
							<Input label="Digits" name="digits" type="number" onChange={handleChange} value={props.newRule.digits || ''} error={errors.digits} />
						</>
					)}
					{props.newRule.type === VALIDATION_RULE_TYPES.PREFIX_DIGITS_SUFFIX && (
						<>
							<Input label="Prefix" name="prefix" onChange={handleChange} value={props.newRule.prefix} error={errors.prefix} />
							<Input label="Digits" name="digits" type="number" onChange={handleChange} value={props.newRule.digits || ''} error={errors.digits} />
							<Input label="Suffix" name="suffix" onChange={handleChange} value={props.newRule.suffix} error={errors.suffix} />
						</>
					)}
					{props.newRule.type === VALIDATION_RULE_TYPES.PREFIX_SUFFIX_WITH_SEPARATOR && (
						<>
							<Input label="Prefix" name="prefix" onChange={handleChange} value={props.newRule.prefix} error={errors.prefix} />
							<Input label="Suffix" name="suffix" onChange={handleChange} value={props.newRule.suffix} error={errors.suffix} />
							<Input label="Digits" name="digits" type="number" onChange={handleChange} value={props.newRule.digits || ''} error={errors.digits} />
							<Input label="Separator after prefix" name="first_separator" onChange={handleChange} value={props.newRule.separators[0] || ''} error={errors.first_separator} />
							<Input label="Separator before suffix" name="second_separator" onChange={handleChange} value={props.newRule.separators[1] || ''} error={errors.second_separator} />
						</>
					)}
					{props.newRule.type === VALIDATION_RULE_TYPES.PREFIX_DIGITS_DECIMAL && (
						<>
							<Input label="Prefix" name="prefix" onChange={handleChange} value={props.newRule.prefix} error={errors.prefix} />
							<Input label="Digits" name="digits" type="number" onChange={handleChange} value={props.newRule.digits || ''} error={errors.digits} />
							<Input label="Decimals" name="decimals" type="number" onChange={handleChange} value={props.newRule.decimals || ''} error={errors.decimals} />
						</>
					)}
					{props.newRule.type === VALIDATION_RULE_TYPES.DIGITS && (
						<>
							<Input label="Digits" name="digits" type="number" onChange={handleChange} value={props.newRule.digits || ''} error={errors.digits} />
						</>
					)}
					{props.newRule.type === VALIDATION_RULE_TYPES.DIGITS_WITH_DECIMAL && (
						<>
							<Input label="Digits" name="digits" type="number" onChange={handleChange} value={props.newRule.digits || ''} error={errors.digits} />
							<Input label="Decimals" name="decimals" type="number" onChange={handleChange} value={props.newRule.decimals || ''} error={errors.decimals} />
						</>
					)}
				</div>
				<Textarea
					label="Alert Message"
					name="alert_message"
					customClassName="create-rule-modal__alert-message"
					value={props.newRule.alert_message}
					onChange={handleChange}
					error={errors.alert_message}
				/>
				<Switch theme="blurple" label="PCT number is required" onChange={handleChange} value={props.newRule.pct_number_required} name="pct_number_required" />
			</div>
		</Modal>
	);
};

CreateRuleModal.propTypes = {
	regions: PropTypes.array,
	setShowLoader: PropTypes.func,
	getRules: PropTypes.func,
	closeModal: PropTypes.func,
	newRuleInit: PropTypes.object,
	newRule: PropTypes.object,
	setNewRule: PropTypes.func,
	editMode: PropTypes.bool,
	setEditMode: PropTypes.func,
};

CreateRuleModal.defaultProps = {
	regions: [],
	setShowLoader: () => {},
	getRules: () => {},
	closeModal: () => {},
	newRuleInit: {},
	newRule: {},
	setNewRule: () => {},
	editMode: false,
	setEditMode: () => {},
};

export default CreateRuleModal;
