import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ConfirmAccountAzami from './ConfirmAccountAzami';
import ConfirmAccountIPeer from './ConfirmAccountIPeer';
import withDomainAuthTemplate from 'com/hoc/withDomainAuthTemplate';

import Auth from 'services/rest/auth';

const ConfirmAccountTemplate = withDomainAuthTemplate(ConfirmAccountAzami, ConfirmAccountIPeer);

const ConfirmAccount = () => {
	const [message, setMessage] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		Auth.ConfirmAccount(id)
			.then(() => {
				setMessage('success');
			})
			.catch((err) => {
				console.log(err);
				setMessage('fail');
			});
	}, []);

	return <ConfirmAccountTemplate message={message} />;
};

export default ConfirmAccount;
