import React, { useEffect, useState } from 'react';

import Settings from 'services/rest/settings';
import Firms from 'services/rest/firms';
import Users from 'services/rest/users';
import { random } from 'services/strings';

import Modal from 'com/widgets/Modal';
import DataTable from 'com/widgets/DataTable';
import PrefsAssociateAddForm from 'com/widgets/PrefsAssociateAddForm';
import Button from 'com/ui/Button';

import ICON_DELETE from 'assets/images/icons/ico_btn_delete_lightgrey.svg';
import ICON_AVATAR_SOLID from 'assets/images/icons/avatar_solid.svg';
import { RoleBox } from 'com/util/RoleBox';

const UserDetailsPreferredAssociates = (props) => {
	const tableHeadersInit = [
		{ title: 'Firm', field: 'firm_name', type: 'string', sortable: false },
		{ title: 'Name', field: 'attorney_name', type: 'string', sortable: false },
		{ title: 'Region', field: 'region_name', type: 'string', sortable: false },
		{ title: 'Created', field: 'created', type: 'date', sortable: false },
		{ title: '', field: 'actions', type: 'string', sortable: false },
	];

	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [preferredAssociates, setPreferredAssociates] = useState([]);
	const [firms, setFirms] = useState([{ label: '', value: '' }]);
	const [associates, setAssociates] = useState([]);
	const [regions, setRegions] = useState([{ label: '', value: '' }]);
	const [showAddForm, setShowAddForm] = useState(false);
	const [showBlacklistedModal, setShowBlacklistedModal] = useState(false);
	const [modalFirmName, setModalFirmName] = useState('');
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deletingPreferred, setDeletingPreferred] = useState(null);

	useEffect(() => {
		if (props.data.preferred_associates) {
			setPreferredAssociates(props.data.preferred_associates);
		}
	}, [props.data]);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, []);

	const fetchData = async () => {
		try {
			let firmsData = await Firms.GetAllRaw();
			setFirms((prevState) => [
				...prevState,
				...firmsData.map((f) => {
					return {
						label: f.name,
						value: f.id,
					};
				}),
			]);

			let associatesData = await Users.GetAllRaw();
			setAssociates((prevState) => [
				...prevState,
				...associatesData
					.map((c) => {
						return !!(c.first_name && c.last_name) ?
								{
									label: `${c.first_name} ${c.last_name}`,
									value: c.id,
									firm_id: c.firm_id,
								}
							:	false;
					})
					.filter(Boolean),
			]);

			let regions = await Settings.GetRegions();
			setRegions((prevState) => [
				...prevState,
				...regions.map((r) => {
					return {
						label: r.name,
						value: r.id,
						code: r.code,
					};
				}),
			]);
		} catch (err) {
			console.log(err);
		}
	};

	const getRegionCode = (item) => {
		let region = regions.find((r) => r.value === item.region_id);
		return region && region.code;
	};

	const toggleAddForm = () => {
		setShowAddForm(!showAddForm);
	};

	const handleAddPreferred = (addingPreferred) => {
		let newPreferred = {
			id: random(8),
			...addingPreferred,
			firm_name: addingPreferred.firm_id && firms.find((f) => f.value === addingPreferred.firm_id).label,
			associate_name: addingPreferred.associate_id && associates.find((f) => f.value === addingPreferred.associate_id).label,
			region_name: addingPreferred.region_id && regions.find((f) => f.value === addingPreferred.region_id).label,
		};

		let isBlacklisted = handleBlacklisted(newPreferred);
		if (!!isBlacklisted) {
			return;
		}

		let data = [...preferredAssociates, newPreferred];
		props.onChangePreferredAssociates(data, 'add');
		setShowAddForm(!showAddForm);
	};

	const handleBlacklisted = (newPreferred) => {
		let isAlreadyBlacklisted = props.data.blacklisted_associates && props.data.blacklisted_associates.find((ba) => ba.firm_id === newPreferred.firm_id);

		if (!!isAlreadyBlacklisted) {
			setModalFirmName(newPreferred.firm_name);
			setShowBlacklistedModal(true);
		}

		return isAlreadyBlacklisted;
	};

	const openDeletePreferredModal = (e) => {
		let { paid } = e.target.parentNode.dataset;
		let preferred = preferredAssociates.find((pa) => pa.id == paid);
		setDeletingPreferred(preferred);

		let name = preferred.firm_name;
		let associate = preferred.associate_name || '';
		setModalFirmName(name.concat(associate ? ` (${associate})` : ''));

		setShowDeleteModal(true);
	};

	const handleDeletePreferred = () => {
		let data = preferredAssociates.filter((pa) => pa.id != deletingPreferred.id);
		props.onChangePreferredAssociates(data);
		setShowDeleteModal(false);
	};

	const closePopupHandler = () => {
		setShowDeleteModal(false);
		setShowBlacklistedModal(false);
	};

	const popupWarningFooterActions = [{ primary: false, label: 'Close', action: closePopupHandler, theme: 'azami-ghost' }];

	const popupDeleteFooterActions = [
		{ primary: false, label: 'Cancel', action: closePopupHandler, theme: 'azami-ghost' },
		{ primary: true, label: 'Delete', action: handleDeletePreferred, theme: 'azami-blue' },
	];

	return (
		<div className="user-details-prefs_assoc">
			<div className="user-details-prefs_assoc__section-header">
				<span className="user-details-prefs_assoc__section-header__title">List of preferred associates</span>
				<RoleBox roles={['admin', 'sales', 'sales_manager']}>
					<Button onClick={toggleAddForm}>Add Preferred Associate</Button>
				</RoleBox>
			</div>
			<div className="user-details-prefs_assoc__section-body">
				<DataTable fixedHeader={true} columns={tableHeaders}>
					{showAddForm && (
						<PrefsAssociateAddForm firms={firms} associates={associates} regions={regions} showAssociateColumn={true} onCancelAddForm={toggleAddForm} onHandleAdd={handleAddPreferred} />
					)}
					{preferredAssociates.map((pa, i) => {
						return (
							<tr key={i}>
								<DataTable.PersonCell ico={ICON_AVATAR_SOLID} theme="solid">
									{pa.firm_name}
								</DataTable.PersonCell>
								<DataTable.Cell>{pa.associate_name}</DataTable.Cell>
								<DataTable.CountryCell code={getRegionCode(pa)}>{pa.region_name}</DataTable.CountryCell>
								<DataTable.DateCell>{pa._created || new Date()}</DataTable.DateCell>
								<DataTable.Cell>
									<RoleBox roles={['admin', 'sales', 'sales_manager']}>
										<button className="user-details-prefs_assoc__section-body__button-delete" data-paid={pa.id} onClick={openDeletePreferredModal}>
											<img src={ICON_DELETE} title="Delete" />
										</button>
									</RoleBox>
								</DataTable.Cell>
							</tr>
						);
					})}
					{showBlacklistedModal && (
						<Modal title="Warning" icon={ICON_DELETE} closeHandler={closePopupHandler} footerActions={popupWarningFooterActions}>
							<strong>{modalFirmName}</strong> already exists in the Blacklisted Associates.
						</Modal>
					)}
					{showDeleteModal && (
						<Modal title="Delete Preferred Associate" icon={ICON_DELETE} closeHandler={closePopupHandler} footerActions={popupDeleteFooterActions}>
							Are you sure that you want to delete <strong>{modalFirmName}</strong> from the list?
						</Modal>
					)}
				</DataTable>
			</div>
		</div>
	);
};

export default UserDetailsPreferredAssociates;
