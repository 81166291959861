import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import AppLink from 'com/util/AppLink';

import Button from 'com/ui/Button';

import './style.css';

import LOGO_SMALL from 'assets/images/azami_logo.svg';
import ICON_ARROWS_LEFT from 'assets/images/icons/ico_arrows_left.svg';
import ICON_ARROWS_RIGHT from 'assets/images/icons/ico_arrows_right.svg';

const PublicTemplate = (props) => {
	let ssb = window.localStorage.getItem('showSidebar') === 'true' ? true : false;
	const [showSidebar, setShowSidebar] = useState(ssb);
	const [headerIcon, setHeaderIcon] = useState('');

	const toggleSidebar = () => {
		window.localStorage.setItem('showSidebar', !showSidebar);
		setShowSidebar(!showSidebar);
	};

	useEffect(() => {
		switch (props.headerIcon) {
			default:
				setHeaderIcon('');
				break;
		}
	}, []);

	const content = React.Children.map(props.children, (child) => (child.type.displayName === 'ApplicationContent' ? child : null));
	const sidebar = React.Children.map(props.children, (child) => (child.type.displayName === 'ApplicationSidebar' ? child : null));
	const header = React.Children.map(props.children, (child) => (child.type.displayName === 'ApplicationHeader' ? child : null));

	return (
		<>
			<div className={`public-template ${props.customClassName}`}>
				<div className="public-template__sidenav">
					{sidebar.length > 0 ?
						<>
							<button className={`public-template__btn-sidebar-hide ${showSidebar ? 'hide' : ''}`} onClick={toggleSidebar}>
								<img src={ICON_ARROWS_LEFT} alt="" />
								Collapse
							</button>
							<button className={`public-template__btn-sidebar-show ${showSidebar ? '' : 'hide'}`} onClick={toggleSidebar}>
								<img src={ICON_ARROWS_RIGHT} alt="" />
							</button>
						</>
					:	null}
					<AppLink type="nav" to="/" exact={true} className="nohover">
						<img src={LOGO_SMALL} className="public-template__sidenav__logo" alt="" />
					</AppLink>
				</div>
				<div className="public-template__main-screen">
					{sidebar.length > 0 ?
						<div className={`public-template__main-screen__left ${showSidebar ? 'hide' : ''}`}>{sidebar}</div>
					:	null}
					<div className="public-template__main-screen__right">
						<div className={`public-template__main-screen__header ${showSidebar || sidebar.length == 0 ? 'hide' : ''}`}>
							<img src={headerIcon} className="public-template__main-screen__header__icon" alt="" />
							<h2>{props.title}</h2>
							{props.countBadge && <span className="public-template__main-screen__header__count-badge">{props.countBadge}</span>}

							<div className="public-template__main-screen__header__content">
								{header}
								{props.button && <Button>{props.button}</Button>}
							</div>
						</div>
						<div className="public-template__main-content">{content}</div>
					</div>
				</div>
			</div>
		</>
	);
};

const AppSidebar = (props) => props.children;
AppSidebar.displayName = 'ApplicationSidebar';
PublicTemplate.Sidebar = AppSidebar;

const AppContent = (props) => props.children;
AppContent.displayName = 'ApplicationContent';
PublicTemplate.Content = AppContent;

const AppHeader = (props) => props.children;
AppHeader.displayName = 'ApplicationHeader';
PublicTemplate.Header = AppHeader;

PublicTemplate.propTypes = {
	customClassName: propTypes.string,
};

PublicTemplate.defaultProps = {
	customClassName: '',
};

export default PublicTemplate;
