import React from 'react';
import AppLink from 'com/util/AppLink';

import './style.css';

const Footer = (props) => {
	let year = new Date().getFullYear();
	return (
		<footer className="app-footer">
			<span>&copy; {year}</span>
			<nav>
				<AppLink to={{ pathname: 'https://www.azamiglobal.com' }} target="_blank">
					Azami Global
				</AppLink>
				<AppLink to={{ pathname: 'https://azamiglobal.com/terms-of-service/' }} target="_blank">
					Terms of Service
				</AppLink>
				<AppLink to={{ pathname: 'https://azamiglobal.com/privacy-policy/' }} target="_blank">
					Privacy Policy
				</AppLink>
				<AppLink to={{ pathname: 'https://azamiglobal.com/gdpr/' }} target="_blank">
					GDPR Compliance
				</AppLink>
				<AppLink to={{ pathname: 'https://azamiglobal.com/information-security-overview/' }} target="_blank">
					Information Security
				</AppLink>
				<AppLink to={{ pathname: 'https://azamiglobal.com/ccpa/' }} target="_blank">
					CCPA
				</AppLink>
			</nav>
		</footer>
	);
};

export default Footer;
