const init = {
	application_number: '',
	wipo_data: {},
};

// constants
const SET_WIPO_DATA = 'SET_WIPO_DATA';
const CLEAR_WIPO_DATA = 'CLEAR_WIPO_DATA';

// actions
export const setWipoData = (data) => {
	return {
		type: SET_WIPO_DATA,
		payload: data,
	};
};

export const clearWipoData = () => {
	return {
		type: CLEAR_WIPO_DATA,
		payload: init,
	};
};

// reducer
const reducer = (state = init, action) => {
	switch (action.type) {
		case SET_WIPO_DATA:
			return { ...state, ...action.payload };
		case CLEAR_WIPO_DATA:
			return { ...action.payload };
		default:
			return state;
	}
};

export default reducer;
