//System Imports
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
//UI Imports
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import BackButton from 'com/ui/BackButton';
import ErrorMessage from 'com/ui/ErrorMessage';
//Template Imports
import AuthBox from 'com/templates/AuthBox';
//Service Imports
import { ForgotPasswordSchema } from 'services/validators/forgotPassword';
//ICON imports
import ICON_BACK_LOGIN from 'assets/images/icons/login_back_light_purple_blue.svg';
//Style Imports
import './style.css';

const ForgotPasswordIPeer = (props) => {
	return (
		<div className="forgot-password-container">
			<AuthBox
				title="Forgot Password"
				titleColor="dark-grey"
				icon="password"
				footer={
					<BackButton icon={ICON_BACK_LOGIN} to="/">
						Back to login
					</BackButton>
				}
			>
				<Formik initialValues={props.initialState} validationSchema={ForgotPasswordSchema} onSubmit={props.forgotPasswordSubmit} validateOnChange={false}>
					{({ values, handleChange, validateField, errors, status }) => {
						const validate = (e) => validateField(e.target.name);

						return (
							<Form>
								<span className="forgot-password-container-ipeer__text">Please enter your email, and we will send a password reset link.</span>
								<Input name="email" value={values.email} onChange={handleChange} onBlur={validate} error={errors.email} label="Email" placeholder="email@domain.com" type="text" />
								<div className="forgot-password-container-ipeer__actions">
									<Button type="submit" theme="ipeer-blue" showSpinner={props.spinner}>
										Submit
									</Button>
								</div>
								{status && <ErrorMessage error={status} />}
							</Form>
						);
					}}
				</Formik>
			</AuthBox>
		</div>
	);
};

ForgotPasswordIPeer.propTypes = {
	initialState: PropTypes.object,
	forgotPasswordSubmit: PropTypes.func,
};

ForgotPasswordIPeer.defaultProps = {
	initialState: {},
	forgotPasswordSubmit: () => {},
};

export default ForgotPasswordIPeer;
