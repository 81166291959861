import Modal from 'com/widgets/Modal';
import PropTypes from 'prop-types';

import './style.css';

const InstructionDeadlineModal = (props) => {
	return (
		<Modal title={'Confirmation'} closeHandler={props.closeHandler} footerActions={props.footerActions}>
			<div className="instruction-deadline-modal__body">
				{props.designService ?
					<>
						<span>{'We are unable to accept instructions (or provide a quote) for Design applications less than ten (10) business days before the filing deadline.'}</span>

						<span>{'Please contact your account executive for more information.'}</span>
					</>
				:	<>
						<span>{'Based on the priority date entered, the filing deadline has passed and we are unable to accept instructions (or provide a quote) for this application.'}</span>

						<span>{'Please contact your account executive for more information.'}</span>
					</>
				}
			</div>
		</Modal>
	);
};

InstructionDeadlineModal.propTypes = {
	closeHandler: PropTypes.func,
	footerActions: PropTypes.array,
	designService: PropTypes.bool,
};

InstructionDeadlineModal.defaultProps = {
	closeHandler: () => {},
	footerActions: [],
	designService: false,
};

export default InstructionDeadlineModal;
