import PropTypes from 'prop-types';

import './style.css';

const LetterBadge = (props) => {
	return props.children ?
			<span className={`letter-badge ${props.theme ? props.theme : ''}`} style={props.theme === 'round' ? { backgroundColor: props.color } : { color: props.color }}>
				{props.children.charAt(0)}
			</span>
		:	null;
};

LetterBadge.propTypes = {
	color: PropTypes.string,
	children: PropTypes.string,
	theme: PropTypes.string,
};

LetterBadge.defaultProps = {
	color: '#000000',
	children: 'Test',
	theme: 'round',
};

export default LetterBadge;
