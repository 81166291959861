import React from 'react';
import PropTypes from 'prop-types';
// icons
import STAR from 'assets/images/icons/star.svg';
import STAR_COLOR from 'assets/images/icons/star-color.svg';
// styles
import './style.css';

const StarsRating = (props) => {
	return (
		<div className="stars-rating">
			{[...Array(5)].map((_, i) => (
				<img key={i} src={props.rating >= i + 1 ? STAR_COLOR : STAR} alt={props.rating >= i + 1 ? 'Filled Star' : 'Empty Star'} />
			))}
		</div>
	);
};

StarsRating.propTypes = {
	rating: PropTypes.number,
};

StarsRating.defaultProps = {
	rating: 0,
};

export default StarsRating;
