import { useEffect, useState } from 'react';
import Modal from 'com/widgets/Modal';
import Input from 'com/ui/Input';
import LoaderOverlay from 'com/ui/LoaderOverlay';
import Settings from 'services/rest/settings';
import Quotes from 'services/rest/quotes';
import { toENCADateString } from 'services/dates';
import Preferences from 'services/rest/preferences';
import { PREFERENCES_GROUP, PREFERENCES_KEYS } from 'data/constants';
// icons
import ICON_INFO_MAGENTA from 'assets/images/icons/ico_info_magenta.svg';

import './style.css';

const QuotesDetailsViewExchangeRatesModal = (props) => {
	const adjustRateIcon = {
		icon: ICON_INFO_MAGENTA,
		tooltip: 'Enter the percent value to adjust the exchange rate by when converting currencies.',
	};

	const [showLoader, setShowLoader] = useState(false);
	const [data, setData] = useState([]);
	const [fxValue, setFxValue] = useState(5);
	const [date, setDate] = useState(toENCADateString(new Date()));
	const [adjustRateIcons, setAdjustRateIcons] = useState([adjustRateIcon]);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, []);

	const fetchData = async () => {
		try {
			setShowLoader(true);
			let quoteData = await Quotes.GetOne(props.quoteID);
			if (quoteData) {
				let exchange_rate_padding = quoteData.exchange_rate_padding;
				if (!exchange_rate_padding) {
					let fx = await Preferences.GetSystemRecordsByKey(PREFERENCES_GROUP.GENERAL_SETTINGS, PREFERENCES_KEYS.EXCHANGE_RATE_PADDING);
					if (fx) {
						exchange_rate_padding = fx.preference_value;
					}
				}
				setFxValue(exchange_rate_padding);
				let d = quoteData.exchange_rate_date ? toENCADateString(quoteData.exchange_rate_date) : date;
				setDate(d);
				let currenciesData = await Settings.GetExchangeRatesByDate(d);
				let cd = currenciesData.filter((c) => c.code !== 'USD');
				setData(cd);
			}
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
			console.log(err);
		}
	};

	useEffect(() => {
		(async () => {
			let currenciesData = await Settings.GetExchangeRatesByDate(date);
			let cd = currenciesData.filter((c) => c.code !== 'USD');
			setData(cd);
		})();
	}, [date]);

	const fxOnChange = (e) => {
		setFxValue(e.target.value);
	};

	const dateOnChange = (e) => {
		setDate(e.target.value);
	};

	const submitOverrides = async () => {
		setShowLoader(true);
		await Quotes.SetExchangeRates(props.quoteID, { exchange_rate_padding: fxValue, exchange_rate_date: date });
		props.setQuoteExchangeRatesData(fxValue, date);
		setShowLoader(false);
		props.closeHandler();
	};

	const modalActions = [
		{ primary: false, label: 'Cancel', action: props.closeHandler, theme: 'ghost' },
		{ primary: true, label: 'Save', action: submitOverrides, theme: 'light' },
	];
	return (
		<Modal title="Exchange Rates" closeHandler={props.closeHandler} footerActions={modalActions}>
			<div className="exchange-rates-modal">
				<div className="exchange-rates-modal__body">
					<LoaderOverlay showLoader={showLoader} size="medium" theme="darkblue" />
					<div className="exchange-rates-modal__body-header-text">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
					</div>
					<div className="exchange-rates-modal__body-datepicker">
						<Input type="date" name="date" value={date} onChange={dateOnChange} />
					</div>
					<div className="exchange-rates-modal__body-rates">
						{data.map((c) => {
							return (
								<div className="exchange-rates-modal__body-rates-field" key={c.id}>
									<div className="exchange-rates-modal__body-rates-field-header">{`1 USD in ${c.code}`}</div>
									<div className="exchange-rates-modal__body-rates-field-input">
										<Input disabled={true} name={c.id} type="number" value={c.value} />
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="exchange-rates-modal__body-input">
					<Input label="Adjust Exchange Rate By" name="preference_value" type="number" value={fxValue} icons={adjustRateIcons} onChange={fxOnChange} />
				</div>
			</div>
		</Modal>
	);
};

export default QuotesDetailsViewExchangeRatesModal;
