import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsUpdated, REDIRECT_FROM_QUOTE_MSG } from 'redux/ducks/redirect';
import { setQuoteData, setQuoteDataFromObject } from 'redux/ducks/quote';

import Users from 'services/rest/users';
import { formatEmailsStringToJSON } from 'services/strings';

import Modal from '../Modal';
import LoaderOverlay from 'com/ui/LoaderOverlay';
import Checkbox from 'com/ui/Checkbox';
import Textarea from 'com/ui/Textarea';
import DataTable from 'com/widgets/DataTable';
import Input from 'com/ui/Input';
import AttachmentsTable from '../AttachmentsTable';
import { RoleBox } from 'com/util/RoleBox';

import useNotification from 'services/hooks/use_notification';

import { DOCUMENT_OBJECT_TYPE, ERR_NO_REGION_SPECIFIED } from 'data/constants';
import { getFlag } from 'data/flags';
import './style.css';

const NewQuoteInstructModal = (props) => {
	const tableHeadersInit = [
		{ title: 'Region', field: 'region', type: 'string', sort: 'none', sortable: false },
		{ title: 'Client Ref. No.', field: 'client_ref_numer', type: 'string', sort: 'none', sortable: false },
		{ title: 'Comments for your Agent', field: 'comment_for_agent', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: '', type: '', sort: 'none', sortable: false },
	];
	const quote = useSelector((state) => state.quote);
	const errorsInit = {
		noRegionSpecified: {
			message: '',
		},
		instructionNotConfirmed: {
			message: '',
		},
	};
	const [activeIndex, setActiveIndex] = useState(1);
	const [errors, setErrors] = useState(errorsInit);
	const dispatch = useDispatch();

	useEffect(async () => {
		if (quote.client_id) {
			let user = await Users.GetOneSlim(quote.client_id);
			if (user) {
				dispatch(
					setQuoteDataFromObject({
						client_contact_greeting: user.first_name || '',
						client_contact_email_for_quotes: formatEmailsStringToJSON(user.email_for_quotes || ''),
						client_contact_full_name: `${user.first_name} ${user.last_name}`,
						client_contact_email: user.email || '',
					}),
				);
			}
		}
	}, [quote.client_id]);

	const sendNotification = useNotification();
	const handleWizardNavClick = (e) => {
		if (e.target.dataset.index == 2 && props.selectedRegionsForInstruct.length <= 0) {
			setErrors((prevState) => {
				sendNotification({ type: 'error', title: ERR_NO_REGION_SPECIFIED });
				return { ...prevState, noRegionSpecified: { message: ERR_NO_REGION_SPECIFIED } };
			});
		} else {
			setActiveIndex(e.target.dataset.index);
		}

		if (errors.noRegionSpecified && props.selectedRegionsForInstruct.length > 0) setErrors((prevState) => ({ ...prevState, noRegionSpecified: '' }));
	};

	const fieldChange = (e) => {
		dispatch(setQuoteData({ name: e.target.name, value: e.target.value }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
	};

	const checkboxChange = (e) => {
		dispatch(setQuoteData({ name: e.target.name, value: !quote[e.target.name] }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
	};

	const regionInfoChange = (e) => {
		let regionId = e.target.closest('tr').dataset.id;
		let regions = quote.additional_region_info.map((r) => {
			if (r.region_id === regionId) {
				return { ...r, [e.target.name]: e.target.value };
			} else {
				return r;
			}
		});

		dispatch(setQuoteData({ name: 'additional_region_info', value: regions }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
	};

	const footerNext = () => {
		if (props.selectedRegionsForInstruct.length > 0) {
			setActiveIndex(2);
			setErrors((prevState) => ({ ...prevState, noRegionSpecified: '' }));
		} else {
			setErrors((prevState) => {
				sendNotification({ type: 'error', title: ERR_NO_REGION_SPECIFIED });
				return { ...prevState, noRegionSpecified: { message: ERR_NO_REGION_SPECIFIED } };
			});
		}
	};

	const instructButtonHandler = () => {
		props.instruct();
		props.closeHandler();
	};

	const footerActions = [
		{ primary: false, label: 'Cancel', action: props.closeHandler, theme: 'azami-ghost' },
		{ primary: true, label: activeIndex == 1 ? 'Next' : 'Instruct Selected Regions', action: activeIndex == 1 ? footerNext : instructButtonHandler, theme: 'azami-blue' },
	];

	const selectAllRegionsToggle = (e) => {
		if (e.target.checked) {
			props.setSelectedRegionsForInstruct(quote.regions);
		} else {
			props.setSelectedRegionsForInstruct([]);
		}
	};

	return (
		<Modal title="Instruct Region" footerActions={footerActions} closeHandler={props.closeHandler}>
			<div className="instruct__modal-content">
				<header className={`instruct__modal-content__header`}>
					<div
						className={`instruct__modal-content__wizard-nav-pill ${activeIndex == 1 ? 'instruct__modal-content__wizard-nav-pill-active' : ''}`}
						data-index={1}
						onClick={handleWizardNavClick}
					>
						<div className={`instruct__modal-content__wizard-nav-pill-text-wrapper ${activeIndex == 1 ? 'current-active' : ''}`}>
							<span className={`instruct__modal-content__wizard-nav-pill-number ${activeIndex == 1 ? 'active' : ''}`}>1</span>
							Regions &amp; Attachments
						</div>
						<i className={`instruct__modal-content__wizard-nav-pill-error-indicator ${errors.noRegionSpecified.message ? 'show-error' : ''}`}></i>
					</div>
					<div
						className={`instruct__modal-content__wizard-nav-pill ${activeIndex >= 2 ? 'instruct__modal-content__wizard-nav-pill-active' : ''}`}
						data-index={2}
						onClick={handleWizardNavClick}
					>
						<div className={`instruct__modal-content__wizard-nav-pill-text-wrapper ${activeIndex == 2 ? 'current-active' : ''}`}>
							<span className={`instruct__modal-content__wizard-nav-pill-number ${activeIndex == 2 ? 'active' : ''}`}>2</span>
							Client Regional Ref. & Contact Info
						</div>
						<i className={`instruct__modal-content__wizard-nav-pill-error-indicator ${errors.instructionNotConfirmed.message ? 'show-error' : ''}`}></i>
					</div>
				</header>
				<div className="instruct__modal-content__wizard">
					<div className={`instruct__modal-content__regions ${activeIndex == 1 ? 'show-instructing-panels' : ''}`}>
						<h4>Select the regions in which you want to instruct the case</h4>
						<Checkbox
							label="Select All Regions"
							name="select_all_regions"
							onClick={selectAllRegionsToggle}
							checked={props.selectedRegionsForInstruct.length === quote.regions.length ? true : false}
						/>
						<div className="instruct__modal-content__regions-grid">
							{quote.regions.length > 0 &&
								quote.regions.map((r) => {
									let region = props.regions.find((reg) => reg.id === r);
									return (
										<Checkbox
											key={r.id}
											value={region.id}
											label={region.name}
											name="regions_for_instruct"
											img={getFlag(region.code)}
											checked={props.selectedRegionsForInstruct.includes(r)}
											onClick={props.checkboxChange}
											labelClassName="instruct__modal-content__regions-grid__label"
										/>
									);
								})}
						</div>
						<div>
							<AttachmentsTable
								documents={props.documents}
								setDocuments={props.setDocuments}
								regions={props.regions.filter((r) => quote.regions.includes(r.id))}
								objectTypeInit={DOCUMENT_OBJECT_TYPE.CASE}
								allowDelete={true}
								checkFileType={true}
							/>
						</div>
						<div>
							<RoleBox roles={['user', 'member', 'member_assistant', 'admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager']}>
								<div className="instruct__modal-content__text-info">
									<Textarea label="Comment" onChange={fieldChange} name="instruction_comment" value={quote.instruction_comment} />
								</div>
							</RoleBox>
						</div>
					</div>
					<div className={`instruct__modal-content__client-ref ${activeIndex == 2 ? 'show-instructing-panels' : ''}`}>
						<DataTable fixedHeader={false} columns={tableHeadersInit} customClassName="attachments__table">
							{props.selectedRegionsForInstruct.map((r) => {
								let region = quote.additional_region_info.find((reg) => reg.region_id === r);
								return (
									<tr key={r} data-id={r}>
										<DataTable.CountryCell code={region.region_code}>{region.region_name}</DataTable.CountryCell>
										<DataTable.Cell>
											<Input type="string" onChange={regionInfoChange} name="client_ref_number" value={region.client_ref_number} />
										</DataTable.Cell>
										<DataTable.Cell>
											<Input type="string" onChange={regionInfoChange} name="comment_for_agent" value={region.comment_for_agent} />
										</DataTable.Cell>
									</tr>
								);
							})}
						</DataTable>
						<div className="instruct__modal-content__client-ref-contact">
							<h4>Contact Information</h4>
							<div className="instruct__modal-content__client-ref-contact-inputs">
								<div>
									<Input type="string" name="client_contact_greeting" label="Case Contact Greeting Name" value={quote.client_contact_greeting} onChange={fieldChange} />
								</div>
								<div>
									<Input
										type="string"
										name="client_contact_email_for_quotes"
										label='Case Contact "CC" Address(es)'
										value={quote.client_contact_email_for_quotes}
										onChange={fieldChange}
										customClassName="instruct__modal-content__client-ref-contact-inputs__input"
									/>
								</div>
								<div>
									<Input type="string" name="client_contact_full_name" label="Case Contact Full Name" value={quote.client_contact_full_name} onChange={fieldChange} />
								</div>
								<div>
									<Input
										type="string"
										name="client_contact_bcc"
										label='Case Contact "BCC" Address(es)'
										value={quote.client_contact_bcc}
										onChange={fieldChange}
										customClassName="instruct__modal-content__client-ref-contact-inputs__input"
									/>
								</div>
								<div>
									<Input type="string" name="client_contact_email" label='Case Contact "To" Address' value={quote.client_contact_email} onChange={fieldChange} />
								</div>
							</div>
						</div>
						<Checkbox
							multilineLabel={true}
							label="By clicking Instruct Selected Regions, I confirm that I am instructing this application in the regions indicated above.
                            I understand that my agent has not yet accepted responsibility for filing this case."
							name="agree_to_terms"
							checked={quote.agree_to_terms === 1 || quote.agree_to_terms === true ? true : false}
							onClick={checkboxChange}
							error={errors.instructionNotConfirmed.message ? true : false}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};
export default NewQuoteInstructModal;
