import React from 'react';
import AppLink from 'com/util/AppLink';

import ICON_REGISTER from 'assets/images/icons/ico_pen_sign_white.svg';

const LoginFooterIPeer = () => {
	return (
		<AppLink type="regular" className="login-footer-ipeer" to="/register">
			<img src={ICON_REGISTER} className="" alt="" />
			<div className="login-footer-ipeer__message">
				<span className="login-footer-ipeer__message__top">Don't have an account yet?</span>
				<span className="login-footer-ipeer__message__bottom">Click here to create one</span>
			</div>
		</AppLink>
	);
};

export default LoginFooterIPeer;
