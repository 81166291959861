import PropTypes from 'prop-types';

import { BackdropContainer } from 'com/util/Modal';

import Button from 'com/ui/Button';

import './style.css';

const Modal = (props) => {
	return (
		<BackdropContainer>
			<div className="confirmation-modal">
				<div className="confirmation-modal__header">
					{props.icon && <img src={props.icon} alt="" />}
					<h4>{props.title}</h4>
					{props.closeHandler && <button onClick={props.closeHandler}>&times;</button>}
				</div>
				<div className="confirmation-modal__body">{props.children}</div>
				{props.footerActions && (
					<div className="confirmation-modal__footer">
						{props.footerActions.map((b, i) => {
							let disabled = b.disabled ? b.disabled : false;
							let theme =
								b.theme ? b.theme
								: b.primary ? 'light'
								: 'ghost';
							return (
								<Button key={i} onClick={b.action} disabled={disabled} theme={theme} showSpinner={b.showSpinner}>
									{b.label}
								</Button>
							);
						})}
					</div>
				)}
			</div>
		</BackdropContainer>
	);
};

Modal.propTypes = {
	icon: PropTypes.string,
	title: PropTypes.string,
	children: PropTypes.node,
	closeHandler: PropTypes.func,
	footerActions: PropTypes.array,
};

export default Modal;
