import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import ChangePasswordAzami from './ChangePasswordAzami';
import ChangePasswordIPeer from './ChangePasswordIPeer';
import withDomainAuthTemplate from 'com/hoc/withDomainAuthTemplate';

import Auth from 'services/rest/auth';
import useQuery from 'services/hooks/useQuery';
import { getError } from 'util/errors';

import useTitle from 'services/hooks/useTitle';
import { PAGE_TITLE } from 'data/constants';

const ChangePasswordTemplate = withDomainAuthTemplate(ChangePasswordAzami, ChangePasswordIPeer);

const ChangePassword = () => {
	useTitle(PAGE_TITLE.RESET_PASSWORD);
	const [spinner, setSpinner] = useState(false);
	const history = useHistory();
	const { id } = useParams();
	const query = useQuery();
	const tc = query.get('tc') === 'true';

	const initialState = {
		password: '',
		password_repeat: '',
		tos_agreement: tc,
	};

	const changePasswordSubmit = async (fields, { setStatus }) => {
		let data = {
			password_reset_token: id,
			password: fields.password,
			password_repeat: fields.password_repeat,
		};
		if (!tc) {
			data = {
				...data,
				tos_agreement: fields.tos_agreement,
			};
		}
		try {
			setSpinner(true);
			await Auth.ResetPassword(data);
			setSpinner(false);
			history.push('/change-password/message');
		} catch (err) {
			setStatus(getError(err.code));
			console.log(err);
		}
	};

	return <ChangePasswordTemplate initialState={initialState} changePasswordSubmit={changePasswordSubmit} tc={tc} spinner={spinner} />;
};

export default ChangePassword;
