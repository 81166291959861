import Ajax from './base';

const GetAll = (queryStringData) => {
	return Ajax.Request('/renewals-sheet-parser/transformation-rules', Ajax.GET, false, queryStringData);
};

const Create = (payload) => {
	return Ajax.Request('/renewals-sheet-parser/transformation-rules', Ajax.POST, true, payload);
};

const Update = (payload, id) => {
	return Ajax.Request(`/renewals-sheet-parser/transformation-rules/${id}`, Ajax.PUT, true, payload);
};

const Delete = (id) => {
	return Ajax.Request(`/renewals-sheet-parser/transformation-rules/${id}`, Ajax.DELETE, false);
};

export default {
	GetAll,
	Create,
	Delete,
	Update,
};
