// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
import AppLink from 'com/util/AppLink';
//icons
import ICON_RIGHT_ARROWS from 'assets/images/icons/ico_blurple_arrows_right.svg';
//styles
import './style.css';

const ItemCard = (props) => {
	return (
		<AppLink to={props.link} className={`item-card__container ${props.customClassName}`}>
			<img className="item-card__icon" src={props.icon} alt="" />
			<div className="item-card__main">
				<div className="item-card__main-header">
					<div className="item-card__main-header__container">
						<span className="item-card__main-header__title">{props.title}</span>
						{props.amount > 0 ?
							<span className="item-card__main-header__amount">{props.amount}</span>
						:	null}
					</div>
					<img className="item-card__main-header__link" src={ICON_RIGHT_ARROWS} alt="" />
				</div>
				<div className="item-card__separator"></div>
				<span className="item-card__main-content">{props.content}</span>
			</div>
		</AppLink>
	);
};

ItemCard.defaultProps = {
	customClassName: '',
	icon: '',
	title: '',
	amount: 0,
	link: '',
	content: '',
};

ItemCard.propTypes = {
	customClassName: PropTypes.string,
	icon: PropTypes.string,
	title: PropTypes.string,
	amount: PropTypes.number,
	link: PropTypes.string,
	content: PropTypes.string,
};

export default ItemCard;
