//constants
const TOASTS_ADD = 'TOASTS_ADD';
const TOASTS_REMOVE = 'TOASTS_REMOVE';
const TOASTS_REMOVE_ALL = 'TOASTS_REMOVE_ALL';

const initialState = [];

//actions
export const toastsAdd = (data) => {
	return {
		type: TOASTS_ADD,
		payload: data,
	};
};

export const toastsRemove = (id) => {
	return {
		type: TOASTS_REMOVE,
		payload: id,
	};
};

export const toastsRemoveAll = () => {
	return {
		type: TOASTS_REMOVE_ALL,
	};
};

//reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case TOASTS_ADD:
			return [
				...state,
				{
					id: Date.now(),
					...action.payload,
				},
			];
		case TOASTS_REMOVE:
			return state.filter((t) => t.id !== action.payload);
		case TOASTS_REMOVE_ALL:
			return [];
		default:
			return state;
	}
};

export default reducer;
