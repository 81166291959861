import Ajax from './base';

const GetPriorites = (queryStringData) => {
	return Ajax.Request(`/assignment-override/priorities`, Ajax.GET, false, queryStringData);
};

const GetRequests = (payload) => {
	return Ajax.Request(`/assignment-override/requests`, Ajax.GET, true, payload);
};

const GetOneRequest = (id) => {
	return Ajax.Request(`/assignment-override/requests/${id}`, Ajax.GET, true);
};

const CreateRequest = (payload) => {
	return Ajax.Request('/assignment-override/requests', Ajax.POST, true, payload);
};

const UpdateRequest = (id, payload) => {
	return Ajax.Request(`/assignment-override/requests/${id}`, Ajax.PUT, true, payload);
};

const UpdateRequestStatus = (id, payload) => {
	return Ajax.Request(`/assignment-override/requests/${id}/status`, Ajax.PATCH, true, payload);
};

const DeleteRequest = (id) => {
	return Ajax.Request(`/assignment-override/requests/${id}`, Ajax.DELETE, true);
};

export default {
	GetPriorites,
	GetRequests,
	GetOneRequest,
	CreateRequest,
	UpdateRequest,
	UpdateRequestStatus,
	DeleteRequest,
};
