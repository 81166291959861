import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import useNotification from 'services/hooks/use_notification';

import Button from 'com/ui/Button';
import Input from 'com/ui/Input';
import Textarea from 'com/ui/Textarea';

import Banner from 'services/rest/banner';
import { formatToInputLocaleDate } from 'services/dates';

import './style.css';

const SystemSettingsBannerCreate = (props) => {
	const dataInit = {
		name: '',
		date_from: '',
		date_to: '',
		text: '',
	};
	const errorFields = {
		name: '',
		date_from: '',
		date_to: '',
		text: '',
	};
	const [data, setData] = useState(dataInit);
	const [errors, setErrors] = useState(errorFields);
	const sendNotification = useNotification();

	const handleChange = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	};

	const params = useParams();

	const handleSave = async () => {
		let error = validate();
		if (error) return;
		props.setShowLoader(true);
		try {
			if (params.id) {
				await Banner.UpdateByID(params.id, data);
			} else {
				await Banner.Create(data);
				setData(dataInit);
			}
			props.setShowLoader(false);
			sendNotification({ type: 'success', title: 'Successfully saved banner data' });
			// re-fetch new data
			await props.fetchData();
		} catch (error) {
			console.log(error);
			props.setShowLoader(false);
			sendNotification({ type: 'error', title: 'Unsuccessfully saved banner data' });
		}
	};

	const handleCancel = () => {
		if (params.id) {
			return getBanner(params.id);
		}
		setData(dataInit);
	};

	const validate = () => {
		setErrors(errorFields);
		let err = false;
		for (let e in errors) {
			if (!data[e]) {
				setErrors((prevState) => {
					return { ...prevState, [e]: 'Required' };
				});
				err = true;
			}
		}
		let dateFrom = new Date(data.date_from);
		let dateTo = new Date(data.date_to);
		if (dateTo <= dateFrom) {
			setErrors((prevState) => {
				return { ...prevState, date_to: 'Invalid date' };
			});
			err = true;
		}
		return err;
	};

	const getBanner = (id) => {
		props.setShowLoader(true);
		Banner.GetOneByID(id)
			.then((data) => {
				setData(data);
				props.setShowLoader(false);
			})
			.catch((err) => {
				console.log(err);
				props.setShowLoader(false);
			});
	};
	useEffect(() => {
		// check for edit mode
		if (params.id) {
			getBanner(params.id);
		}
	}, []);

	return (
		<>
			<div className="system-settings__banner-create__header">
				<span>Create Banner</span>
			</div>
			<div className="system-settings__banner-create__body">
				<Input value={data.name} type="text" label="Name" name="name" onChange={handleChange} error={errors.name} />
				<div className="system-settings__banner-create__body__date-time-wrapper">
					<Input value={data.date_from} name="date_from" type="datetime-local" label="Start" onChange={handleChange} error={errors.date_from} />
					<Input value={data.date_to} name="date_to" type="datetime-local" label="End" onChange={handleChange} error={errors.date_to} />
				</div>
				<Textarea value={data.text} name="text" onChange={handleChange} error={errors.text} label="Text Area">
					Text area
				</Textarea>
			</div>
			<footer className="system-settings__banner-footer">
				{Object.values(data).some((v) => v !== '') ?
					<>
						<Button onClick={handleCancel} theme="ghost" className="system-settings__banner-footer__cancel-btn">
							Cancel
						</Button>
						<Button onClick={handleSave}>Save Banner</Button>
					</>
				:	null}
			</footer>
		</>
	);
};

SystemSettingsBannerCreate.propTypes = {
	setShowLoader: PropTypes.func,
	fetchData: PropTypes.func,
};

SystemSettingsBannerCreate.defaultProps = {
	setShowLoader: () => {},
	fetchData: () => {},
};

export default SystemSettingsBannerCreate;
