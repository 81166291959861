import React, { useEffect, useState } from 'react';

import ExpertiseList from 'com/widgets/ExpertiseList';
import RegionsList from 'com/widgets/RegionsList';
import { RoleBox } from 'com/util/RoleBox';

const UserDetailsExpertiseRegions = (props) => {
	// const removeDuplicateRegions = (regions) => {
	//     return regions.filter((v, i, arr) => arr.findIndex(sv => sv.region_code === v.region_code) === i)
	// }

	const extractTechnologyIDs = (technologies) => {
		return technologies.map((e) => e.technology_id);
	};

	return (
		<div className="user-details-expertise">
			<RoleBox roles={['$user', 'admin', 'sales', 'sales_manager']}>
				<ExpertiseList values={extractTechnologyIDs(props.userData.expertise)} onChange={props.onChangeExpertise} />
				<RegionsList
					values={props.userData.regions}
					onChange={props.onChangeRegions}
					title="Regions where user is licensed to file"
					editable={true}
					firmID={props.userData.firm_id}
					regionsList="firm"
					name={`${props.userData.first_name} ${props.userData.last_name}`}
				/>
			</RoleBox>
		</div>
	);
};

export default UserDetailsExpertiseRegions;
