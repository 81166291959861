import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './style.css';

const Editor = (props) => {
	let theme = props.theme ? props.theme : 'snow';

	const modules = {
		toolbar: [
			[{ header: [1, 2, false] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
			['link', 'image'],
			['clean'],
		],
	};

	const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image'];

	return (
		<label className={`form-editor ${props.customClassName ? props.customClassName : ''}`}>
			<span className={`form-editor__label ${theme}`}>{props.label}</span>
			<ReactQuill
				theme={theme}
				label={props.label}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				className={`form-editor__input ${theme}`}
				modules={modules}
				formats={formats}
			></ReactQuill>
		</label>
	);
};

Editor.propTypes = {
	theme: PropTypes.oneOf(['snow']),
	label: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
};

Editor.defaultProps = {
	theme: 'snow',
	label: '',
	name: '',
	value: '',
	onChange: () => {},
};

export default Editor;
