const getByName = (name) => {
	let c = document.cookie;
	if (!c) {
		return null;
	}
	let value = null;
	let cookieArr = c.split(';');

	cookieArr.forEach((c) => {
		let cookieSplit = c.split('=');
		if (name.trim() === cookieSplit[0].trim()) {
			value = cookieSplit[1];
		}
	});
	return value;
};

export default {
	getByName,
};
