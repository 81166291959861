import Ajax from './base';

const GetAll = (queryStringData) => {
	return Ajax.Request('/cases', Ajax.GET, false, queryStringData);
};

const GetAllCasesRegions = (queryStringData) => {
	return Ajax.Request('/cases/regions', Ajax.GET, false, queryStringData);
};

const SetSelectedAssociate = (payload) => {
	return Ajax.Request(`/cases/${payload.case_id}/regions/${payload.case_region_id}/associate`, Ajax.POST, true, payload);
};

const GetOne = (id, queryStringData) => {
	return Ajax.Request(`/cases/${id}`, Ajax.GET, true, queryStringData);
};

const GetAllByUser = (queryStringData) => {
	return Ajax.Request('/cases/regions/me', Ajax.GET, true, queryStringData);
};

const GetActiveCases = (queryStringData) => {
	return Ajax.Request('/cases/regions/latest-active', Ajax.GET, false, queryStringData);
};

const Instruct = (id, payload) => {
	return Ajax.Request(`/cases/${id}/regions/instruct`, Ajax.PATCH, true, payload);
};

const setAssociateStatus = (payload) => {
	return Ajax.Request(`/cases/${payload.case_id}/regions/${payload.case_region_id}/associate/${payload.associate_id}/status`, Ajax.PATCH, true, payload);
};

export default {
	GetAll,
	GetAllCasesRegions,
	SetSelectedAssociate,
	GetOne,
	GetAllByUser,
	Instruct,
	setAssociateStatus,
	GetActiveCases,
};
