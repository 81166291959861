//vendor imports
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import ReactDom from "react-dom";
import ReactDOM from 'react-dom';
//components
import Toast from 'com/widgets/Toast';
//styles
import './style.css';
import { toastsRemoveAll } from 'redux/ducks/notification_toasts';

const NotificationLayer = (props) => {
	const notification_toasts = useSelector((state) => state.notification_toasts);
	const dispatch = useDispatch();

	const toastTypes = ['info', 'error', 'warning', 'success'];

	const removeAllToasts = () => {
		dispatch(toastsRemoveAll());
	};

	return ReactDOM.createPortal(
		<div className="notification-layer">
			{notification_toasts.map((t) => {
				return toastTypes.includes(t.type) ? <Toast type={t.type} value={t.title} id={t.id} timeout={t.timeout} key={t.id} toastActions={t.toastActions} /> : null;
			})}
			{notification_toasts.length >= 3 ?
				<button className="notification-layer__remove-all" onClick={removeAllToasts}>
					<span className="notification-layer__remove-all__close">&times;</span>
					Dismiss all notifications
				</button>
			:	null}
		</div>,
		document.getElementById('toast'),
	);
};

export default NotificationLayer;
