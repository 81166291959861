import { VALIDATION_RULE_TYPES } from 'data/constants';

export const requiredFieldsMap = {
	[VALIDATION_RULE_TYPES.PREFIX]: {
		required: ['type', 'ip_type', 'object_type', 'region_id', 'alert_message', 'prefix'],
	},
	[VALIDATION_RULE_TYPES.PREFIX_AND_DIGITS]: {
		required: ['type', 'ip_type', 'object_type', 'region_id', 'alert_message', 'prefix'],
	},
	[VALIDATION_RULE_TYPES.PREFIX_SEPARATOR_DIGITS]: {
		required: ['type', 'ip_type', 'object_type', 'region_id', 'alert_message', 'prefix', 'separators', 'first_separator'],
	},
	[VALIDATION_RULE_TYPES.PREFIX_DIGITS_STARTS_WITH]: {
		required: ['type', 'ip_type', 'object_type', 'region_id', 'alert_message', 'prefix', 'digit_start_with'],
	},
	[VALIDATION_RULE_TYPES.PREFIX_DIGITS_SUFFIX]: {
		required: ['type', 'ip_type', 'object_type', 'region_id', 'alert_message', 'prefix', 'suffix'],
	},
	[VALIDATION_RULE_TYPES.PREFIX_SUFFIX_WITH_SEPARATOR]: {
		required: ['type', 'ip_type', 'object_type', 'region_id', 'alert_message', 'prefix', 'separators', 'first_separator', 'second_separator', 'suffix'],
	},
	[VALIDATION_RULE_TYPES.PREFIX_DIGITS_DECIMAL]: {
		required: ['type', 'ip_type', 'object_type', 'region_id', 'alert_message', 'prefix'],
	},
	[VALIDATION_RULE_TYPES.DIGITS]: {
		required: ['type', 'ip_type', 'object_type', 'region_id', 'alert_message'],
	},
	[VALIDATION_RULE_TYPES.DIGITS_WITH_DECIMAL]: {
		required: ['type', 'ip_type', 'object_type', 'region_id', 'alert_message'],
	},
	[VALIDATION_RULE_TYPES.ALERT_ONLY]: {
		required: ['type', 'ip_type', 'object_type', 'region_id', 'alert_message'],
	},
};
