//System Imports
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//UI Imports
import Input from 'com/ui/Input';

//Hepler Imports
import { getFlag } from 'data/flags';

import './style.css';

const RegionSearchSelector = (props) => {
	const [displayedRegions, setDisplayedRegions] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		handleSearch();
	}, [searchTerm]);

	const handleSearch = () => {
		if (searchTerm.length === 0) return setDisplayedRegions([]);
		if (searchTerm.length > 1) {
			let filterData = props.regions.filter((r) => r.name.toLowerCase().includes(searchTerm));
			setDisplayedRegions(filterData);
		}
	};

	const handleClick = (e) => {
		e.stopPropagation();
		const region = props.regions.find((r) => r.id === e.target.dataset.rid);
		if (region) {
			setSearchTerm(region.name);
			props.onClick(region.id, region.center);
		}
	};

	const handleOnChange = (e) => {
		setSearchTerm(e.target.value.trim().toLowerCase());
	};

	let searchListHeight = 0;
	if (props.regions.length === displayedRegions.length) {
		searchListHeight = 0;
	} else if (displayedRegions.length > 5) {
		searchListHeight = 300;
	} else {
		searchListHeight = displayedRegions.length * 40;
	}

	return (
		<div className={`region-search-selector ${props.customClassName ? props.customClassName : ''}`}>
			<Input placeholder={props.placeholder ? props.placeholder : 'Search'} onChange={handleOnChange} value={searchTerm} />
			<ul className="region-search-selector__regions-list" style={{ height: props.useDefaultHeight ? '350px' : `${searchListHeight}px` }}>
				{displayedRegions.map((r) => (
					<li onClick={handleClick} data-rid={r.id}>
						<div className="region-search-selector__regions-list-left">
							<img src={getFlag(r?.code)} />
							<span>{r.name}</span>
						</div>
						<span>{r.code}</span>
					</li>
				))}
			</ul>
		</div>
	);
};

RegionSearchSelector.propTypes = {
	regions: PropTypes.array,
	onClick: PropTypes.func,
	customClassName: PropTypes.string,
	placeholder: PropTypes.string,
	useDefaultHeight: PropTypes.bool,
};

RegionSearchSelector.defaultProps = {
	regions: [],
	onClick: () => {},
	customClassName: '',
	placeholder: '',
	useDefaultHeight: false,
};

export default RegionSearchSelector;
